const winston = require('winston')
const Transport = require('winston-transport')
const moment = require('moment')
const timestampFormat = () =>
  moment()
    .format('YYYY-MM-DD HH:mm:ss.SSS')
    .trim()
const { combine, timestamp, label } = winston.format

const settings = {
  levels: {
    error: 0,
    warn: 1,
    info: 2,
    http: 3,
    verbose: 4,
    debug: 5,
    silly: 6
  },
  levelNames: ['error', 'warn', 'info', 'http', 'verbose', 'debug', 'silly'],
  searchOptions: ['tag', 'message', 'all'],
  console: {
    error: console.error,
    warn: console.warn,
    info: console.info,
    http: console.info,
    verbose: console.info,
    debug: console.info,
    silly: console.info
  }
}

function rejectEntry (options, info) {
  if (!options.enabled) {
    return true
  }
  if (!options.query) {
    return false
  }
  const query = options.query.toLowerCase()
  const { label, message } = info
  const tagOrAll = options.search === 'tag' || options.search === 'all'
  const msgOrAll = options.search === 'message' || options.search === 'all'
  let msg = message
  if (typeof message === 'object') {
    msg = JSON.stringify(msg)
  }
  let foundInTag = tagOrAll && label.toLowerCase().includes(query)
  let foundInMsg = msgOrAll && msg.toLowerCase().includes(query)
  return !foundInTag && !foundInMsg
}

function logInfo (info, levelName) {
  const { level, message, label, timestamp } = info
  const currentLevel = settings.levelNames.indexOf(levelName)
  const messageLevel = settings.levelNames.indexOf(level)
  if (messageLevel > currentLevel) {
    return
  }
  if (info[Symbol.for('splat')]) {
    settings.console[level](
      `${timestamp} [${label}] ${level}:`,
      message,
      ...info[Symbol.for('splat')]
    )
  } else {
    settings.console[level](`${timestamp} [${label}] ${level}:`, message)
  }
}

class LogBuffer extends Transport {
  constructor (opts) {
    super(opts)
    this.buffer = []
    this.bufferLimit = 10000
    this.options = {
      downloadFormat: 'csv',
      query: '',
      search: 'tag',
      level: 'silly',
      enabled: true
    }
  }
  log (info, callback) {
    process.nextTick(() => {
      this.buffer.push(info)
      const overflowCount = this.buffer.length - this.bufferLimit
      if (overflowCount > 0) {
        this.buffer.splice(0, overflowCount)
      }
      if (!rejectEntry(this.options, info)) {
        logInfo(info, this.options.level)
      }
      this.emit('logged', info)
    })
    callback()
  }
  dump () {
    console.clear()
    for (let i in this.buffer) {
      if (rejectEntry(this.options, this.buffer[i])) {
        continue
      }
      logInfo(this.buffer[i], this.options.level)
    }
  }
  clear () {
    this.buffer = []
    console.clear()
  }
  download () {
    const entries = []
    for (let i in this.buffer) {
      let info = this.buffer[i]
      if (rejectEntry(this.options, info)) {
        continue
      }
      const { level, message, label, timestamp } = info
      let processed = message
      if (typeof message === 'object') {
        processed = JSON.stringify(message)
      }
      if (info[Symbol.for('splat')]) {
        processed += ' ' + JSON.stringify(info[Symbol.for('splat')])
      }
      entries.push(`${timestamp}, [${label}], ${level}, "${processed}"\n`)
    }
    const url = window.URL.createObjectURL(new Blob(entries))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `tap-frontend.csv`)
    document.body.appendChild(link)
    link.click()
  }
}

export const logBuffer = new LogBuffer()
export const loggers = {}
export const namespaced = true

export const state = {
  levels: settings.levels,
  levelNames: settings.levelNames,
  searchOptions: settings.searchOptions
}

export const actions = {
  clearBuffer () {
    logBuffer.clear()
  },
  downloadLog (options) {
    logBuffer.options = options
    logBuffer.download()
  },
  dumpLog (options) {
    logBuffer.options = options
    logBuffer.dump()
  },
  setOptions (options) {
    logBuffer.options = options
  }
}

console.tag = function (logLabel) {
  let logger = loggers[logLabel]
  if (!logger) {
    logger = winston.createLogger({
      level: 'silly',
      format: combine(
        label({ label: logLabel }),
        timestamp({ format: timestampFormat })
      ),
      transports: [logBuffer]
    })

    loggers[logLabel] = logger
  }

  return logger
}
