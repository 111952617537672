import { dateTimeFormat, location } from './const'

/**
 * Refer to the material color palette: https://vuetifyjs.com/en/styles/colors/#material-colors
 *
 * @param color Is the material color base name such as red, pink, purple, etc.
 * @param tint Is the tint value without the '-', lighten-5 would be passed as 'lighten5'.
 * @returns The hex color of the color and tint.
 */
const clone = obj => {
  return JSON.parse(JSON.stringify(obj))
}

const constructUrl = (base, path) => {
  let url = ''
  try {
    if (base.length) url = new URL(path, base)
    else url = new URL(path)
  } catch (error) {
    console.error(error)
    url = ''
  }
  return url.toString()
}

function convertDateTime (dateInput, formatDate, formatTime) {
  let date = dateInput
  let dateOptions = dateTimeFormat[formatDate]
  let timeOptions = dateTimeFormat[formatTime]
  if (dateInput instanceof Date === false) {
    date = new Date(dateInput)
  }
  return date.toLocaleString(location, { ...dateOptions, ...timeOptions })
}

export {
  clone,
  constructUrl,
  convertDateTime
}
