<template>
  <div>
    <v-btn
      @click.stop="toggle ? endSpeechRecognition() : startSpeechRecognition()"
      icon
      :color="!toggle ? buttonColor : speaking ? 'red' : 'red darken-3'"
      :class="{ 'animated infinite pulse': toggle }">
      <v-icon :color="iconColor">{{ toggle ? "mic_off" : "mic" }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
let SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition
let recognition = SpeechRecognition ? new SpeechRecognition() : false
export default {
  data () {
    return {
      error: false,
      speaking: false,
      toggle: false,
      runtimeTranscription: '',
      sentences: [],
      text: '',
      iconColor: 'primary',
      buttonColor: 'white'
    }
  },
  computed: {
    lastSentence () {
      return this.sentences && this.sentences.length > 0
        ? this.sentences[this.sentences.length - 1]
        : ''
    }
  },
  methods: {
    checkCompatibility () {
      if (!recognition) {
        this.error =
          'Speech Recognition is not available on this browser. Please use Chrome or Firefox'
      }
    },
    endSpeechRecognition () {
      recognition.stop()
      this.toggle = false
      this.$emit('speechend', {
        sentences: this.sentences,
        text: this.sentences.join('. '),
        lastSentence: this.lastSentence
      })
    },
    startSpeechRecognition () {
      if (!recognition) {
        this.error =
          'Speech Recognition is not available on this browser. Please use Chrome or Firefox'
        return false
      }
      this.toggle = true
      recognition.lang = this.$store.state.ttsLanguage
      recognition.interimResults = true
      recognition.addEventListener('speechstart', event => {
        this.speaking = true
      })
      recognition.addEventListener('speechend', event => {
        this.speaking = false
      })
      recognition.addEventListener('result', event => {
        const text = Array.from(event.results)
          .map(result => result[0])
          .map(result => result.transcript)
          .join('')
        this.runtimeTranscription = text
      })
      recognition.addEventListener('end', () => {
        if (this.runtimeTranscription !== '') {
          this.sentences.push(
            this.capitalizeFirstLetter(this.runtimeTranscription)
          )
          this.$emit(
            'update:text',
            `${this.text}${this.sentences.slice(-1)[0]}. `
          )
        }
        this.runtimeTranscription = ''
        this.endSpeechRecognition()
      })
      recognition.start()
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    speechEnd ({ sentences, text }) {
      console.tag('SpeechToText').verbose('text', text)
      console.tag('SpeechToText').verbose('sentences', sentences)
      this.sentences = sentences
    }
  },
  mounted () {
    this.checkCompatibility()
  }
}
</script>

<style scoped></style>
