import {
  ALL_TEST_CASES,
  CREATE_TEST_CASE,
  DELETE_TEST_CASE,
  UPDATE_TEST_CASE,
  PLAY_TEST_CASE,
  BULK_DELETE_TEST_CASE,
  TEST_CASE
} from '../graphql/graphql'
import { apolloClient } from '@/vue-apollo'

export default {
  createTestCase (newTC) {
    return apolloClient.mutate({
      mutation: CREATE_TEST_CASE,
      variables: newTC,
      context: { hasUpload: true }
    })
  },
  async getTCs (args) {
    const testCases = await apolloClient.query({
      query: ALL_TEST_CASES,
      variables: args
    })
    return testCases
  },
  getTcById (id) {
    return apolloClient.query({
      query: TEST_CASE, fetchPolicy: 'no-cache', variables: id
    })
  },
  deleteTC (id) {
    return apolloClient.mutate({
      mutation: DELETE_TEST_CASE, variables: id
    })
  },
  deleteBulkTC (args) {
    return apolloClient.mutate({
      mutation: BULK_DELETE_TEST_CASE, variables: args
    })
  },
  playTestCase (testCase) {
    return apolloClient.mutate({
      mutation: PLAY_TEST_CASE, variables: testCase
    })
  },
  async updateTC (updatedTC) {
    return apolloClient.mutate({
      mutation: UPDATE_TEST_CASE,
      variables: updatedTC,
      context: { hasUpload: true }
    })
  }
}
