<template>
  <v-dialog
    v-model="dialog"
    max-width="1200px"
    height="600px"
    persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <!-- Buttons to open dialog -->
      <v-tooltip
        v-if="!configurationId"
        bottom
        open-delay="1000">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            icon
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }">
            <v-icon>
              mdi-plus-circle
            </v-icon>
          </v-btn>
        </template>
        <span>Add a new Configuration</span><br/>
      </v-tooltip>
      <v-tooltip
        v-else
        bottom
        open-delay="1000">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            icon
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }">
            <v-icon small>
              edit
            </v-icon>
          </v-btn>
        </template>
        <span>Edit the Configuration</span><br/>
      </v-tooltip>
    </template>
    <v-card v-if="dialog">
      <v-card-title>
        <h2 v-if="!configurationId">Create Configuration</h2>
        <h2 v-else>Edit Configuration</h2>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container grid-list-md>
            <v-row> <!-- Test Case Form -->
              <v-col cols="12" sm="6"> <!-- Name -->
                <TextField
                  type="name"
                  store="configuration"
                  :index="0"
                  :required="true" />
              </v-col>
              <v-col cols="12" sm="6"> <!-- Domain -->
                <ComboboxField
                  type="systemUnderTest"
                  store="configuration"
                  :required="true"
                  :index="0"
                  :multiple="false" />
              </v-col>
              <v-col cols="12" sm="6"> <!-- Language -->
                <ComboboxField
                  type="car"
                  store="configuration"
                  :index="0"
                  :required="true"
                  :multiple="false" />
              </v-col>
              <v-col cols="12" sm="6"> <!-- Platform -->
                <ComboboxField
                  type="recognition"
                  store="configuration"
                  :index="0"
                  :required="true"
                  :multiple="false" />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions> <!-- Close & Save Buttons -->
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
          text
          @click="saveConfiguration"
          :disabled="!valid"
          color="primary">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TextField from '@/components/formFields/TextField'
import ComboboxField from '@/components/formFields/ComboboxField'

export default {
  name: 'CreateEditConfigurationDialog',
  components: {
    TextField,
    ComboboxField
  },
  props: {
    configurationId: Number
  },
  data () {
    return {
      dialog: false,
      valid: false,
      panel: [],
      rules: [v => !!v || 'Field is required']
    }
  },
  computed: {
    editedItem () {
      return this.$store.state.configuration.editedItem[0]
    }
  },
  methods: {
    async saveConfiguration () {
      if (this.configurationId) {
        await this.$store.dispatch('configuration/save', this.configurationId)
      } else {
        await this.$store.dispatch('configuration/save')
      }
      this.closeDialog()
    },
    async closeDialog () {
      this.$refs.form.reset()
      this.dialog = false
      this.panel = []
      this.$store.commit('configuration/SET_DEFAULT')
      this.$store.dispatch('configuration/fetch')
    }
  },
  watch: {
    dialog (value) {
      if (value === true) {
        if (!this.configurationId) {
          this.$store.commit('configuration/SET_DEFAULT', true)
          this.panel = [0]
        } else {
          this.$store.commit('configuration/SET_EDITED', this.configurationId)
        }
      } else {
        this.closeDialog()
      }
    }
  }
}
</script>
