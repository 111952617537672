<template>
  <v-tooltip
    bottom
    open-delay="1000">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        small
        v-bind="attrs"
        v-on="on">
        <v-icon
          small
          @click.stop="downloadTestResults(item.id)">
          mdi-download
        </v-icon>
      </v-btn>
    </template>
    <span>Download excel file of the Test Job</span>
  </v-tooltip>
</template>

<script>
import reportDowloader from '@/services/downloadReport.js'

export default {
  methods: {
    async downloadTestResults (testJobHistoryID) {
      this.$store.commit('testResult/SET_TESTJOBHISTORYID', testJobHistoryID)
      await this.fetchTestResults()
      await reportDowloader.createXSLXTestResults(
        testJobHistoryID, this.$store.state.testResult.all
      )
      this.$store.commit('testResult/CLEAR_ALL')
    }
  }
}
</script>
