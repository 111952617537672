<template>
  <v-dialog v-model="dialog" width="40%">
    <template #activator="{ on }">
      <v-btn block v-on="on">{{ label }}</v-btn>
    </template>
    <div v-if="dialog">
      <v-toolbar color="lighterBackground">
        <v-toolbar-title>Request Details</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-card>
        <v-container grid-list-md text-xs-left>
          <v-layout row wrap>
            <v-autocomplete
              v-model="header.language"
              :items="supportedLanguages"
              label="Language"
              item-text="name"
              item-value="value"
              @input="$emit('input', header)"
            />
          </v-layout>
          <v-layout row wrap>
            <v-autocomplete
              v-model="header.stage"
              :items="stages"
              label="Stage"
              item-text="name"
              item-value="value"
              @input="$emit('input', header)"
            />
          </v-layout>
          <v-layout row wrap v-for="(item, index) in textfields" :key="index">
            <v-text-field
              v-model="header[item.key]"
              :label="item.label"
              @input="$emit('input', header)"
              :key="index"
            />
          </v-layout>
          <v-layout row wrap>
            <v-text-field
              v-model="header.huVersion"
              label="HU Version"
              @input="$emit('input', header)"
            />
          </v-layout>
          <v-layout row wrap>
            <v-combobox
              v-model="header.appName"
              :items="appNames"
              label="App Name"
              @input="$emit('input', header)"
            ></v-combobox>
          </v-layout>
          <v-layout row wrap>
            <v-combobox
              v-model="header.ntgVersion"
              :items="ntgVersionsChatBot"
              @change="onAppNameChange(header.ntgVersion)"
              label="NTG Version"
              @input="$emit('input', header)"
            ></v-combobox>
          </v-layout>
          <v-layout row wrap> <h4>Position</h4> </v-layout>
          <v-layout row wrap>
            <v-text-field
              v-model="header.position.latitude"
              label="Latitude"
              @input="$emit('input', header)"
              class="mr-2"
            />
            <v-text-field
              v-model="header.position.longitude"
              label="Longitude"
              @input="$emit('input', header)"
              class="ml-2"
            />
          </v-layout>
        </v-container>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {},
  props: {
    value: {},
    label: {
      type: String,
      default: 'Edit Request Header'
    }
  },
  computed: {
    ...mapState('language', ['supportedLanguages']),
    ...mapState('utils', ['appNames', 'ntgVersionsChatBot'])
  },
  watch: {
    dialog () {
      if (this.dialog) {
        this.header = this.value
      }
    }
  },
  methods: {
    onAppNameChange (ntgVersion) {
      if (
        ntgVersion === 'NTG6-Mid-SOP' ||
        ntgVersion === 'NTG6-Mid-FU1' ||
        ntgVersion === 'NTG6-High-FU1'
      ) {
        this.header.appName = 'NTG6'
      } else if (
        ntgVersion === 'NTG6-Mid-FU2' ||
        ntgVersion === 'NTG6-High-FU2'
      ) {
        this.header.appName = 'NTG6_FUP2'
      } else if (
        ntgVersion === 'NTG6-Mid-FU3' ||
        ntgVersion === 'NTG6-High-FU3'
      ) {
        this.header.appName = 'NTG6_FUP3'
      } else if (
        ntgVersion === 'NTG7-Mid-SOP' ||
        ntgVersion === 'NTG7-High-SOP' ||
        ntgVersion === 'NTG7-Prem-SOP' ||
        ntgVersion === 'NTG7-PremPlus-SOP'
      ) {
        this.header.appName = 'OneSpeech_19'
      } else if (
        ntgVersion === 'NTG7-Mid-FU2' ||
        ntgVersion === 'NTG7-High-FU2' ||
        ntgVersion === 'NTG7-Prem-FU2' ||
        ntgVersion === 'NTG7-PremPlus-FU2'
      ) {
        this.header.appName = 'NTG7_FUP2'
      }
      return this.header.appName
    }
  },
  data () {
    return {
      header: {},
      dialog: false,
      stages: [
        {
          name: 'Dev',
          value: 'ece-dev'
        },
        {
          name: 'ECE Nonprod',
          value: 'ece-nonprod'
        },
        {
          name: 'ECE Prod',
          value: 'ece-prod'
        },
        {
          name: 'AMAP Nonprod',
          value: 'amap-nonprod'
        },
        {
          name: 'AMAP Prod',
          value: 'amap-prod'
        }
      ],
      textfields: [
        {
          label: 'VIN',
          key: 'vin'
        },
        {
          label: 'App ID',
          key: 'appId'
        },
        {
          label: 'App Key',
          key: 'appKey'
        },
        {
          label: 'Profile Auth ID',
          key: 'profileAuth'
        }
      ]
    }
  }
}
</script>

<style scoped></style>
