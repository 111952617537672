<template>
  <v-speed-dial
    :top="false"
    :bottom="false"
    :right="false"
    :left="false"
    :direction="directionProp || top"
  >
    <template v-slot:activator>
      <v-btn :color="buttonColor" height="20" small rounded>
        <div v-if="selectedItem" class="white--text">
          {{ selectedItem.title }}
        </div>
        <v-icon v-else :color="iconColor">language</v-icon>
      </v-btn>
    </template>
    <v-btn
      v-for="(item, index) in filteredItems"
      :key="item.selection"
      small
      rounded
      :color="item.buttonColor"
      @click="selectStage(item.selection, index)"
    >
      <div class="white--text pa-0">{{ item.title }}</div>
    </v-btn>
  </v-speed-dial>
</template>

<script>
export default {
  props: ['directionProp', 'stage'],
  data () {
    return {
      iconColor: 'white',
      items: [],
      selectedIndex: 0,
      selectedStage: ''
    }
  },
  computed: {
    selectedItem () {
      return this.items[this.selectedIndex]
    },
    buttonColor () {
      return this.selectedItem.buttonColor || this.currentTheme.primary
    },
    filteredItems () {
      let self = this
      return this.items.filter(function (item) {
        return item.selection !== self.selectedItem.selection
      })
    }
  },
  methods: {
    selectStage (stage) {
      this.selectedStage = stage
      this.$emit('stageSelected', stage)
    }
  },
  watch: {
    selectedStage () {
      for (let i in this.items) {
        const item = this.items[i]
        if (item.selection === this.selectedStage) {
          this.selectedIndex = i
          return
        }
      }
    },
    stage () {
      this.selectedStage = this.stage
    }
  },
  created () {
    this.items = [
      {
        title: 'dev',
        selection: 'ece-dev',
        buttonColor: this.currentTheme.accent
      },
      {
        title: 'ECE nonprod',
        selection: 'ece-nonprod',
        buttonColor: this.currentTheme.success.base || this.currentTheme.success
      },
      {
        title: 'ECE prod',
        selection: 'ece-prod',
        buttonColor: this.currentTheme.success.base || this.currentTheme.success
      },
      {
        title: 'AMAP nonprod',
        selection: 'amap-nonprod',
        buttonColor: this.currentTheme.warning
      },
      {
        title: 'AMAP prod',
        selection: 'amap-prod',
        buttonColor: this.currentTheme.warning
      }
    ]
  }
}
</script>

<style scoped>
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}

.v-btn {
  width: 110px;
  height: 22px;
}
</style>
