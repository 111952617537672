import { USER_SETTING, UPDATE_USER_SETTING } from '../graphql/graphql'
import { apolloClient } from '@/vue-apollo'

export default {
  async  getUserSetting () {
    return apolloClient.query({ query: USER_SETTING })
  },
  updateUserSetting (updatedSetting) {
    return apolloClient.mutate({
      mutation: UPDATE_USER_SETTING,
      variables: updatedSetting
    })
  }
}
