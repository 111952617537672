import testJobHistoryService from '@/services/testJobHistoryService.js'
import {
  createLimit,
  createOffset,
  createOrder,
  createBetween,
  previousDate,
  createTotalPages,
  createCurrentPage
} from './_utilsStore'
import { convertDateTime } from '../../utils'

export const namespaced = true

export const state = {
  all: [],
  single: {},
  loading: false,
  chartLoading: false,
  search: null,
  time: { name: 'Last 30 Days', days: 30, from: 'today', custom: false },
  customTime: [
    previousDate(new Date(), 30),
    new Date()
  ],
  maxTime: new Date().toISOString().substr(0, 10),
  headers: [
    { text: 'ID ', value: 'id', width: '65px' },
    { text: 'Test Job', value: 'testJob.name' },
    { text: 'Status', value: 'status', width: '120px' },
    { text: 'Execution Start (GMT)', value: 'executionStart', width: '115px' },
    { text: 'Execution End (GMT)', value: 'executionEnd', width: '115px' },
    { text: 'Total', value: 'testCaseCount', width: '80px' },
    { text: 'Pass', value: 'passedCount', width: '80px' },
    { text: 'Max Time (sec)', value: 'maxResponseTime', width: '90px' },
    { text: 'Min Time (sec)', value: 'minResponseTime', width: '90px' },
    { text: 'Avg Time (sec)', value: 'avgResponseTime', width: '90px' },
    {
      text: 'Success %',
      value: 'successRate',
      width: '110px',
      sortable: false
    },
    { text: 'Actions', value: 'action', width: '105px', sortable: false }
  ],
  dataTableOptions: {
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: ['executionStart'],
    sortDesc: ['false'],
    multiSort: false,
    mustSort: true,
    totalItems: 0,
    totalPages: 0
  },
  defaultSort: {
    sortBy: ['executionStart'],
    sortDesc: ['false']
  },
  timeframe: [
    { name: 'Last 7 Days', days: 7, from: 'today', custom: false },
    { name: 'Last 30 Days', days: 30, from: 'today', custom: false },
    { name: 'Last 6 Months', days: 183, from: 'today', custom: false },
    { name: 'Last 12 Months', days: 365, from: 'today', custom: false },
    { name: 'All', days: 1000, from: 'today', custom: false },
    { name: 'Custom', custom: true }
  ],
  lineChart: {
    data: [],
    labels: []
  }
}

export const getters = {
  testJobHistories: state => state.testJobHistories
}
export const mutations = {
  FETCH_STARTS (state) {
    state.all = []
    state.loading = true
  },
  FETCH_ENDS (state, testJobHistories) {
    state.all = testJobHistories.data
    let paging = testJobHistories.meta.paging
    state.dataTableOptions.totalItems = paging.total
    state.dataTableOptions.totalPages = createTotalPages(
      paging.total, paging.limit
    )
    state.dataTableOptions.currentPage = createCurrentPage(
      paging.offset, paging.limit
    )
    state.loading = false
  },
  FETCH_STARTS_SINGLE (state) {
    state.single = {}
    state.loading = true
  },
  FETCH_ENDS_SINGLE (state, testJobHistory) {
    state.single = testJobHistory
    state.loading = false
  },
  LINE_GRAPH_STARTS (state) {
    state.chartLoading = true
  },
  LINE_GRAPH_ENDS (state, testJobHistories) {
    state.lineChart.data = []
    state.lineChart.labels = []
    for (let item of testJobHistories.data) {
      if (item.status !== 'SCHEDULED') {
        if (item.passedCount) {
          state.lineChart.data.unshift(
            (
              parseFloat(item.passedCount) / parseFloat(item.testCaseCount)
            ) * 100
          ).toFixed(2)
        } else {
          state.lineChart.data.unshift(0)
        }
        if (item.executionStart) {
          state.lineChart.labels.unshift(
            convertDateTime(
              item.executionStart, 'shortNumericDate', 'shortTime'
            )
          )
        } else {
          state.lineChart.labels.unshift('N/A')
        }
      }
    }
    state.chartLoading = false
  },
  UPDATE_TEST_JOB_HISTORY (state, updatedTJH) {
    let index = state.testJobHistories.findIndex(
      testjobhistory => testjobhistory.name === updatedTJH.name
    )
    state.testJobHistories.splice(index, 1, updatedTJH)
  },
  SET_SEARCH (state, data) {
    state.search = data
  },
  SET_TABLE_OPTIONS (state, data) {
    for (let setting in data) {
      state.dataTableOptions[setting] = data[setting]
    }
  },
  SET_TIME (state, data) {
    if (data == null) {
      state.time = {
        name: 'Last 30 Days', days: 30, from: 'today', custom: false
      }
    } else {
      state.time = data
    }
  },
  SET_CUSTOM_TIME (state, data) {
    let newTime = []
    for (let item of data) {
      newTime.push(new Date(item))
    }
    newTime.sort()
    state.customTime = newTime
  },
  SET_CUSTOM_TIME_NAME (state, data) {
    for (let item of state.timeframe) {
      if (item.custom === true) {
        if (data !== 'Custom') {
          let dateArray = []
          for (let item of state.customTime) {
            dateArray.push(
              convertDateTime(item, 'longNumericDate', 'shortTime')
            )
          }
          item.name = dateArray.join(' - ')
        } else {
          item.name = data
        }
      }
    }
  }
}

export const actions = {
  async fetch ({ commit }) {
    try {
      commit('FETCH_STARTS')
      const filter = {
        testJob: {
          name: {
            contains: state.search
          }
        },
        executionEnd: (state.time.custom === true)
          ? await createBetween(state.customTime[0], state.customTime[1], null)
          : await createBetween(null, 'today', state.time.days)
      }
      const {
        data, loading
      } = await testJobHistoryService.getTestJobHistories(
        {
          limit: await createLimit(state.dataTableOptions),
          offset: await createOffset(state.dataTableOptions),
          filter: filter,
          order: await createOrder(state.dataTableOptions)
        }
      )
      if (loading) {
        commit('FETCH_STARTS')
      }
      if (data) {
        commit('FETCH_ENDS', data.testJobHistories)
        return data.testJobHistories
      }
    } catch (error) {
      const errorMessage = 'Error while fetching test job history: ' +
        error.message
      console.log(errorMessage)
      console.log(error.response)
    }
  },
  async fetchSingle ({ commit }, args) {
    try {
      commit('FETCH_STARTS_SINGLE')
      const { data } = await testJobHistoryService.getTestJobHistory(args)
      commit('FETCH_ENDS_SINGLE', data.testJobHistory)
      return data
    } catch (error) {
      const errorMessage = 'Error while fetching test job history: ' +
        error.message
      console.log(errorMessage)
      console.log(error.response)
    }
  },
  async fetchChartData ({ commit }) {
    try {
      commit('LINE_GRAPH_STARTS')
      const filter = {
        testJob: {
          name: {
            contains: state.search
          }
        },
        executionEnd: (state.time.custom === true)
          ? await createBetween(state.customTime[0], state.customTime[1], null)
          : await createBetween(null, 'today', state.time.days)
      }
      const {
        data, loading
      } = await testJobHistoryService.getTestJobHistories(
        {
          limit: 30,
          filter: filter,
          order: await createOrder(state.defaultSort)
        }
      )
      if (loading) {
        commit('LINE_GRAPH_STARTS')
      }
      if (data) {
        commit('LINE_GRAPH_ENDS', data.testJobHistories)
        return data.testJobHistories
      }
    } catch (error) {
      const errorMessage = 'Error while fetching test job history: ' +
        error.message
      console.log(errorMessage)
      console.log(error)
    }
  }
}
