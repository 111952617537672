<template>
  <div>
    <v-alert
      v-model="alert"
      transition="scale-transition"
      dismissible
      :type="notification.type ? notification.type : 'info'"
      @click="deleteNotification">
      {{ notification.message }}
      <template v-if="notification.secondaryMessage">
        &nbsp;- {{ notification.secondaryMessage }}
      </template>
    </v-alert>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      timeout: null,
      alert: true
    }
  },
  computed: {},
  methods: {
    ...mapActions('notification', ['remove']),
    deleteNotification () {
      this.$store.commit('notification/DELETE', this.notification)
    }
  }
}
</script>

<style scoped>
.notification-bar {
  margin: 1em 0 1em;
}
</style>
