export const namespaced = true

const ssmls = [
  'eos=0',
  'eos=1',
  'readmode=explicit_eos',
  'lang=eng',
  'lang=deu',
  'mw',
  'nlu=BND:W',
  'nlu=BND:S',
  'nlu=BND:N',
  'nlu=PRM:0',
  'nlu=PRM:1',
  'nlu=PRM:2',
  'nlu=PRM:3',
  'pause=x',
  'pitch=x',
  'rate=x',
  'toi=lhp',
  'toi=nts',
  'toi=pyt',
  'toi=diacritized',
  'toi=orth'
]

export const state = {
  appNames: ['NTG6', 'NTG6_FUP2', 'NTG6_FUP3', 'OneSpeech_19', 'NTG7_FUP2'],
  isDebugModeEnabled: false,
  ntgVersionsChatBot: [
    'NTG6-Mid-SOP',
    'NTG6-Mid-FU1',
    'NTG6-High-FU1',
    'NTG6-Mid-FU2',
    'NTG6-High-FU2',
    'NTG6-Mid-FU3',
    'NTG6-High-FU3',
    'NTG6-High-FU3',
    'NTG7-Mid-SOP',
    'NTG7-High-SOP',
    'NTG7-Prem-SOP',
    'NTG7-PremPlus-SOP',
    'NTG7-Mid-FU2',
    'NTG7-High-FU2',
    'NTG7-Prem-FU2',
    'NTG7-PremPlus-FU2'
  ],
  ssmls
}

export const actions = {}

export const getters = {
  ssmlTags: state => {
    return state.ssmls
  }
}

export function previousDate (date, days) {
  let dateCopy = new Date(date)
  dateCopy.setDate(dateCopy.getDate() - days)
  return dateCopy
}

export async function createLimit (tableOptions) {
  return (tableOptions.itemsPerPage === -1)
    ? tableOptions.totalItems
    : tableOptions.itemsPerPage
}

export async function createOffset (tableOptions) {
  return (tableOptions.currentPage === 1)
    ? 0
    : ((tableOptions.currentPage - 1) * tableOptions.itemsPerPage)
}

export async function createOrder (tableOptions) {
  let model = null
  let field = tableOptions.sortBy[0]
  if (tableOptions.sortBy[0].includes('.')) {
    let splittedSortBy = tableOptions.sortBy[0].split('.')
    model = splittedSortBy[0].charAt(0).toUpperCase() +
      splittedSortBy[0].slice(1)
    field = splittedSortBy[1]
  }
  return {
    model: model,
    field: field,
    direction: (tableOptions.sortDesc[0] === false) ? 'ASC' : 'DESC'
  }
}

export async function createBetween (from, to, days) {
  let fromDate = from
  let toDate = to
  if (toDate === 'today') {
    toDate = new Date()
    fromDate = previousDate(new Date(), days)
  }
  return {
    from: fromDate.toISOString().slice(0, 10),
    to: toDate.toISOString().slice(0, 10)
  }
}

export function createTotalPages (total, limit) {
  return Math.ceil(total / limit)
}

export function createCurrentPage (offset, limit) {
  return (offset / limit) + 1
}
