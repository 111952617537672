import { apolloClient } from '@/vue-apollo'
import {
  ALL_TEST_RESULTS,
  DELETE_TEST_RESULT,
  BULK_DELETE_TEST_RESULTS,
  SUBSCRIPTION_TEST_RESULT
} from '../graphql/graphql'

export default {
  async getTestResults (args) {
    let testresults = await apolloClient.query({
      query: ALL_TEST_RESULTS, variables: args
    })
    return testresults
  },
  deleteTestResult (id) {
    return apolloClient.mutate({
      mutation: DELETE_TEST_RESULT, variables: { id }
    })
  },
  bulkDeleteTestResults (ids) {
    return apolloClient.mutate({
      mutation: BULK_DELETE_TEST_RESULTS, variables: { ids }
    })
  },
  subscribeToTestResults () {
    return apolloClient.subscribe({
      subscription: SUBSCRIPTION_TEST_RESULT
    })
  }
}
