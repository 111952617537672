import Vue from 'vue'
import Router from 'vue-router'
import TestJobHistory from '@/components/dataTables/TestJobHistory'
import TestCaseList from '@/components/dataTables/TestCase'
import TestJob from '@/components/dataTables/TestJob'
import TestResult from '@/components/dataTables/TestResult'
import HomeView from '@/components/HomeView.vue'
import ConfigurationList from '@/components/dataTables/Configuration'
import CarList from '@/components/dataTables/Car'
import SystemUnderTestList from '@/components/dataTables/SystemUnderTest'
import LanguageList from '@/components/dataTables/Language'
import Documentation from '@/components/Documentation'
import OidcCallback from '@/views/OidcCallback.vue'
import OidcPopupCallback from '@/views/OidcPopupCallback.vue'
import OidcCallbackError from '@/views/OidcCallbackError.vue'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import store from '@/store/store'
import TestJobRunHistory from '@/components/dataTables/TestJobRunHistory'
Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: { isPublic: true }
    },
    {
      path: '/testcases',
      name: 'TestCases',
      component: TestCaseList
    },

    {
      path: '/dashboard',
      name: 'dashboard',
      component: TestJobHistory
    },
    {
      path: '/testjob',
      name: 'TestJob',
      component: TestJob
    },
    {
      path: '/testjobrunhistory/:id(\\d+)',
      name: 'TestJobRunHistory',
      component: TestJobRunHistory
    },
    {
      path: '/testresult',
      name: 'TestResult',
      component: TestResult
    },
    {
      path: '/configurationlist',
      name: 'ConfigurationList',
      component: ConfigurationList
    },
    {
      path: '/carlist',
      name: 'CarList',
      component: CarList
    },
    {
      path: '/systemundertestlist',
      name: 'SystemUnderTestList',
      component: SystemUnderTestList
    },
    {
      path: '/languagelist',
      name: 'LanguageList',
      component: LanguageList
    },
    {
      path: '/documentation',
      name: 'documentation',
      component: Documentation,
      props: true
    },
    {
      path: '/oidc-callback',
      name: 'oidcCallback',
      component: OidcCallback
    },
    {
      path: '/oidc-popup-callback',
      name: 'oidcPopupCallback',
      component: OidcPopupCallback
    },
    {
      path: '/oidc-callback-error',
      name: 'oidcCallbackError',
      component: OidcCallbackError,
      meta: { isPublic: true }
    }
  ]
})

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'))

export default router
