import { ALL_PROMPTS, CREATE_PROMPT } from '../graphql/graphql'
import { apolloClient } from '@/vue-apollo'

export default {
  async  getPrompts () {
    return apolloClient.query({ query: ALL_PROMPTS })
  },
  async createPrompt (newPrompt) {
    return apolloClient.mutate({
      mutation: CREATE_PROMPT, variables: newPrompt
    })
  }
}
