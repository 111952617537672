<template>
  <v-dialog
    v-model="dialog"
    max-width="1200px"
    height="600px"
    persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <!-- Buttons to open dialog -->
      <v-tooltip
        v-if="!testCaseId"
        bottom
        open-delay="1000">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            icon
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            class="mr-1">
            <v-icon>
              mdi-plus-circle
            </v-icon>
          </v-btn>
        </template>
        <span>Add a new Test Case</span><br/>
      </v-tooltip>
      <v-tooltip
        v-else
        bottom
        open-delay="1000">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            icon
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }">
            <v-icon small>
              edit
            </v-icon>
          </v-btn>
        </template>
        <span>Edit the Test Case</span><br/>
      </v-tooltip>
    </template>
    <v-card v-if="dialog">
      <v-card-title>
        <h2 v-if="!testCaseId">Create Test Case</h2>
        <h2 v-else>Edit Test Case</h2>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container grid-list-md>
            <v-row> <!-- Test Case Form -->
              <v-col cols="12" sm="6" md="6"> <!-- Name -->
                <TextField
                  type="name"
                  store="testCase"
                  :index="0"
                  :required="true" />
              </v-col>
              <v-col cols="12" sm="6" md="6"> <!-- Domain -->
                <ComboboxField
                  type="domain"
                  store="testCase"
                  :required="true"
                  :index="0"
                  :addNew="true"
                  :multiple="false" />
              </v-col>
              <v-col cols="12" sm="6" md="6"> <!-- Language -->
                <ComboboxField
                  type="language"
                  store="testCase"
                  :index="0"
                  :required="true"
                  :multiple="false" />
              </v-col>
              <v-col cols="12" sm="6" md="6"> <!-- Platform -->
                <ComboboxField
                  type="platform"
                  store="testCase"
                  :index="0"
                  :required="true"
                  :multiple="false" />
              </v-col>
            </v-row>
            <v-row> <!-- Test Step Headline -->
              <h3 class="text-h5">Test Steps</h3>
              <v-spacer></v-spacer>
              <v-btn
                @click="addNewTestStep"
                icon>
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-row>
            <v-row :key="componentKey"> <!-- Test Steps -->
              <v-container fluid>
                <draggable
                  :list="editedTestSteps"
                  handle=".reorderHandle"
                  @change="detectReorder">
                  <v-card
                    v-for="(editedTestStep, index) in editedTestSteps"
                    :key="'editedTestStep' + index"
                    outlined>
                    <v-card-title>
                      <v-row class="pa-1">
                        <del v-if="editedTestStep.deleted">
                          {{ index + 1 }}. {{ editedTestStep.phrase }}
                        </del>
                        <span v-else>
                          {{ index + 1 }}. {{ editedTestStep.phrase }}
                        </span>
                        <v-spacer></v-spacer>
                        <div>
                          <v-tooltip
                            bottom
                            open-delay="1000"
                            v-if="editedTestSteps.length > 1">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                v-on="on"
                                class="reorderHandle">
                                <v-icon
                                  small>
                                  mdi-reorder-horizontal
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Reorder Test Step</span>
                          </v-tooltip>
                        </div>
                        <div v-if="editedTestStep.id">
                          <v-tooltip
                            bottom
                            open-delay="1000"
                            v-if="editedTestStep.deleted">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                v-on="on"
                                @click="restoreTestStep(index)">
                                <v-icon
                                  small>
                                  mdi-delete-restore
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Undo delete Test Step</span>
                          </v-tooltip>
                          <v-tooltip
                            bottom
                            open-delay="1000"
                            v-else>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                v-on="on"
                                @click="deleteTestStep(index)">
                                <v-icon
                                  small>
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Delete Test Step</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else>
                          <v-tooltip
                            bottom
                            open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                v-on="on"
                                @click="removeTestStep(index)">
                                <v-icon
                                  small>
                                  mdi-close
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Remove Test Step</span>
                          </v-tooltip>
                        </div>
                        <div>
                          <v-tooltip
                            bottom
                            open-delay="1000">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                small
                                v-on="on"
                                @click="toggle(index)">
                                <v-icon
                                  small>
                                  {{
                                    panel.includes(index)
                                    ? 'mdi-chevron-up'
                                    : 'mdi-chevron-down'
                                  }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>
                              {{ panel.includes(index) ? 'Close' : 'Open' }}
                              Test Step
                            </span>
                          </v-tooltip>
                        </div>
                      </v-row>
                    </v-card-title>
                    <v-expand-transition>
                      <div v-show="panel.includes(index)">
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-row class="pt-3">
                            <v-col cols="12" sm="6" md="6">
                              <TextField
                                type="phrase"
                                store="testStep"
                                :index="index"
                                :required="false"/>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                              <ComboboxField
                                type="intent"
                                store="testStep"
                                :index="index"
                                :required="true"
                                :addNew="true"
                                :multiple="false" />
                            </v-col>
                            <v-col cols="12">
                              <ComboboxField
                                type="prompts"
                                store="testStep"
                                :index="index"
                                :required="false"
                                :addNew="true"
                                :multiple="true" />
                            </v-col>
                            <v-col cols="12">
                              <v-file-input
                                accept=".ogg"
                                show-size
                                prepend-icon="mdi-file-music"
                                label="Test phrase audio"
                                outlined
                                dense
                                persistent-hint
                                hint="File needs to be in .ogg format!">
                              </v-file-input>
                              <div v-if="editedTestStep.recording">
                                <h6>Existing audio recording - </h6>
                                <a :href="editedTestStep.recording.dataUrl">
                                  {{
                                    editedTestStep.recording.fileName + '.ogg'
                                  }}
                                </a>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </div>
                    </v-expand-transition>
                  </v-card>
                </draggable>
              </v-container>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions> <!-- Close & Save Buttons -->
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
          text
          @click="saveTestCase"
          :disabled="!valid || editedTestSteps.length === 0"
          color="primary">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import draggable from 'vuedraggable'
import TextField from '@/components/formFields/TextField'
import ComboboxField from '@/components/formFields/ComboboxField'

export default {
  name: 'CreateEditTestCaseDialog',
  components: {
    draggable,
    TextField,
    ComboboxField
  },
  props: {
    testCaseId: Number
  },
  data () {
    return {
      componentKey: 0,
      dialog: false,
      valid: false,
      panel: [],
      rules: [v => !!v || 'Field is required'],
      rulesMultiple: [v => v.length > 0 || 'At least one item is required']
    }
  },
  computed: {
    ...mapState('language', { languages: 'all' }),
    ...mapState('prompt', { prompts: 'all' }),
    ...mapState('intent', { intents: 'all' }),
    ...mapState('testStep', {
      editedTestSteps: 'editedItem',
      undeleteableTestSteps: 'undeleteable'
    }),
    editedItem () {
      return this.$store.state.testCase.editedItem[0]
    }
  },
  methods: {
    addNewTestStep () {
      this.$store.commit('testStep/ADD_NEW_DEFAULT_TESTSTEP')
      this.panel = [this.editedTestSteps.length - 1]
    },
    updateDataTestStep () {
      this.testCase = this.$store.state.testCase.editedTestCase
      this.componentKey += 1
    },
    toggle (index) {
      if (this.panel.includes(index)) {
        let indexRemoveIndex = this.panel.indexOf(index)
        this.panel.splice(indexRemoveIndex, 1)
      } else {
        this.panel.push(index)
      }
    },
    deleteTestStep (position) {
      this.$store.commit('testStep/DELETE_TESTSTEP', position)
      this.updateDataTestStep()
    },
    removeTestStep (position) {
      this.$store.commit('testStep/REMOVE_TESTSTEP', position)
      this.updateDataTestStep()
    },
    restoreTestStep (position) {
      this.$store.commit('testStep/RESTORE_TESTSTEP', position)
      this.updateDataTestStep()
    },
    detectReorder (event) {
      this.$store.commit('testStep/REORDER_TESTSTEPS', event.moved)
    },
    async saveTestCase () {
      let newTestCase = null
      if (this.testCaseId) {
        newTestCase = await this.$store.dispatch(
          'testCase/save', this.testCaseId
        )
      } else {
        newTestCase = await this.$store.dispatch('testCase/save')
      }
      await this.$store.dispatch('testStep/saveTestStep', newTestCase)
      if (this.undeleteableTestSteps.length > 0) {
        for (let undeleteableTestStep of this.undeleteableTestSteps) {
          this.$store.commit('notification/PUSH', {
            message: 'The test step ' + undeleteableTestStep.phrase +
              ' (ID: ' + undeleteableTestStep.id +
              ') could not be deleted. Please restore it!',
            type: 'error'
          })
        }
      } else {
        this.closeDialog()
      }
    },
    async closeDialog () {
      this.$refs.form.reset()
      this.dialog = false
      this.panel = []
      this.$store.commit('testCase/SET_DEFAULT_TESTCASE')
      this.$store.commit('testStep/SET_DEFAULT_TESTSTEP', false)
      this.$store.commit('domain/CLEAR_NEW')
      this.$store.commit('intent/CLEAR_NEW')
      this.$store.commit('prompts/CLEAR_NEW')
      this.$store.dispatch('testCase/fetch')
    }
  },
  watch: {
    dialog (value) {
      if (value === true) {
        if (!this.testCaseId) {
          this.$store.commit('testCase/SET_DEFAULT_TESTCASE', true)
          this.$store.commit('testStep/SET_DEFAULT_TESTSTEP', true)
          this.panel = [0]
        } else {
          this.$store.commit('testCase/SET_EDITED_TESTCASE', this.testCaseId)
        }
      } else {
        this.closeDialog()
      }
    }
  }
}
</script>
