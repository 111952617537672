<template>
  <v-tooltip
    bottom
    open-delay="1000">
    <template v-slot:activator="{ on: tooltip, attrs }">
      <v-btn
        icon
        small
        v-bind="attrs"
        v-on="{...tooltip}"
        class="mr-1"
        @click.stop="download">
        <v-icon>
          mdi-download
        </v-icon>
      </v-btn>
    </template>
    <span>Download JSON file of the Test Cases</span>
  </v-tooltip>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'TestCaseDownloadDialog',
  computed: {
    ...mapState('testCase', ['all'])
  },
  methods: {
    async download () {
      let downloadTestCases = []
      this.all.forEach((testCase) => {
        console.log(testCase)
        downloadTestCases.push({
          'domain': testCase.domain.name,
          'testCase': testCase.name,
          'testPhrase': testCase.testSteps.length > 0 ? testCase.testSteps[0].phrase : null,
          'intent': testCase.testSteps.length > 0 ? testCase.testSteps[0].intent.name : null
        })
      })
      const ObjToWrite = {
        'testCases': downloadTestCases
      }
      let filename = 'testCases' + '_' + this.currentDateTime() + '.json'
      let element = document.createElement('a')
      element.setAttribute(
        'href',
        'data:application/json;charset=utf-8,' + encodeURIComponent(
          JSON.stringify(ObjToWrite)
        )
      )
      element.setAttribute('download', filename)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
      this.closeDialog()
    },
    currentDateTime () {
      const current = new Date()
      const date = current.getFullYear() + '-' + (current.getMonth() + 1) +
        '-' + current.getDate()
      const time = current.getHours() + ':' + current.getMinutes() + ':' +
        current.getSeconds()
      const dateTime = date + ' ' + time
      return dateTime
    }
  }
}
</script>
