import {
  ALL_TEST_JOBS,
  CREATE_TEST_JOB,
  DELETE_TEST_JOB,
  ADD_TEST_JOB_TEST_CASE,
  DELETE_TEST_JOB_TEST_CASE,
  PLAY_TEST_JOB,
  GET_A_TEST_JOB,
  UPDATE_TEST_JOB,
  PAUSE_A_TEST_JOB,
  RESUME_A_TEST_JOB,
  DELETE_A_TEST_JOB
} from '../graphql/graphql'
import { apolloClient } from '@/vue-apollo'

export default {
  async createTestJob (newTJ) {
    return apolloClient.mutate({
      mutation: CREATE_TEST_JOB, variables: newTJ
    })
  },
  getTJs (args) {
    return apolloClient.query({
      query: ALL_TEST_JOBS, fetchPolicy: 'no-cache', variables: args
    })
  },
  async getTJ (id) {
    return apolloClient.query({
      query: GET_A_TEST_JOB, variables: id
    })
  },
  deleteTJ (id) {
    return apolloClient.mutate({
      mutation: DELETE_TEST_JOB, variables: id
    })
  },
  async addTJ_TC (args) {
    return apolloClient.mutate({
      mutation: ADD_TEST_JOB_TEST_CASE, variables: args
    })
  },
  async deleteTJ_TC (args) {
    return apolloClient.mutate({
      mutation: DELETE_TEST_JOB_TEST_CASE, variables: args
    })
  },
  playTestJob (args) {
    return apolloClient.mutate({
      mutation: PLAY_TEST_JOB, variables: args
    })
  },
  updateTestJob (args) {
    const response = apolloClient.mutate({
      mutation: UPDATE_TEST_JOB, variables: args
    })
    return response
  },
  pauseTestJob (args) {
    return apolloClient.mutate({
      mutation: PAUSE_A_TEST_JOB, variables: args
    })
  },
  resumeTestJob (args) {
    return apolloClient.mutate({
      mutation: RESUME_A_TEST_JOB, variables: args
    })
  },
  deleteTestJob (args) {
    return apolloClient.mutate({
      mutation: DELETE_A_TEST_JOB, variables: args
    })
  }
}
