import configuationService from '@/services/configurationService.js'
import {
  createLimit,
  createOffset,
  createOrder,
  createTotalPages,
  createCurrentPage
} from './_utilsStore'

export const namespaced = true

export const state = {
  all: [],
  single: {},
  loading: false,
  search: null,
  dataTableOptions: {
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: ['name'],
    sortDesc: ['false'],
    multiSort: false,
    mustSort: true,
    totalItems: 0,
    totalPages: 0
  },
  headers: [
    { text: 'ID', value: 'id', width: '65px' },
    { text: 'Name', value: 'name' },
    { text: 'Car', value: 'car.name', width: '150px' },
    { text: 'SUT', value: 'systemUnderTest.name', width: '150px' },
    { text: 'Recognition', value: 'recognition', width: '130px' },
    { text: 'Actions', value: 'actions', sortable: false, width: '90px' }
  ],
  editedItem: [],
  default: {
    name: '',
    systemUnderTest: { id: 0, name: '' },
    car: { id: 0, name: '' },
    recognition: 'SPEECH'
  }
}

export const mutations = {
  FETCH_STARTS (state) {
    state.all = []
    state.loading = true
  },
  FETCH_ENDS (state, data) {
    state.all = data.data
    let paging = data.meta.paging
    state.dataTableOptions.totalItems = paging.total
    state.dataTableOptions.totalPages = createTotalPages(
      paging.total, paging.limit
    )
    state.dataTableOptions.currentPage = createCurrentPage(
      paging.offset, paging.limit
    )
    state.loading = false
  },
  SET_EDITED (state, id) {
    let toEdit = state.all.findIndex(obj => obj.id === id)
    let copied = JSON.parse(
      JSON.stringify(state.all[toEdit])
    )
    state.editedItem = [
      copied
    ]
  },
  SET_EDITED_FIELD (state, data) {
    for (let [field, value] of Object.entries(data.data)) {
      state.editedItem[0][field] = value
    }
  },
  SET_DEFAULT (state) {
    state.editedItem = [state.default]
  },
  SET_TABLE_OPTIONS (state, data) {
    for (let setting in data) {
      state.dataTableOptions[setting] = data[setting]
    }
  },
  SET_SEARCH (state, data) {
    state.search = data
  }
}

export const actions = {
  async fetch ({ commit }) {
    try {
      commit('FETCH_STARTS')
      const filter = {
        name: {
          contains: state.search
        }
      }
      const { data, loading } = await configuationService.getConfigurations(
        {
          limit: await createLimit(state.dataTableOptions),
          offset: await createOffset(state.dataTableOptions),
          filter: filter,
          order: await createOrder(state.dataTableOptions)
        }
      )
      if (loading) {
        commit('FETCH_STARTS')
      }
      if (data) {
        commit('FETCH_ENDS', data.configurations)
        return data.configurations
      }
    } catch (error) {
      console.error(error)
    }
  },
  async delete ({ state }, id) {
    try {
      const response = await configuationService.deleteConfiguration({
        id: id
      })
      console.log('Configuration deleted: ' + id)
      return response.data.deleteConfiguration
    } catch (error) {
      console.error(error)
    }
  },
  async save ({ state }, id) {
    try {
      let newItem = null
      let item = state.editedItem[0]
      let data = {
        name: item.name,
        systemUnderTestId: item.systemUnderTest.id,
        carId: item.car.id,
        recognition: item.recognition
      }
      if (id) {
        await configuationService.updateConfiguration({
          id: id,
          data: data
        })
      } else {
        newItem = await configuationService.createConfiguration({
          data: data
        })
      }
      return newItem
    } catch (error) {
      console.error(error)
    }
  }
}
