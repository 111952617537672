export const namespaced = true

export const state = {
  items: [
    { title: 'Home', icon: 'mdi-home', path: '/dashboard' },
    { title: 'Test Cases', icon: 'mdi-clipboard', path: '/testcases' },
    { title: 'Schedule', icon: 'mdi-clock', path: '/testjob' },
    { title: 'Test Results', icon: 'mdi-summit', path: '/testresult' },
    {
      title: 'Configuration',
      icon: 'mdi-tune',
      path: '/configurationlist'
    },
    {
      title: 'Settings',
      icon: 'mdi-cogs',
      path: '/carlist',
      submenu: [
        { title: 'Cars', icon: 'mdi-car', path: '/carlist' },
        {
          title: 'System Under Test',
          icon: 'mdi-pound',
          path: '/systemundertestlist'
        },
        { title: 'Language', icon: 'mdi-translate', path: '/languagelist' }
      ]
    },
    {
      title: 'Getting Started',
      icon: 'mdi-information',
      path: '/documentation'
    }
  ]
}
