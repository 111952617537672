
import Vue from 'vue'
import Vuetify from 'vuetify'
import colors from 'vuetify/lib/util/colors'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: { customProperties: true },
    dark: true,
    themes: {
      dark: {
        darkerBackground: colors.grey.darken4,
        lighterBackground: colors.grey.darken3,
        menuBackground: colors.grey.darken1,
        icon: colors.shades.white,
        primary: colors.blue.darken1,
        secondary: colors.blue.darken4,
        accent: colors.blue.lighten3,
        error: colors.red.darken4,
        warning: colors.yellow.darken4,
        success: colors.green.darken4,
        text: colors.shades.white
      },
      light: {
        darkerBackground: colors.grey.lighten3,
        lighterBackground: colors.grey.lighten1,
        menuBackground: colors.grey.lighten1,
        icon: colors.grey.darken3,
        primary: colors.blue.darken1,
        secondary: colors.blue.darken4,
        accent: colors.blue.lighten3,
        error: colors.red.darken4,
        warning: colors.yellow.darken4,
        success: colors.green.darken4,
        text: colors.grey.darken3
      }
    }
  }
})
