<template>
  <v-flex>
    <template v-if="botOnRightProp">
      <v-layout v-if="isYours" left row ml-2>
        <v-icon color="primary">{{ icon }}</v-icon>
        <v-flex pa-4 ml-3 mt-2 mb-2 mr-5 is-yours left-bubble primary>
          <v-progress-circular :width="3" color="amber" indeterminate />
        </v-flex>
        <v-spacer />
      </v-layout>

      <v-layout v-else right row mr-2>
        <v-spacer />
        <v-flex pa-4 ml-5 mt-2 mb-2 mr-3 is-not-yours right-bubble>
          <v-progress-circular :width="3" color="amber" indeterminate />
        </v-flex>
        <v-avatar size="32">
          <img :src="assets.mercedesLogo2" alt="avatar" />
        </v-avatar>
      </v-layout>
    </template>

    <template v-else>
      <v-layout v-if="isYours" right row mr-2>
        <v-spacer />
        <div class="pa-4 ml-5 mt-2 mb-2 mr-3 is-yours right-bubble primary">
          <v-progress-circular :width="3" color="amber" indeterminate />
        </div>
        <v-icon color="primary">{{ icon }}</v-icon>
      </v-layout>
      <v-layout v-else left row ml-2>
        <v-avatar size="32">
          <img :src="assets.mercedesLogo2" alt="avatar" />
        </v-avatar>
        <div class="pa-4 ml-3 mt-2 mb-2 mr-5 is-not-yours left-bubble">
          <v-progress-circular :width="3" color="amber" indeterminate />
        </div>
        <v-spacer />
      </v-layout>
    </template>
  </v-flex>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    msg: {
      type: Object
    },
    botOnRightProp: {}
  },
  computed: {
    icon () {
      return this.isYours ? 'person' : 'directions_car'
    },
    isYours () {
      return this.msg.author === 'me'
    },
    ...mapState(['assets'])
  }
}
</script>

<style lang="scss" scoped>
.left-bubble {
  border-radius: 10px 25px 25px 25px;
}
.is-yours {
  display: table;
}
.right-bubble {
  border-radius: 25px 10px 25px 25px;
}
.is-not-yours {
  display: table;
  color: #222222;
  background-color: #eaeaea;
}
</style>
