import gql from 'graphql-tag'

// Reads
export const ALL_TEST_RESULTS = gql`
  query testResults($limit: Int, $offset: Int, $filter: TestResultFilter, $order: OrderFilter) {
    testResults(limit: $limit, offset: $offset, filter: $filter, order: $order) {
      data {
        id
        testJobHistory {
          testJob {
            name
          }
        }
        testCase {
          id
          name
          domain {
            name
          }
          language {
            cerenceCode
          }
          testSteps {
            intent {
              name
            }
            phrase
            prompts {
              text
              language {
                cerenceCode
              }
            }
            slots {
              name
              text
            }
          }
        }
        wordErrorRate
        intent
        domain
        userIntention
        prompt
        passed
        latency
        transcription
        traceId
        sessionId
        preTtsText
        audioData
        createdAt
        responseUrl
      }
      meta{
        paging {
          total
          offset
          limit
        }
      }
    }
  }
`

export const TEST_JOB_RUN_HISTORY = gql`
  query testResults($limit: Int, $offset: Int, $filter: TestResultFilter, $order: OrderFilter) {
    testResults(limit: $limit, offset: $offset, filter: $filter, order: $order) {
      data {
        id
        testJobHistory {
          testJob {
            name
          }
        }
        testCase {
          id
          name
          domain {
            name
          }
          language {
            cerenceCode
          }
          testSteps {
            intent {
              name
            }
            phrase
            prompts {
              text
              language {
                cerenceCode
              }
            }
            slots {
              name
              text
            }
          }
        }
        wordErrorRate
        intent
        domain
        userIntention
        prompt
        passed
        latency
        transcription
        traceId
        sessionId
        preTtsText
        audioData
        responseUrl
      }
      meta{
        paging {
          total
          offset
          limit
        }
      }
    }
  }
`

export const DELETE_TEST_RESULT = gql`
  mutation deleteTestResult($id: Int!) {
    deleteTestResult(id: $id) {
      id
      passed
      intent
      domain
      wordErrorRate
      userIntention
      prompt
      latency
      responseUrl
      testCase {
        id
        name
      }
      testStep {
        id
        phrase
      }
    }
  }
`

export const BULK_DELETE_TEST_RESULTS = gql`
  mutation deleteTestResults($ids: [Int!]!) {
    deleteTestResults(ids: $ids) {
      id
    }
  }
`

export const ALL_FILES = gql`
  query uploads {
    uploads {
      filename
    }
  }
`

export const ALL_SLOTS = gql`
  query slots($limit: Int, $offset: Int, $filter: SlotFilter) {
    slots(limit: $limit, offset: $offset, filter: $filter) {
      data {
        id
        text
      }
      meta {
        paging {
          total
          offset
          limit
        }
      }
    }
  }
`
export const ALL_PROMPTS = gql`
  query prompts($limit: Int, $offset: Int, $filter: PromptFilter) {
    prompts(limit: $limit, offset: $offset, filter: $filter) {
      data {
        id
        text
      }
      meta {
        paging {
          total
          offset
          limit
        }
      }
    }
  }
`

export const ALL_TEST_CASES = gql`
  query testCases($limit: Int, $offset: Int, $filter: TestCaseFilter, $order: OrderFilter) {
    testCases(limit: $limit, offset: $offset, filter: $filter, order: $order) {
      data {
        id
        name
        domain {
          id
          name
          creator {
            id
            email
          }
        }
        language {
          id
          name
          iso
          cerenceCode
        }
        platform
        testSteps {
          id
          phrase
          intent{
            id
            name
          }
          prompts {
            id
            text
          }
          slots {
            id
            text
          }
          recording {
            id
            name
            fileName
            dataUrl
          }
          position
        }
        latestTestResult {
          createdAt
          passed
        }
        creator {
            id
            email
        }
      }
      meta {
        paging {
          total
          offset
          limit
        }
      }
    }
  }
`

export const ALL_TEST_STEPS = gql`
  query allTestSteps($limit: Int, $offset: Int, $filter: TestStepFilter) {
    testSteps(limit: $limit, offset: $offset, filter: $filter) {
      data {
        id
        phrase
        intent {
          id
          name
        }
        prompts {
          id
          text
        }
        slots {
          id
          name
        }
        recording {
          id
          fileName
        }
      }
      meta {
        paging {
          total
          offset
          limit
        }
      }
    }
  }
`
export const ALL_TEST_JOBS = gql`
  query testJobs($limit: Int, $offset: Int, $filter: TestJobFilter, $order: OrderFilter) {
    testJobs(limit: $limit, offset: $offset, filter: $filter, order: $order) {
      data {
        id
        name
        weekDays
        executionTime
        delete
        repeat
        pause
        configuration {
          id
          name
          systemUnderTest {
            name
          }
        }
        testCases {
          id
          name
          domain {
            name
          }
          language {
            name
          }
          creator {
            id
            email
          }
        }
        testResults {
          id
          passed
          latency
          wordErrorRate
          testCase {
            id
            name
          }
        }
        createdAt
        updatedAt
      }
      meta {
        paging {
          total
          offset
          limit
        }
      }
    }
  }
`

export const ALL_RECORDINGS = gql`
  query recordings($limit: Int, $offset: Int, $filter: RecordingFilter) {
    recordings(limit: $limit, offset: $offset, filter: $filter) {
      data {
        id
        name
        dataUrl
        fileName
        qualityRating
      }
      meta {
        paging {
          total
          offset
          limit
        }
      }
    }
  }
`

export const ALL_LANGUAGES = gql`
  query languages($limit: Int, $offset: Int, $filter: LanguageFilter, $order: OrderFilter) {
    languages(limit: $limit, offset: $offset, filter: $filter, order: $order) {
      data {
        id
        name
        iso
        cerenceCode
      }
      meta {
        paging {
          total
          offset
          limit
        }
      }
    }
  }
`
export const ALL_INTENTS = gql`
  query intents($limit: Int, $offset: Int, $filter: IntentFilter, $order: OrderFilter) {
    intents(limit: $limit, offset: $offset, filter: $filter, order: $order) {
      data {
        id
        name
      }
      meta {
        paging {
          total
          offset
          limit
        }
      }
    }
  }
`

export const ALL_DOMAINS = gql`
  query domains($limit: Int, $offset: Int, $filter: DomainFilter) {
    domains(limit: $limit, offset: $offset, filter: $filter) {
      data {
        id
        name
        creator {
          id
          email
        }
      }
      meta {
        paging {
          total
          offset
          limit
        }
      }
    }
  }
`
export const ALL_SYSTEM_UNDER_TEST = gql`
  query systemUnderTests($limit: Int, $offset: Int, $filter: SystemUnderTestFilter, $order: OrderFilter){
    systemUnderTests(limit: $limit, offset: $offset, filter: $filter, order: $order) {
      data {
        id
        name
      }
      meta {
        paging {
          total
          offset
          limit
        }
      }
    }
  }
`
export const CREATE_SYSTEM_UNDER_TEST = gql`
  mutation createSystemUnderTest($data: CreateSystemUnderTestInput!) {
    createSystemUnderTest(data: $data) {
      name
    }
  }
`
export const UPDATE_SYSTEM_UNDER_TEST = gql`
mutation updateSystemUnderTest($id: Int!, $data: UpdateSystemUnderTestInput!) {
  updateSystemUnderTest(id: $id, data: $data) {
    name
    id
  }
}`

export const DELETE_SYSTEM_UNDER_TEST = gql`
mutation deleteSystemUnderTest($id: Int!) {
  deleteSystemUnderTest(id: $id) {
    name
    id
  }
}`

export const CREATE_TEST_JOB = gql`
  mutation createTestJob($data: CreateTestJobInput!) {
    createTestJob(data: $data) {
      id
      name
      weekDays
      executionTime
      delete
      repeat
      pause
      configuration {
        id
        name
        systemUnderTest {
          name
        }
      }
      testCases {
        id
        name
        domain {
          name
        }
        language {
          name
        }
        creator {
          id
          email
        }
      }
      testResults {
        id
        passed
        latency
        wordErrorRate
        testCase {
          id
          name
        }
      }
      createdAt
      updatedAt
    }
    }

`

export const CREATE_DOMAIN = gql`
mutation createDomain($data: CreateDomainInput!) {
  createDomain(data: $data) {
    id
    name
  }
}
`

export const CREATE_INTENT = gql`
mutation createIntent($data: CreateIntentInput!) {
  createIntent(data: $data){
    id
    name
  }
}
`

export const CREATE_PROMPT = gql`
mutation createPrompt($data: CreatePromptInput!){
  createPrompt(data: $data)
  {
    id
    language {
      id
      name
    }
    text
  }
}
`

export const CREATE_SLOT = gql`
mutation createSlot($data: CreateSlotInput!){
  createSlot(data: $data){
    id
    name
    text
  }
}
`
export const CREATE_RECORDING = gql`
mutation createRecording($data: CreateRecordingInput!) {
  createRecording(data: $data) {
    id
    name
  }
}
`
export const UPLOAD_EXCEL = gql`
  mutation importExcel($data: ImportExcelInput!) {
    importExcel(data: $data) {
      name
    }
  }
`

export const UPLOAD_JSON = gql`
  mutation importJson($data: ImportExcelInput!) {
    importJson(data: $data){
      status
      message
    }
  }
`

export const UPLOAD_FILE = gql`
  mutation singleUpload($data: SingleUploadInput!) {
    singleUpload(data: $data) {
      filename
      mimetype
      encoding
    }
  }
`
export const CREATE_TEST_CASE = gql`
  mutation createTestCase($data: CreateTestCaseInput!) {
  createTestCase(data: $data) {
    id
    name
    domain {
      id
      name
    }
    language {
      id
      name
    }
    testSteps {
      id
      phrase
      intent {
        id
        name
      }
      prompts {
        id
        text
      }
    }
    platform
  }
}
`

export const DELETE_TEST_CASE = gql`
  mutation testCaseDelete($id: Int!) {
    deleteTestCase(id: $id) {
      name
    }
  }
`
export const BULK_DELETE_TEST_CASE = gql`
  mutation deleteTestCases($ids: [Int!]!) {
    deleteTestCases(ids: $ids) {
      id
      name
    }
  }
`
export const DELETE_TEST_STEP = gql`
  mutation deleteTestStep($id: Int!) {
    deleteTestStep(id: $id) {
      id
      phrase
    }
  }
`
export const DELETE_TEST_JOB = gql`
  mutation testJobDelete($id: Int!) {
    forceDeleteTestJob(id: $id) {
      name
    }
  }
`
export const BULK_DELETE_TEST_JOB = gql`
  mutation deleteTestJobs($ids: [Int!]!) {
    forceDeleteTestJobs(ids: $ids) {
      id
      name
    }
  }`

export const TEST_CASE = gql`
query testCase($id: Int!) {
  testCase(id: $id) {
        id
        name
        domain {
          id
          name
          creator {
            id
            email
          }
        }
        language {
          id
          name
          iso
          cerenceCode
        }
        platform
        testSteps {
          id
          phrase
          intent{
            id
            name
          }
          prompts {
            id
            text
          }
          slots {
            id
            text
          }
          recording {
            id
            name
            fileName
            dataUrl
          }
          position
        }
        creator {
            id
            email
        }
  }
}
`
export const UPDATE_TEST_CASE = gql`
  mutation updateTestCase($id: Int!, $data: UpdateTestCaseInput!) {
  updateTestCase(id: $id, data: $data) {
    id
    name
    domain {
      id
    }
    language {
      id
    }
    testSteps {
      id
      phrase
      position
      intent {
        id
        name
      }
      prompts {
        id
        text
      }
      slots {
        id
        name
        text
      }
      recording {
        id
        name
        fileName
        dataUrl
      }
    }
    creator {
      id
      email
    }
    platform
  }
}
`

export const UPDATE_TEST_JOB = gql`
mutation updateTestJob($id: Int!, $data: UpdateTestJobInput!) {
  updateTestJob(id: $id, data: $data){
    id
      name
      weekDays
      executionTime
      delete
      repeat
      pause
      configuration {
        id
        name
        systemUnderTest {
          name
        }
      }
      testCases {
        id
        name
        domain {
          name
        }
        language {
          name
        }
        creator {
          id
          email
        }
      }
      testResults {
        id
        passed
        latency
        wordErrorRate
        testCase {
          id
          name
        }
      }
      createdAt
      updatedAt
    }
  }
`
export const UPDATE_TEST_STEP = gql`
mutation updateTestStep($id: Int!, $data: UpdateTestStepInput!){
  updateTestStep(id: $id, data: $data){
    id
    position
    phrase
    intent {
        id
        name
      }
      prompts {
        id
        text
      }
    recording {
      id
      name
      fileName
      dataUrl
    }
  }
}
`
export const CREATE_TEST_STEP = gql`
mutation createTestStep($data:CreateTestStepInput!){
  createTestStep(data: $data){
    id
    position
    phrase
    intent {
        id
        name
      }
      prompts {
        id
        text
      }
    recording {
      id
      name
      fileName
      dataUrl
    }
    testCase {
      id
    }
  }
}
`
export const ADD_TEST_JOB_TEST_CASE = gql`
  mutation tctjAdd($data: AddTestCaseToTestJobInput!) {
    addTestCaseToTestJob(data: $data){
      id
      name
      testCases {
        id
        name
      }
    }
  }
`

export const DELETE_TEST_JOB_TEST_CASE = gql`
  mutation deleteTestCaseFromTestJob($data: DeleteTestCaseFromTestJobInput!) {
    deleteTestCaseFromTestJob(data: $data){
      id
      name
      testCases {
        id
        name
      }
    }
  }
`

export const CREATE_LANGUAGE = gql`
  mutation createLanguage($data: CreateLanguageInput!) {
    createLanguage(data: $data) {
        iso
        name
        id
        cerenceCode
      }
    }
`
export const UPDATE_LANGUAGE = gql`
  mutation updateLanguage($id: Int!, $data: UpdateLanguageInput!) {
    updateLanguage(id: $id, data: $data) {
      id
      name
      iso
      cerenceCode
    }
  }
`
export const DELETE_LANGUAGE = gql`
  mutation deleteLanguage($id: Int!) {
    deleteLanguage(id: $id) {
      id
    }
  }
`

export const GET_A_LANGUAGE = gql`
query language($id: Int!){
  language(id: $id){
    id
    name
    iso
  }
}`

export const PLAY_TEST_CASE = gql`
  mutation playTestCase($data: PlayTestCaseInput!) {
    playTestCase(data: $data) {
      id
      passed
      intent
      domain
      wordErrorRate
      userIntention
      prompt
      latency
      responseUrl
      transcription
      traceId
      sessionId
      preTtsText
      audioData
      testCase {
        id
        name
      }
      testStep {
        id
        phrase
      }
      createdAt
    }
  }
`
export const PLAY_TEST_JOB = gql`
  mutation playTestJob($data: PlayTestJobInput!){
    playTestJob(data: $data){
      id
      testCase{
        id
      }
      testStep {
        id
      }
      wordErrorRate
      intent
      domain
      userIntention
      prompt
      passed
      latency
      responseUrl
      transcription
      traceId
      sessionId
      preTtsText
      audioData
    }
  }`

export const CREATE_CONFIGURATION = gql`
mutation createConfiguration($data:CreateConfigurationInput!){
  createConfiguration(data: $data){
    id
    name
    recognition
    systemUnderTest{
      id
      name
    }
    car {
      id
      name
      vin
      location
      ntgVersion {
        id
        name
      }
      application {
        id
        name
      }
      locale {
        id
        name
      }
      market {
        id
        name
      }
    }
  }
}`

export const UPDATE_CONFIGURATION = gql`
mutation updateConfiguration($id: Int!, $data:UpdateConfigurationInput!){
  updateConfiguration(id: $id, data: $data){
    id
    name
    recognition
    systemUnderTest{
      id
      name
    }
    car {
      id
      name
      vin
      location
      ntgVersion {
        id
        name
      }
      application {
        id
        name
      }
      locale {
        id
        name
      }
      market {
        id
        name
      }
    }
  }
}`

export const DELETE_CONFIGURATION = gql`
mutation deleteConfiguration($id: Int!){
  deleteConfiguration(id: $id){
    id
  }
}`

export const GET_ALL_CONFIGURATIONS = gql`
  query configurations($limit: Int, $offset: Int, $filter: ConfigurationFilter, $order: OrderFilter) {
  configurations(limit: $limit, offset: $offset, filter: $filter, order: $order) {
    data {
      id
      name
      recognition
      systemUnderTest{
        id
        name
      }
      car {
        id
        name
        vin
        location
        ntgVersion {
          id
          name
        }
        application {
          id
          name
        }
        locale {
          id
          name
        }
        market {
          id
          name
        }
      }
    }
    meta {
      paging {
        total
        offset
        limit
      }
    }
  }
}`

export const GET_A_CONFIGURATION = gql`
query configuration($id:Int!){
  configuration(id: $id){
    id
  }
}`

export const CREATE_MARKET = gql`
mutation createMarket($data:CreateMarketInput!){
  createMarket(data: $data){
    id
  }
}`

export const UPDATE_MARKET = gql`
mutation updateMarket($id: Int!, $data:UpdateMarketInput!){
  updateMarket(id: $id, data: $data){
    id
  }
}`

export const DELETE_MARKET = gql`
mutation deleteMarket($id: Int!){
  deleteMarket(id: $id){
    id
  }
}`

export const GET_ALL_MARKETS = gql`
  query markets($limit: Int, $offset: Int, $filter: MarketFilter) {
    markets(limit:$limit, offset:$offset, filter:$filter){
    data {
      id
      name
    }
    meta {
      paging {
        total
        offset
        limit
      }
    }
  }
}`

export const GET_A_MARKET = gql`
query market($id:Int!){
  market(id: $id) {
    id
    name
  }
}`

export const CREATE_CAR = gql`
mutation createCar($data: CreateCarInput!) {
  createCar(data: $data) {
    id
    name
    vin
    location
    ntgVersion {
      id
      name
    }
    application {
      id
      name
    }
    locale {
      id
      name
    }
    market {
      id
      name
    }
  }
}`

export const UPDATE_CAR = gql`
mutation updateCar($id: Int!, $data: UpdateCarInput!){
  updateCar(id: $id, data: $data){
    id
    name
    vin
    location
    ntgVersion {
      id
      name
    }
    application {
      id
      name
    }
    locale {
      id
      name
    }
    market {
      id
      name
    }
  }
}`

export const DELETE_CAR = gql`
mutation deleteCar($id: Int!) {
  deleteCar(id: $id){
    id
  }
}`

export const GET_ALL_CARS = gql`
query cars($limit: Int, $offset: Int, $filter: CarFilter, $order: OrderFilter) {
  cars(limit: $limit, offset: $offset, filter: $filter, order: $order) {
    data {
      id
      name
      vin
      location
      ntgVersion {
        id
        name
      }
      application {
        id
        name
      }
      locale {
        id
        name
      }
      market {
        id
        name
      }
    }
    meta {
      paging {
        total
        offset
        limit
      }
    }
  }
}`

export const GET_A_CAR = gql`
query car($id:Int!) {
  car(id: $id){
    id
    name
    vin
    location
    ntgVersion {
      id
      name
    }
    application {
      id
      name
    }
    locale {
      id
      name
    }
    market {
      id
      name
    }
  }
}`

export const ALL_TEST_JOB_HISTORY = gql`
query testJobHistories ($limit: Int, $offset: Int, $filter: TestJobHistoryFilter, $order: OrderFilter) {
  testJobHistories (limit: $limit, offset: $offset, filter: $filter, order: $order) {
    data {
      id
      testJob {
        id
        name
      }
      status
      testCaseCount
      passedCount
      executionStart
      executionEnd
      maxResponseTime
      avgResponseTime
      minResponseTime
    }
    meta {
      paging {
        total
        offset
        limit
      }
    }
  }
}`

export const TEST_JOB_HISTORY = gql`
query testJobHistory ($id: Int) {
  testJobHistory (id: $id) {
    id
    testJob {
      id
      name
    }
    status
    testCaseCount
    passedCount
    executionStart
    executionEnd
    maxResponseTime
    avgResponseTime
    minResponseTime
  }
}`

export const ALL_NTG_VERSIONS = gql`
query ntgVersions ($limit: Int, $offset: Int, $filter: NtgVersionFilter) {
  ntgVersions (limit: $limit, offset: $offset, filter: $filter) {
    data {
      id
      name
    }
    meta {
      paging {
        total
        offset
        limit
      }
    }
  }
}`

export const ALL_APPLICATIONS = gql`
query applications ($limit: Int, $offset: Int, $filter: ApplicationFilter) {
  applications (limit: $limit, offset: $offset, filter: $filter) {
    data {
      id
      name
    }
    meta {
      paging {
        total
        offset
        limit
      }
    }
  }
}`
export const ALL_LOCALES = gql`
query locales ($limit: Int, $offset: Int, $filter: LocaleFilter) {
  locales (limit: $limit, offset: $offset, filter: $filter) {
    data {
      id
      name
    }
    meta {
      paging {
        total
        offset
        limit
      }
    }
  }
}`

export const USER_SETTING = gql`
query userSetting {
  userSetting {
    defaultConfiguration {
      id
      name
      recognition
      systemUnderTest {
        name
      }
      car {
        name
      }
    }
  }
}`

export const UPDATE_USER_SETTING = gql`
mutation updateUserSetting($data: UpdateUserSettingInput!) {
  updateUserSetting(data: $data) {
      defaultConfiguration {
        id
        name
        recognition
        systemUnderTest {
          name
        }
        car {
          name
        }
    }
  }
}`
export const SUBSCRIPTION_TEST_RESULT = gql`
subscription {
  testResult {
    data {
      id
      passed
      intent
      domain
      wordErrorRate
      userIntention
      prompt
      latency
      responseUrl
      transcription
      traceId
      sessionId
      preTtsText
      audioData
      testCase {
        id
        name
      }
      testStep {
        id
        phrase
      }
      createdAt
      }
    }
}`

export const SUBSCRIPTION_TEST_JOB_HISTORY = gql`
  subscription {
    testJobHistory {
      data {
        id
        testJob {
        id
        }
        status
        testCaseCount
        passedCount
        executionStart
        executionEnd
        maxResponseTime
        avgResponseTime
        minResponseTime
      }
    }
}`

export const DOWNLOAD_MVAP_RES_JSON = gql`
query downLoadMVAPJson($containerId: String!, $fileId: String!) {
  downLoadMVAPJson(containerId: $containerId, fileId: $fileId)
}`

export const CREATE_USER = gql`
mutation createUser($data: CreateUserInput!) {
  createUser(data: $data) {
    id
    email
    sub
    roles { id }
  }
}`

export const UPDATE_USER = gql`
mutation updateUser($data: UpdateUserInput!) {
  updateUser(data: $data) {
    id
    email
    sub
    roles { id }
  }
}`

export const DELETE_USER = gql`
mutation deleteUser($id: Int!) {
  deleteUser(id: $id) {
    id
    email
    sub
    roles { id }
  }
}`

export const CREATE_ROLE = gql`
mutation createRole($data: CreateRoleInput!) {
  createRole(data: $data) {
    id
    name
  }
}`

export const UPDATE_ROLE = gql`
mutation updateRole($data: UpdateRoleInput) {
  updateRole(data: $data) {
    id
    name
  }
}`

export const DELETE_ROLE = gql`
mutation deleteRole($id: Int!) {
  deleteRole(id: $id) {
    id
    name
  }
}`

export const USER = gql`
query user($id: Int!) {
  user(id: $id){
    id
    email
    sub
    roles { id }
  }
}`

export const USERS = gql`
query users($limit: Int, $offset: Int, $filter: UserFilter) {
  users(limit:$limit, offset: $offset, filter: $filter){
    data {
      id
      email
      sub
      roles { id }
    }
  }
}`

export const ROLE = gql`
query role($id: Int!) {
  role(id: $id){
    id
    name
  }
}`

export const ROLES = gql`
query roles($limit: Int, $offset: Int, $filter: RoleFilter) {
  roles(limit:$limit, offset: $offset, filter: $filter){
    data {
      id
      name
    }
  }
}`

export const UPLOAD_AUDIO = gql`
mutation uploadAudio($data: UploadAudioInput!) {
  uploadAudio(data: $data) {
      id
      name
      fileName
      dataUrl
      qualityRating
  }
}`

export const GET_A_TEST_JOB = gql`
query TestJob($id: Int!){
  testJob(id: $id){
        id
        name
        weekDays
        executionTime
        delete
        repeat
        pause
        configuration {
          id
          name
          systemUnderTest {
            name
          }
        }
        testCases {
          id
          name
          domain {
            name
          }
          language {
            name
          }
          creator {
            id
            email
          }
        }
        testResults {
          id
          passed
          latency
          wordErrorRate
          testCase {
            id
            name
          }
        }
        createdAt
        updatedAt
      }
}
`
export const PAUSE_A_TEST_JOB = gql`
  mutation pauseTestJob($id: Int!){
  pauseTestJob(id: $id){
    id
    name
    weekDays
    executionTime
    delete
    repeat
    pause
    configuration {
      id
      name
      systemUnderTest {
        name
      }
    }
    testCases {
      id
      name
      domain {
        name
      }
      language {
        name
      }
      creator {
        id
        email
      }
    }
    testResults {
      id
      passed
      latency
      wordErrorRate
      testCase {
        id
        name
      }
    }
    createdAt
    updatedAt
  }
}
`

export const RESUME_A_TEST_JOB = gql`
  mutation resumeTestJob($id: Int!){
  resumeTestJob(id: $id){
    id
    name
    weekDays
    executionTime
    delete
    repeat
    pause
    configuration {
      id
      name
      systemUnderTest {
        name
      }
    }
    testCases {
      id
      name
      domain {
        name
      }
      language {
        name
      }
      creator {
        id
        email
      }
    }
    testResults {
      id
      passed
      latency
      wordErrorRate
      testCase {
        id
        name
      }
    }
    createdAt
    updatedAt
  }
}
`
export const DELETE_A_TEST_JOB = gql`
  mutation deleteTestJob($id: Int!){
  deleteTestJob(id: $id){
    id
    name
    weekDays
    executionTime
    delete
    repeat
    pause
    configuration {
      id
      name
      systemUnderTest {
        name
      }
    }
    testCases {
      id
      name
      domain {
        name
      }
      language {
        name
      }
      creator {
        id
        email
      }
    }
    testResults {
      id
      passed
      latency
      wordErrorRate
      testCase {
        id
        name
      }
    }
    createdAt
    updatedAt
  }
}
`
