<template>
  <v-dialog v-model="dialog" width="400px" scrollable>
    <template #activator="{ on }">
      <v-btn
        v-on="on"
        color="warning"
        class="language-button"
        height="20"
        small
        rounded
      >
        <div class="white--text">
          {{ value.language }}
        </div>
      </v-btn>
    </template>
    <v-card v-if="dialog">
      <v-card-title primary-title>
        <h2>Supported Languages</h2>
        <v-spacer />
        <v-btn @click="dialog = false" icon small>
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item
            v-for="language in supportedLanguages"
            :key="language.name"
            @click="
              onLanguageSelect(language.value);
              dialog = false;
            "
          >
            <v-list-item-action>
              <v-list-item-title v-text="language.value"></v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {},
  props: {
    value: {},
    label: {}
  },
  computed: {
    ...mapState('language', ['supportedLanguages'])
  },
  data () {
    return {
      dialog: false
    }
  },
  watch: {},
  methods: {
    onLanguageSelect (language) {
      this.value.language = language
      this.$emit('input', this.value)
    }
  }
}
</script>

<style scoped>
.language-button {
  width: 110px;
  height: 22px;
  margin-bottom: -16px;
}
</style>
