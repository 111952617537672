<template>
  <v-layout
    align-center
    column
    justify-center
    align-content-center
    style="min-height: 500px">
    <h1 class="font-weight-light">
      Test Automation Platform
    </h1>
    <h2 class="headline">
      The testing tool for #HeyMercedes domains
    </h2>
    <div class="mt-5">
      <v-btn
        class="mt-5 mr-2"
        color="primary"
        data-testid="login-btn"
        large @click.prevent="authenticateOidcPopup">
        Login
      </v-btn>
    </div>
  </v-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { onLogout, onLogin, apolloClient } from '@/vue-apollo'
export default {
  computed: {
    ...mapState('oidcStore', ['user']),
    ...mapState(['assets'])
  },
  methods: {
    ...mapActions('oidcStore', [
      'authenticateOidcPopup',
      'removeOidcUser'
    ]),
    userLoaded: function (e) {
      console.log(
        'I am listening to the user loaded event in vuex-oidc',
        e.detail
      )
      onLogin(apolloClient, e.detail.access_token)
      this.$router.push('/dashboard').catch(() => true)
    },
    oidcError: function (e) {
      console.log(
        'I am listening to the oidc oidcError event in vuex-oidc',
        e.detail
      )
    },
    automaticSilentRenewError: function (e) {
      console.log(
        'I am listening to the automaticSilentRenewError event in vuex-oidc',
        e.detail
      )
    },
    signOut: function () {
      this.removeOidcUser().then(() => {
        onLogout(apolloClient)
        this.$router.push('/')
      })
    }
  },
  mounted () {
    window.addEventListener('vuexoidc:userLoaded', this.userLoaded)
    window.addEventListener('vuexoidc:oidcError', this.oidcError)
    window.addEventListener(
      'vuexoidc:automaticSilentRenewError', this.automaticSilentRenewError
    )
  },
  destroyed () {
    window.removeEventListener('vuexoidc:userLoaded', this.userLoaded)
    window.removeEventListener('vuexoidc:oidcError', this.oidcError)
    window.removeEventListener(
      'vuexoidc:automaticSilentRenewError', this.automaticSilentRenewError
    )
  }
}
</script>
