import domainService from '@/services/domainService.js'

export const namespaced = true

export const state = {
  single: {},
  all: [],
  loading: false,
  new: []
}

export const getters = {
}

export const mutations = {
  ADD (state, domain) {
    state.all.push({ name: domain })
  },
  FETCH_STARTS (state) {
    state.loading = true
  },
  FETCH_ENDS (state, domains) {
    state.all = domains
    state.loading = false
  },
  CREATE_NEW (state, data) {
    state.new.splice(data.position, 1, { name: data.item })
  },
  CLEAR_NEW (state) {
    state.new = []
  }
}
export const actions = {
  async fetch ({ commit }) {
    commit('FETCH_STARTS')
    try {
      let response = await domainService.getDomains()
      commit(
        'FETCH_ENDS', JSON.parse(JSON.stringify(response.data.domains.data))
      )
    } catch (error) {
      const errorMessage = 'Error while fetching Domains: ' + error.message
      console.log(errorMessage)
      console.log(error.response.data)
    }
  },
  async create ({ state }) {
    try {
      if (state.new) {
        let response = await domainService.createDomain(
          { data: state.new[0] }
        )
        state.all.push(response.data.createDomain)
        return response.data.createDomain
      }
      return null
    } catch (error) {
      const errorMessage = 'Error while creating Domains: ' + error.message
      console.log(errorMessage)
      console.log(error.response.data)
    }
  }
}
