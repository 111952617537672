<template>
  <v-card class="ma-3">
    <v-data-table
      :loading="loading"
      loading-text="Test Results Loading..."
      v-model="selected"
      :headers="headers"
      :items="testresults"
      show-select
      hide-default-footer
      :server-items-length="dataTableOptions.totalItems"
      :options.sync="dataTableOptions"
      :footer-props="dataTableOptions.footerProps">
      <template v-slot:top>
        <v-toolbar>
          <v-fade-transition>
            <v-btn
              style="min-width: 0"
              v-show="selected.length > 0"
              class="pa-0 mr-4"
              small
              text
              @click="bulkDelete()">
              <v-icon>delete_forever</v-icon>
            </v-btn>
          </v-fade-transition>
          <v-toolbar-title>
            <v-icon class="mb-1 mr-2">summarize</v-icon>
            Test Cases Results
          </v-toolbar-title>
          <v-spacer />
          <v-text-field
            v-model="search"
            label="Search Test Case"
            prepend-inner-icon="search"
            outlined
            dense
            clearable
            hide-details />
        </v-toolbar>
      </template>
      <template v-slot:[`item.prompt`]="{ item }">
        <span style="word-wrap:break-word;">{{ item.prompt }}</span>
      </template>
      <template v-slot:[`item.latency`]="{ item }">
        <span>{{ (parseFloat(item.latency) / 1000).toFixed(2) }}</span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <span>{{ item.createdAt | convertDateTime }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-layout>
          <DeleteDialog store="testResult" :item="item" />
        </v-layout>
      </template>
      <template v-slot:[`item.passed`]="{ item }">
        <ResultChip :result="item.passed" />
      </template>
    </v-data-table>
    <Pagination type="testResult" />
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ResultChip from '@/components/fragments/ResultChip'
import Pagination from '@/components/fragments/Pagination'
import DeleteDialog from '@/components/dialogs/Delete'

export default {
  name: 'TestResultDataTable',
  components: {
    ResultChip,
    Pagination,
    DeleteDialog
  },
  data () {
    return {
      selected: []
    }
  },
  computed: {
    ...mapState('userSetting', ['userSetting', 'userSettingLoading']),
    ...mapState('testResult', ['loading', 'headers']),
    ...mapState('testCase', ['busy']),
    testresults: {
      get () {
        return this.$store.state.testResult.all
      },
      set () {
        console.log('set TestResult')
      }
    },
    dataTableOptions: {
      get () {
        return this.$store.state.testResult.dataTableOptions
      },
      set (value) {
        this.$store.commit('testResult/SET_TABLE_OPTIONS', value)
        this.fetch()
      }
    },
    search: {
      get () {
        return this.$store.state.testResult.search
      },
      set (value) {
        this.$store.commit('testResult/SET_SEARCH', value)
        this.fetch()
      }
    }
  },
  methods: {
    ...mapActions('testResult', [
      'fetch',
      'bulkDeleteTestResults'
    ]),
    ...mapActions('testCase', ['playTestCase'])
  },
  created () {
    this.fetch()
  }
}
</script>
