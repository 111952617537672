import roleService from '@/services/roleService.js'

export const namespaced = true

export const state = {
  roles: [],
  roleLoading: true,
  totalRoles: 0
}

export const getters = {
  roles: state => state.roles,
  roleLoading: state => state.roleLoading,
  totalRoles: state => state.totalRoles
}

export const mutations = {
  ADD_ROLE (state, role) {
    state.roles.push(role)
  },
  SET_ROLE (state, role) {
    state.role = role
  },
  FETCH_ROLES_STARTS (state) {
    state.roleLoading = true
    state.roles = []
    state.totalRoles = 0
  },
  FETCH_ROLES_ENDS (state, roles) {
    state.roles = roles
    state.roleLoading = false
    state.totalRoles = roles.length
  },
  FETCH_ROLE_STARTS (state) {
    state.roleLoading = true
    state.role = {}
  },
  FETCH_ROLE_ENDS (state, role) {
    state.role = role
    state.roleLoading = false
  },
  DELETE_ROLE (state, id) {
    state.roles = state.roles.filter(
      role => role.id !== id
    )
  },
  UPDATE_ROLE (state, updatedRole) {
    let index = state.roles.findIndex(
      role => role.name === updatedRole.name
    )
    state.roles.splice(index, 1, updatedRole)
  }
}

export const actions = {
  async fetchRoles ({ commit }) {
    commit('FETCH_ROLES_STARTS')
    await roleService.getRoles().then(
      response => {
        let { data } = response.data.roles
        commit('FETCH_ROLES_ENDS', data)
      })
      .catch(error => {
        const errorMessage = 'Error while fetching Roles: ' + error.message
        console.log(errorMessage)
        console.log(error.response.data)
      })
  },
  async fetchRole ({ commit }, id) {
    commit('FETCH_ROLE_STARTS')
    await roleService.getRole(id).then(
      response => {
        let role = response.data.role
        commit('FETCH_ROLE_ENDS', role)
      }).catch(error => {
      const errorMessage = 'Error while fetching Role: ' + error.message
      console.log(errorMessage)
      console.log(error.message.data)
    })
  },
  createRole ({ commit, dispatch }, newRole) {
    return new Promise(function (resolve, reject) {
      roleService.createRole(newRole).then(
        response => {
          commit('ADD_ROLE', response.data.createRole)
        }
      )
    })
  },
  updateRole ({ commit }, updatedRole) {
    return new Promise(function (resolve, reject) {
      roleService.updateRole(updatedRole).then(
        response => {
          commit('UPDATE_ROLE', response.data.updateRole)
        }
      )
    })
  },
  deleteRole ({ commit }, id) {
    return new Promise(function (resolve, reject) {
      roleService.deleteRole(id).then(
        response => {
          commit('DELETE_ROLE', id)
        }
      )
    })
  }
}
