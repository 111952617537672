<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    nudge-bottom="51">
    <template v-slot:activator="{ on }">
      <v-btn icon>
        <v-icon large v-on="on">account_circle</v-icon>
      </v-btn>
    </template>
    <v-card width="600">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon large>account_circle</v-icon>
          </v-list-item-avatar>
          <v-list-item-content v-if="user">
            <v-list-item-title v-if="user.name">
              {{ user.name }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="user.email">
              {{ user.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              text
              color="primary"
              @click="logout">
              logout
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-action>
            <v-switch v-model="showChat" color="primary"></v-switch>
          </v-list-item-action>
          <v-list-item-title>
            {{ showChat ? "Chat enabled" : "Chat disabled" }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { onLogout, apolloClient } from '@/vue-apollo'

export default {

  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
    showChat: false,
    showTts: false
  }),
  computed: {
    ...mapState('oidcStore', ['user'])
  },
  watch: {
    showChat () {
      this.$emit('switch-show-chat', this.showChat)
    },
    showTts () {
      this.$emit('switch-show-tts', this.showTts)
    }
  },
  methods: {
    logout: function () {
      this.removeOidcUser().then(() => {
        onLogout(apolloClient)
        this.$router.push('/')
      })
    },
    ...mapActions('oidcStore', ['removeOidcUser'])
  }
}
</script>
