import intentService from '@/services/intentService.js'

export const namespaced = true

export const state = {
  single: {},
  all: [],
  loading: false,
  new: []
}

export const getters = {
}

export const mutations = {
  ADD (state, intent) {
    state.all.push(intent)
  },
  FETCH_STARTS (state) {
    state.loading = true
  },
  FETCH_ENDS (state, intents) {
    state.all = intents.data
    state.loading = false
  },
  CREATE_NEW (state, data) {
    state.new.splice(data.position, 1, { name: data.item })
  },
  CLEAR_NEW (state) {
    state.new = []
  }
}
export const actions = {
  async fetch ({ commit }) {
    commit('FETCH_STARTS')
    try {
      let { data, loading } = await intentService.getIntents({
        order: {
          model: null,
          field: 'name',
          direction: 'ASC'
        },
        filter: {}
      })
      if (loading) {
        commit('FETCH_STARTS')
      }
      if (data) {
        commit('FETCH_ENDS', data.intents)
        return data.intents
      }
    } catch (error) {
      const errorMessage = 'Error while fetching Intents: ' + error.message
      console.log(errorMessage)
      console.log(error.response.data)
    }
  },
  async create ({ state, commit }, data) {
    try {
      let response = null
      if (state.new) {
        if (data.item instanceof Object) {
          response = await intentService.createIntent(
            { data: data.item }
          )
        } else {
          response = await intentService.createIntent(
            { data: { name: data.item } }
          )
        }
        return response.data.createIntent
      }
      return null
    } catch (error) {
      const errorMessage = 'Error while creating Intent: ' + error.message
      console.log(errorMessage)
      console.log(error.response.data)
    }
  }
}
