<script>
import { Line } from 'vue-chartjs'

export default {
  name: 'Charts',
  extends: Line,
  props: {
    chartData: {
      type: Array,
      required: false
    },
    chartLabel: {
      type: Array,
      required: true
    }
  },
  mounted () {
    this.renderChart(
      {
        labels: this.chartLabel,
        datasets: [
          {
            label: 'Success Rate',
            borderColor: 'white',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: 'white',
            backgroundColor: 'transparent',
            data: this.chartData
          }
        ]
      },
      {
        scales: {
          yAxes: [{
            ticks: { beginAtZero: true },
            gridLines: { display: true }
          }],
          xAxes: [{
            gridLines: { display: false }
          }]
        },
        legend: { display: true },
        responsive: true,
        maintainAspectRatio: false
      }
    )
  }
}

</script>

<style scoped>
  div {
    width: 100%;
  }
  canvas {
    aspect-ratio: auto;
  }
</style>
