<template>
  <v-flex>
    <template v-if="botOnRightProp">
      <v-layout v-if="isYours" left row ml-2>
        <v-icon color="primary">{{ icon }}</v-icon>
        <div
          @click="$emit('chat-bubble-clicked')"
          class="pa-4 ml-3 mt-2 mb-2 mr-5 is-yours bubble left-bubble primary"
        >
          <slot name="bubble-content" />
        </div>
        <v-spacer />
      </v-layout>
      <v-layout v-else right row mr-2>
        <v-spacer />
        <v-flex
          @click="$emit('chat-bubble-clicked')"
          class="pa-4 ml-5 mt-2 mb-2 mr-3 is-not-yours bubble right-bubble"
        >
          <slot name="bubble-content" />
        </v-flex>
        <v-avatar size="32">
          <img :src="assets.mercedesLogo2" alt="avatar" />
        </v-avatar>
      </v-layout>
    </template>

    <template v-else>
      <v-layout v-if="isYours" right row mr-2>
        <v-spacer />
        <div
          @click="$emit('chat-bubble-clicked')"
          class="pa-4 ml-5 mt-2 mb-2 mr-3 is-yours bubble right-bubble primary"
        >
          <slot name="bubble-content" />
        </div>
        <v-icon color="primary">{{ icon }}</v-icon>
      </v-layout>
      <v-layout v-else left row ml-2>
        <v-avatar size="32" class="mt-4">
          <img :src="assets.mercedesLogo2" alt="avatar" />
        </v-avatar>
        <div
          @click="$emit('chat-bubble-clicked')"
          class="pa-4 ml-3 mt-2 mb-2 mr-5 is-not-yours bubble left-bubble"
        >
          <slot name="bubble-content" />
        </div>
        <v-spacer />
      </v-layout>
    </template>
  </v-flex>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    botOnRightProp: {
      type: Boolean
    },
    icon: {
      type: String
    },
    isYours: {
      type: Boolean
    }
  },
  computed: {
    ...mapState(['assets'])
  }
}
</script>

<style scoped>
.bubble {
  cursor: pointer;
}
.left-bubble {
  border-radius: 10px 25px 25px 25px;
}
.is-yours {
  display: table;
}
.right-bubble {
  border-radius: 25px 10px 25px 25px;
}
.is-not-yours {
  display: table;
  color: #222222;
  background-color: #eaeaea;
}
</style>
