import languageService from '@/services/languageService.js'
import {
  createLimit,
  createOffset,
  createOrder,
  createTotalPages,
  createCurrentPage
} from './_utilsStore'

export const namespaced = true

export const state = {
  single: {},
  all: [],
  loading: false,
  supportedLanguages: [
    { name: 'ara-SAU', value: 'ara-SAU' },
    { name: 'ces-CZE', value: 'ces-CZE' },
    { name: 'cmn-TWN', value: 'cmn-TWN' },
    { name: 'dan-DNK', value: 'dan-DNK' },
    { name: 'deu-DEU', value: 'deu-DEU' },
    { name: 'eng-GBR', value: 'eng-GBR' },
    { name: 'eng-USA', value: 'eng-USA' },
    { name: 'fra-FRA', value: 'fra-FRA' },
    { name: 'ita-ITA', value: 'ita-ITA' },
    { name: 'jpn-JPN', value: 'jpn-JPN' },
    { name: 'kor-KOR', value: 'kor-KOR' },
    { name: 'nld-NLD', value: 'nld-NLD' },
    { name: 'nor-NOR', value: 'nor-NOR' },
    { name: 'pol-POL', value: 'pol-POL' },
    { name: 'por-BRA', value: 'por-BRA' },
    { name: 'por-PRT', value: 'por-PRT' },
    { name: 'rus-RUS', value: 'rus-RUS' },
    { name: 'spa-ESP', value: 'spa-ESP' },
    { name: 'swe-SWE', value: 'swe-SWE' },
    { name: 'tha-THA', value: 'tha-THA' },
    { name: 'tur-TUR', value: 'tur-TUR' },
    { name: 'yue-CHS', value: 'yue-CHS' }
  ],
  dataTableOptions: {
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: ['name'],
    sortDesc: ['false'],
    multiSort: false,
    mustSort: true,
    totalItems: 0,
    totalPages: 0
  },
  headers: [
    { text: 'ID', value: 'id', width: '65px' },
    { text: 'Name', value: 'name', width: '150px' },
    { text: 'ISO Code', value: 'iso', width: '150px' },
    { text: 'Cerence Code', value: 'cerenceCode' },
    { text: 'Actions', value: 'actions', sortable: false, width: '90px' }
  ],
  editedItem: [],
  default: {
    name: '',
    iso: '',
    cerenceCode: ''
  }
}

export const mutations = {
  FETCH_STARTS (state) {
    state.all = []
  },
  FETCH_ENDS (state, languages) {
    state.all = languages.data
    let paging = languages.meta.paging
    state.dataTableOptions.totalItems = paging.total
    state.dataTableOptions.totalPages = createTotalPages(
      paging.total, paging.limit
    )
    state.dataTableOptions.currentPage = createCurrentPage(
      paging.offset, paging.limit
    )
    state.loading = false
  },
  SET_EDITED (state, id) {
    let toEdit = state.all.findIndex(obj => obj.id === id)
    let copied = JSON.parse(
      JSON.stringify(state.all[toEdit])
    )
    state.editedItem = [
      copied
    ]
  },
  SET_EDITED_FIELD (state, data) {
    for (let [field, value] of Object.entries(data.data)) {
      state.editedItem[0][field] = value
    }
  },
  SET_DEFAULT (state) {
    state.editedItem = [state.default]
  },
  SET_TABLE_OPTIONS (state, data) {
    for (let setting in data) {
      state.dataTableOptions[setting] = data[setting]
    }
  },
  SET_SEARCH (state, data) {
    state.search = data
  }
}

export const actions = {
  async fetch ({ commit }) {
    try {
      commit('FETCH_STARTS')
      const filter = {
        name: {
          contains: state.search
        }
      }
      const { data, loading } = await languageService.getLanguages({
        limit: await createLimit(state.dataTableOptions),
        offset: await createOffset(state.dataTableOptions),
        filter: filter,
        order: await createOrder(state.dataTableOptions)
      })
      if (loading) {
        commit('FETCH_STARTS')
      }
      if (data) {
        commit('FETCH_ENDS', data.languages)
        return data.languages
      }
    } catch (error) {
      const errorMessage = 'Error while fetching cars: ' + error.message
      console.error(errorMessage)
      console.error(error)
    }
  },
  async delete ({ state }, id) {
    try {
      const response = await languageService.deleteLanguage({
        id: id
      })
      console.log('Language deleted: ' + id)
      return response.data.deleteLanguage
    } catch (error) {
      console.error(error)
    }
  },
  async save ({ state }, id) {
    try {
      let newItem = null
      let item = state.editedItem[0]
      let data = {
        name: item.name,
        iso: item.iso,
        cerenceCode: item.cerenceCode
      }
      if (id) {
        await languageService.updateLanguage({
          id: id,
          data: data
        })
      } else {
        newItem = await languageService.createLanguage({
          data: data
        })
      }
      return newItem
    } catch (error) {
      console.error(error)
    }
  }
}
