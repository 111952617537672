<template>
  <div id="app">
    <v-fade-transition>
      <component :is="'Default'">
        <router-view />
      </component>
    </v-fade-transition>
  </div>
</template>

<script>
import Default from '@/views/Default.vue'
export default {
  components: {
    Default
  }
}
</script>

<style>
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  ::-webkit-scrollbar-track {
    background-color: #272727;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #424242;
    border: 2px solid #272727;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #545454;
  }
</style>
