import fileService from '@/services/fileService.js'

export const namespaced = true

export const state = {
  files: [],
  file: {},
  fileLoading: false,
  downLoading: false,
  blobFileDownloadJson: {}
}

export const getters = {
  files () {
    return state.files
  },
  fileLoading () {
    return state.fileLoading
  },
  getFILEById: state => id => {
    return state.files.filter(
      file => file.name.toLowerCase() === id.toLowerCase()
    )[0]
  },
  getResJson: state => state.blobFileDownloadJson
}

export const mutations = {
  ADD_FILE (state, file) {
    state.files.push(file)
  },
  SET_FILE (state, file) {
    state.file = file
  },
  FETCH_STARTS (state) {
    state.fileLoading = true
  },
  FETCH_ENDS (state, files) {
    state.files = files
    state.fileLoading = false
  },
  DELETE_FILE (state, id) {
    state.files = state.files.filter(
      file => file.id !== id
    )
  },
  UPDATE_FILE (state, updatedFILE) {
    let index = state.files.findIndex(
      file => file.name === updatedFILE.name
    )
    state.files.splice(index, 1, updatedFILE)
  },
  DOWNLOAD_BEGINS (state) {
    state.downLoading = true
  },
  DOWNLOAD_FILE (state, downloadedResponseJson) {
    state.blobFileDownloadJson = downloadedResponseJson
    state.downLoading = false
  }
}

export const actions = {
  async fetchFiles ({ commit }) {
    commit('FETCH_STARTS')
    await fileService.getFiles().then(
      response => {
        let files = response.data.uploads
        commit('FETCH_ENDS', files)
      })
      .catch(error => {
        const errorMessage = 'Error while fetching domains: ' + error.message
        console.log(errorMessage)
        console.log(error.response.data)
      })
  },
  async createFile ({ commit, dispatch }, newFile) {
    return new Promise(async function (resolve, reject) {
      await fileService.createFile(newFile).then(
        response => {
          commit('ADD_FILE', newFile)
          return response.data.singleUpload.filename
        }
      )
    })
  },
  deleteFile ({ commit }, id) {
    return new Promise(function (resolve, reject) {
      fileService.deleteFILE(id).then(
        response => {
          commit('DELETE_FILE', id)
        }
      )
    })
  },
  updateFile ({ commit }, updatedFILE) {
    return new Promise(function (resolve, reject) {
      fileService.updateFILE(updatedFILE).then(
        response => {
          commit('UPDATE_FILE', updatedFILE)
        }
      )
    })
  },
  async downloadFileMVAPResonseJson ({ commit }, { containerId, fileId }) {
    try {
      commit('DOWNLOAD_BEGINS')
      const response = await fileService.downloadMVAPResponseJson(
        containerId, fileId
      )
      commit('DOWNLOAD_FILE', response.data.downLoadMVAPJson)
      return response.data.downLoadMVAPJson
    } catch (error) {
      const errorMessage = 'Error while fetching domains: ' + error.message
      console.log(errorMessage)
      console.log(error.response.data)
    }
  }
}
