import { apolloClient } from '@/vue-apollo'
import {
  ALL_TEST_STEPS,
  CREATE_TEST_STEP,
  DELETE_TEST_STEP,
  UPDATE_TEST_STEP
} from '../graphql/graphql'

export default {
  async createTeststep (newTestStep) {
    return apolloClient.mutate({
      mutation: CREATE_TEST_STEP,
      variables: newTestStep,
      context: { hasUpload: true }
    })
  },
  get_TestSteps () {
    return apolloClient.query({ query: ALL_TEST_STEPS })
  },
  async updateTS (updatedTS) {
    return apolloClient.mutate({
      mutation: UPDATE_TEST_STEP,
      variables: updatedTS,
      context: { hasUpload: true }
    })
  },
  deleteTestStep (id) {
    return apolloClient.mutate({
      mutation: DELETE_TEST_STEP, variables: { 'id': id }
    })
  }
}
