import { url, auth } from '@/config/config'
import axios from 'axios'

export const makeClient = path => {
  let options = {
    baseURL: (url || '/api') + path,
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  // These settings for the development only
  // Check for the authorization and make the adjustments.
  if (auth.clientId && auth.clientSecret) {
    options.headers['authorization'] = `${auth.clientId}:${auth.clientSecret}`
  }
  return axios.create(options)
}
