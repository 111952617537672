import StageService from '@/services/StageService.js'
import { constructUrl } from '@/utils.js'

export const namespaced = true

const defaultStages = {
  ece: {
    dev: null,
    nonprod: null,
    prod: null
  },
  amap: {
    nonprod: null,
    prod: null
  },
  embedded: {
    dev: null
  }
}

export const state = {
  canStageDomain: false,
  stage: null,
  stages: defaultStages,
  selectedStage: {},
  stageExternalTriggerFlag: false
}

export const mutations = {
  SET_STAGE (state, stage) {
    state.stage = stage
  },
  SET_STAGES (state, stages) {
    state.stages = stages
  },
  SET_SELECTED_STAGE (state, stage) {
    state.selectedStage = stage
  },
  SWITCH_CAN_STAGE_DOMAIN (state, newValue) {
    state.canStageDomain = newValue
  },
  SWITCH_STAGE_EXTERNAL_TRIGGER_FLAG (state, newValue) {
    state.stageExternalTriggerFlag = newValue
  }
}

export const actions = {
  fetchStage (
    { commit, dispatch },
    { domainId, market, environment, language }
  ) {
    console.tag('Stage').verbose('domainId:', domainId)
    if (!domainId) {
      commit('SET_STAGE', {})
    } else {
      domainId = domainId.toLowerCase()
      console.tag('Stage').verbose('Fetching stage from service')
      StageService
        .getStage({ domainId, market, environment, language })
        .then(response => {
          console.tag('Stage').verbose('response:', response)
          let stageData =
            response.data.data.length > 0
              ? formatFromDbStage(response.data.data[0])
              : {}
          commit('SET_STAGE', stageData)
        })
        .catch(error => {
          const message =
            'Error while fetching stage for ' +
            domainId +
            ' ' +
            language +
            ' ' +
            market +
            '-' +
            environment +
            ' : ' +
            error.message
          console.tag('Stage').error(message)
          console.tag('Stage').error(error.response.data)
          commit('SET_STAGE', {})
          dispatch('notification/show', { message, error }, { root: true })
        })
    }
  },
  async fetchStages (
    { commit, dispatch },
    { domainId, stagesToCollect, language }
  ) {
    console.tag('Stage').verbose('domainId:', domainId)
    if (!domainId) {
      commit('SET_STAGES', defaultStages)
    } else {
      domainId = domainId.toLowerCase()
      console.tag('Stage').verbose('Fetching stages from service')
      console
        .tag('Stage')
        .verbose('stagesToCollect:', JSON.stringify(stagesToCollect))
      let stages = JSON.parse(JSON.stringify(defaultStages))
      for (let market in stagesToCollect) {
        for (let environment in stagesToCollect[market]) {
          const environmentData = stagesToCollect[market][environment]
          if (environmentData.deployed && environmentData.deployed === true) {
            try {
              // Fetch stage data
              let response = await StageService.getStage({
                domainId,
                market,
                environment,
                language
              })
              let stageData =
                response.data.data.length > 0
                  ? formatFromDbStage(response.data.data[0])
                  : null
              // Add stage data to result
              stages[market][environment] = stageData
            } catch (error) {
              const message =
                'Error while fetching stage for ' +
                domainId +
                ' ' +
                language +
                ' ' +
                market +
                '-' +
                environment +
                ' : ' +
                error.message
              console.tag('Stage').error(message)
              console.tag('Stage').error(error.response.data)
              stages[market][environment] = null
              dispatch('notification/show', { message, error }, { root: true })
            }
          }
        }
      }
      commit('SET_STAGES', stages)
    }
  },
  createStage ({ commit, dispatch }, { newStageData }) {
    return new Promise(function (resolve, reject) {
      console.tag('Stage').verbose('newStageData')
      console.tag('Stage').verbose(newStageData)
      let formattedStage = formatToDbDomain(newStageData)
      console.tag('Stage').verbose('formattedStage:')
      console.tag('Stage').verbose(formattedStage)
      StageService.createStage({
        newStageData: formattedStage
      })
        .then(response => {
          console.tag('Stage').verbose('response:', response)
          let createdStage = formatFromDbStage(response.data.data[0])
          console.tag('Stage').verbose('createdStage:', createdStage)
          commit('SET_STAGE', createdStage)
          const message =
            'Stage for ' +
            createdStage.domainId +
            ' ' +
            createdStage.language +
            ' in ' +
            newStageData.market +
            '-' +
            newStageData.environment +
            ' successfully created'
          console.tag('Stage').verbose(message)
          dispatch('notification/show', { message }, { root: true })
          resolve(message)
        })
        .catch(error => {
          const message =
            'Error creating stage ' + newStageData.name + ': ' + error.message
          console.tag('Stage').error('error:', error)
          console.tag('Stage').error(message)
          commit('SET_STAGE', {})
          dispatch('notification/show', { message, error }, { root: true })
          reject(message)
        })
    })
  },
  deleteStage (
    { commit, dispatch },
    { domainId, market, environment, language }
  ) {
    return new Promise(function (resolve, reject) {
      StageService.deleteStage({ domainId, market, environment, language })
        .then(() => {
          const message = 'Stage successfully deleted'
          console.tag('Stage').verbose(message)
          commit('SET_STAGE', {})
          dispatch('notification/show', { message }, { root: true })
          resolve(message)
        })
        .catch(error => {
          const message = 'Error deleting stage: ' + error.message
          console.tag('Stage').error(message)
          console.tag('Stage').error(error.response.data)
          commit('SET_STAGE', {})
          dispatch('notification/show', { message, error }, { root: true })
          reject(message)
        })
    })
  },
  selectStage ({ commit }, { market, environment }) {
    if (market && environment) {
      commit('SET_SELECTED_STAGE', { market, environment })
    }
  },
  switchCanStageDomain ({ commit }, newValue) {
    commit('SWITCH_CAN_STAGE_DOMAIN', newValue)
  },
  switchStageExternaltriggerFlag ({ commit }, newValue) {
    commit('SWITCH_STAGE_EXTERNAL_TRIGGER_FLAG', newValue)
  }
}

export const getters = {
  checkStageDomainOnSync: (state, getters) => ({
    domainData,
    market,
    environment
  }) => {
    if (
      state.stages &&
      state.stages[market] &&
      state.stages[market][environment]
    ) {
      const reducedDomain = (({
        decisionRules,
        ontology,
        nluInterpretations,
        ntgVersions,
        userInputs,
        apiActions,
        apiActionUrls,
        systemActions,
        dialogUnits,
        isCompositeDialog,
        ttsResponses,
        audioResponses,
        guiResponses
      }) => {
        apiActions = formatApiActionUrl(apiActions, apiActionUrls[environment])
        nluInterpretations = getters.cleanNluInterpretations(domainData)
        userInputs = getters.cleanUserInputs(domainData)
        return {
          decisionRules,
          ontology: {
            intents: ontology.intents,
            apiInputs: ontology.apiInputs
          },
          nluInterpretations,
          ntgVersions,
          userInputs,
          apiActions,
          systemActions,
          dialogUnits,
          isCompositeDialog,
          ttsResponses,
          audioResponses,
          guiResponses
        }
      })(domainData)
      const stageData = state.stages[market][environment]
      const reducedStage = (({
        decisionRules,
        ontology,
        nluInterpretations,
        meta,
        userInputs,
        apiActions,
        systemActions,
        dialogUnits,
        isCompositeDialog,
        ttsResponses,
        audioResponses,
        guiResponses
      }) => ({
        decisionRules,
        ontology: {
          intents: ontology.intents,
          apiInputs: ontology.apiInputs
        },
        nluInterpretations,
        ntgVersions: meta.ntgVersions,
        userInputs,
        apiActions,
        systemActions,
        dialogUnits,
        isCompositeDialog,
        ttsResponses,
        audioResponses,
        guiResponses
      }))(stageData)
      return JSON.stringify(reducedDomain) !== JSON.stringify(reducedStage)
    }
    return false
  },
  cleanNluInterpretations: () => stageData => {
    if (!stageData.nluInterpretations.length) return []
    return stageData.nluInterpretations.map(
      nluInterpretation => nluInterpretation.intent
    )
  },
  cleanUserInputs: () => stageData => {
    if (!stageData.userInputs.length) return []
    return stageData.userInputs.map(userInput => userInput.name)
  },
  stageToTag: () => stageData => {
    if (stageData && stageData.market && stageData.environment) {
      return stageData.environment
    }
    return null
  },
  tagToStage: () => stageTag => {
    if (stageTag) {
      const splitTag = stageTag.split('-')
      if (splitTag.length === 2) {
        return { market: splitTag[0], environment: splitTag[1] }
      }
    }
    return null
  }
}

const formatFromDbStage = stageData => {
  return {
    creator: stageData.creator,
    domainId: stageData.domain_id,
    domainName: stageData.domain_name,
    handlerUrl: stageData.handler_url,
    dateCreated: stageData.date_created,
    dateModified: stageData.date_modified,
    description: stageData.description,
    language: stageData.language,
    market: stageData.market,
    nluInterpretations: stageData.nlu_interpretations,
    userInputs: stageData.user_inputs,
    decisionRules: stageData.decision_rules,
    systemActions: stageData.system_actions,
    apiActions: stageData.api_actions,
    ontology: stageData.ontology,
    volatile: stageData.volatile,
    dialogUnits: stageData.dialog_units || [],
    isCompositeDialog: !!stageData.is_composite_dialog,
    meta: stageData.meta || {},
    ttsResponses: stageData.tts_responses,
    audioResponses: stageData.audio_responses,
    guiResponses: stageData.gui_responses,
    additionalResponses: stageData.additional_responses
  }
}

const formatToDbDomain = stageData => ({
  creator: stageData.creator,
  market: stageData.market,
  environment: stageData.environment,
  language: stageData.language,
  name: stageData.name,
  handler_url: stageData.handlerUrl,
  description: stageData.description,
  nlu_interpretations: stageData.nluInterpretations,
  user_inputs: stageData.userInputs,
  decision_rules: stageData.decisionRules,
  system_actions: stageData.systemActions,
  api_actions: stageData.apiActions ? stageData.apiActions : [],
  ontology: stageData.ontology
    ? stageData.ontology
    : {
      user_inputs: {},
      intents: {},
      api_inputs: {}
    },
  volatile: stageData.volatile || false,
  dialog_units: stageData.dialogUnits || [],
  is_composite_dialog: !!stageData.isCompositeDialog,
  ntg_versions: stageData.ntgVersions,
  domain_creator:
    stageData.creator.displayName || stageData.creator.name || '',
  tts_responses: stageData.ttsResponses,
  audio_responses: stageData.audioResponses,
  gui_responses: stageData.guiResponses,
  additional_responses: stageData.additionalResponses
})

const formatApiActionUrl = (apiActions, base) => {
  return apiActions.map(apiAction => {
    apiAction.path = constructUrl(base, apiAction.path)
    return apiAction
  })
}
