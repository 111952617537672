import {
  GET_ALL_MARKETS,
  GET_A_MARKET,
  CREATE_MARKET,
  UPDATE_MARKET,
  DELETE_MARKET
} from '../graphql/graphql'
import { apolloClient } from '@/vue-apollo'

export default {
  async  getMarkets () {
    return apolloClient.query({
      query: GET_ALL_MARKETS
    })
  },
  async createMarket (newMarket) {
    return apolloClient.mutate({
      mutation: CREATE_MARKET, variables: newMarket
    })
  },
  async updateMarket (updatedMarket) {
    return apolloClient.mutate({
      mutation: UPDATE_MARKET, variables: updatedMarket
    })
  },
  async deleteMarket (id) {
    return apolloClient.mutate({
      mutation: DELETE_MARKET, variables: id
    })
  },
  async getMarket (id) {
    return apolloClient.query({ query: GET_A_MARKET, variables: id })
  }
}
