import Vue from 'vue'
import Vuex from 'vuex'
import * as testCase from '@/store/modules/testcase.js'
import * as testJob from '@/store/modules/testjob.js'
import * as language from '@/store/modules/language.js'
import * as intent from '@/store/modules/intent.js'
import * as domain from '@/store/modules/domain.js'
import * as platform from '@/store/modules/platform.js'
import * as recording from '@/store/modules/recording.js'
import * as testStep from '@/store/modules/teststep.js'
import * as file from '@/store/modules/file.js'
import * as slot from '@/store/modules/slot.js'
import * as prompts from '@/store/modules/prompt.js'
import * as systemUnderTest from '@/store/modules/systemundertest.js'
import * as testResult from '@/store/modules/testresult.js'
import car from '@/store/modules/car'
import * as configuration from '@/store/modules/configuration.js'
import * as testJobHistory from '@/store/modules/testjobhistory'
import * as testJobRunHistory from '@/store/modules/testjobrunhistory'
import * as locale from '@/store/modules/locale'
import * as application from '@/store/modules/application'
import * as ntgVersion from '@/store/modules/ntgVersion'
import * as userSetting from '@/store/modules/userSetting'
import * as notification from '@/store/modules/notification'
import * as stage from '@/store/modules/stage.js'
import * as logger from '@/store/modules/logger.js'
import * as user from '@/store/modules/user.js'
import * as role from '@/store/modules/role.js'
import * as settings from '@/store/modules/settings.js'
import * as recognition from '@/store/modules/recognition.js'
import * as market from '@/store/modules/market.js'
import * as navigation from '@/store/modules/navigation.js'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcSettings } from '@/config/oidc.js'
import createPersistedState from 'vuex-persistedstate'
// this signature allows access to the sdp-assets blob folder until end of
// 2024, see README under Working with Assets for more information.
const SIGNATURE_QS =
  '?sp=rl&st=2020-02-20T16:47:53Z&se=2024-12-31T16:47:00Z&sv=2019-02-02&sr=c&sig=bHJzCKMTbbRQZrEGjW9wuMKuQ%2FAENPnfdZxqcLDSy0A%3D'
const BLOB_STORAGE = 'https://sdpfilestorage.blob.core.windows.net/sdp-assets/'

const getResourceLink = picture => `${BLOB_STORAGE}${picture}${SIGNATURE_QS}`

Vue.use(Vuex)
export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: [
        'user',
        'userSetting'
      ]
    })
  ],
  modules: {
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        namespaced: true,
        dispatchEventsOnWindow: true
      },
      {
        userLoaded: (user) => console.log('OIDC user is loaded:', user),
        userUnloaded: () => console.log('OIDC user is unloaded'),
        accessTokenExpiring: () => console.log('Access token will expire'),
        accessTokenExpired: () => console.log('Access token did expire'),
        silentRenewError: () => console.log('OIDC user is unloaded'),
        userSignedOut: () => console.log('OIDC user is signed out'),
        oidcError: (payload) => console.log('OIDC error', payload),
        automaticSilentRenewError: (payload) => console.log(
          'OIDC automaticSilentRenewError', payload
        )
      }
    ),
    testCase,
    testJob,
    language,
    intent,
    domain,
    platform,
    recording,
    testStep,
    file,
    prompts,
    slot,
    systemUnderTest,
    testResult,
    car,
    configuration,
    testJobHistory,
    testJobRunHistory,
    locale,
    application,
    ntgVersion,
    userSetting,
    notification,
    stage,
    logger,
    user,
    role,
    settings,
    recognition,
    market,
    navigation
  },
  state: {
    isLeftDrawerOpen: false,
    envMode: '',
    isChatOpen: false,
    ttsLanguage: 'en-US',
    assets: {
      mercedesLogo: getResourceLink('logo.png'),
      mercedesLogo2: getResourceLink('logo_icon2.ico')
    }
  },
  mutations: {
    SWITCH_LEFT_DRAWER_STATE (state) {
      state.isLeftDrawerOpen = !state.isLeftDrawerOpen
    }
  },
  actions: {
    switchDrawerState ({ commit }, side) {
      if (side === 'left') commit('SWITCH_LEFT_DRAWER_STATE')
      else if (side === 'right') commit('SWITCH_RIGHT_DRAWER_STATE')
    }
  },
  getters: {
    isProduction: state => {
      return state.envMode === 'production'
    }
  }
})
