import { apolloClient } from '@/vue-apollo'
import {
  ALL_FILES,
  UPLOAD_FILE,
  UPLOAD_EXCEL,
  UPLOAD_JSON,
  DOWNLOAD_MVAP_RES_JSON
} from '../graphql/graphql'

export default {
  async getFiles () {
    let result = await apolloClient.query({ query: ALL_FILES })
    return result
  },
  async createFile (newFile) {
    let result = await apolloClient.mutate({
      mutation: UPLOAD_FILE, variables: newFile
    })
    return result
  },
  async uploadExcel (newFile, langId) {
    const result = await apolloClient.mutate({
      mutation: UPLOAD_EXCEL, variables: newFile
    })
    return result
  },
  async uploadJson (newFile, langId) {
    const result = await apolloClient.mutate({
      mutation: UPLOAD_JSON, variables: newFile
    })
    return result
  },
  async downloadMVAPResponseJson (containerId, fileId) {
    return apolloClient.query({
      query: DOWNLOAD_MVAP_RES_JSON, variables: { containerId, fileId }
    })
  }
}
