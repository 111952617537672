import {
  CREATE_CONFIGURATION,
  UPDATE_CONFIGURATION,
  DELETE_CONFIGURATION,
  GET_ALL_CONFIGURATIONS,
  GET_A_CONFIGURATION
} from '../graphql/graphql'
import { apolloClient } from '@/vue-apollo'

export default {
  createConfiguration (newConfig) {
    return apolloClient.mutate({
      mutation: CREATE_CONFIGURATION, variables: newConfig
    })
  },
  async getConfigurations (variables) {
    return apolloClient.query({
      query: GET_ALL_CONFIGURATIONS, fetchPolicy: 'no-cache', variables: variables
    })
  },
  async getAConfiguration (id) {
    return apolloClient.query({
      query: GET_A_CONFIGURATION, fetchPolicy: 'no-cache', variables: id
    })
  },
  deleteConfiguration (id) {
    return apolloClient.mutate({
      mutation: DELETE_CONFIGURATION, variables: id
    })
  },
  async updateConfiguration (updatedConfig) {
    return apolloClient.mutate({
      mutation: UPDATE_CONFIGURATION, variables: updatedConfig
    })
  }
}
