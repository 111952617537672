import userService from '@/services/userService.js'

export const namespaced = true

export const state = {
  users: [],
  userLoading: true,
  totalUsers: 0,
  currentUser: {}
}

export const getters = {
  users: state => state.users,
  userLoading: state => state.userLoading,
  totalUsers: state => state.totalUsers,
  isAdmin: state => {
    if (state.currentUser) {
      const userAvail = state.users.filter(
        ({ user }) => user.email === this.currentUser.email
      )
      if (userAvail) {
        return true
      }
      return false
    }
    return false
  }
}

export const mutations = {
  ADD_USER (state, user) {
    state.users.push(user)
  },
  SET_USER (state, user) {
    state.user = user
  },
  FETCH_USERS_STARTS (state) {
    state.userLoading = true
    state.users = []
    state.totalUsers = 0
  },
  FETCH_USERS_ENDS (state, users) {
    state.users = users
    state.userLoading = false
    state.totalUsers = users.length
  },
  FETCH_USER_STARTS (state) {
    state.userLoading = true
  },
  FETCH_USER_ENDS (state, user) {
    state.users.push(user)
    state.userLoading = false
  },
  DELETE_USER (state, id) {
    state.users = state.users.filter(
      user => user.id !== id
    )
  },
  UPDATE_USER (state, updatedUser) {
    let index = state.users.findIndex(
      user => user.name === updatedUser.name
    )
    state.users.splice(index, 1, updatedUser)
  },
  GET_CURRENT_USER (state, user) {
    state.currentUser = user
  }
}

export const actions = {
  async fetchUsers ({ commit }) {
    commit('FETCH_USERS_STARTS')
    await userService.getUsers().then(
      response => {
        let { data } = response.data.users
        commit('FETCH_USERS_ENDS', data)
      })
      .catch(error => {
        const errorMessage = 'Error while fetching Users: ' + error.message
        console.log(errorMessage)
        console.log(error.response.data)
      })
  },
  async fetchUser ({ commit }, id) {
    commit('FETCH_USER_STARTS')
    await userService.getUser(id).then(
      response => {
        let user = response.data.user
        commit('FETCH_USER_ENDS', user)
      }).catch(error => {
      const errorMessage = 'Error while fetching User: ' + error.message
      console.log(errorMessage)
      console.log(error.message.data)
    })
  },
  createUser ({ commit }, newUser) {
    return new Promise(function () {
      userService.createUser(newUser).then(
        response => {
          commit('ADD_USER', response.data.createUser)
        }
      )
    })
  },
  updateUser ({ commit }, updatedUser) {
    return new Promise(function () {
      userService.updateUser(updatedUser).then(
        response => {
          commit('UPDATE_USER', response.data.updateUser)
        }
      )
    })
  },
  deleteUser ({ commit }, id) {
    return new Promise(function () {
      userService.deleteUser(id).then(
        response => {
          commit('DELETE_USER', id)
        }
      )
    })
  },
  getuser (context) {
    let currentUser = context.rootState.oidcStore.user
    context.commit('GET_CURRENT_USER', currentUser)
  }
}
