/* eslint-disable quotes */
<template>
  <v-card class="ma-3">
    <v-data-table
      :loading="loading"
      loading-text="Test Cases Loading..."
      v-model="selected"
      :headers="headers"
      :items="testCases"
      item-key="id"
      show-select
      hide-default-footer
      :options.sync="dataTableOptions"
      :server-items-length="dataTableOptions.totalItems">
      <template v-slot:top>
        <v-toolbar>
          <v-toolbar-title>
            <h1 class="text-h6">
              <v-icon class="mr-2">
                mdi-clipboard-check-multiple-outline
              </v-icon>
              Test Cases
            </h1>
          </v-toolbar-title>
          <v-spacer/>
          <DefaultConfigDialog :configuration="defaultConfigInfo"/>
          <TestCaseDialog/>
          <TestCaseUpload/>
          <TestCaseDownload/>
        </v-toolbar>
        <v-toolbar>
          <v-row justify="space-between" >
            <v-col :align-self="'center'" cols="2"> <!-- Creator Filter -->
              <v-select
                :items="['ALL', 'ME']"
                v-model="selectedCreator"
                label="Owner"
                outlined
                hide-details
                dense />
            </v-col>
            <v-col :align-self="'center'" cols="2"> <!-- Domains Filter -->
              <v-select
                :items="domains"
                item-text="name"
                v-model="selectedDomain"
                label="Domains"
                outlined
                clearable
                hide-details
                dense />
            </v-col>
            <v-col :align-self="'center'" cols="2"> <!-- Intent Filter -->
              <v-autocomplete
                :items="intents"
                item-text="name"
                v-model="selectedIntent"
                label="Intents"
                outlined
                clearable
                hide-details
                dense />
            </v-col>
            <v-col :align-self="'end'"> <!-- Search -->
              <v-text-field
                v-model="search"
                label="Search Name"
                prepend-inner-icon="search"
                outlined
                clearable
                hide-details
                dense />
            </v-col>
          </v-row>
        </v-toolbar>
        <v-toolbar>
          <v-tooltip
            bottom
            open-delay="1000">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-show="selected.length > 0"
                :loading="isBulkDeleting"
                @click="bulkDelete()"
                v-bind="attrs"
                v-on="on"
                class="ml-0 mr-4"
                disabled >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete all selected Test Cases</span><br/>
          </v-tooltip>
          <v-combobox
            class="mr-2"
            label="Edit Test Schedule"
            :loading="testJobLoading"
            @change="logTestJob"
            item-text="name"
            :items="testJobs"
            v-model="selectedTestJobs"
            return-object
            color="primary"
            outlined
            dense
            hide-details
            clearable
            style="max-width:250px"/>
          <TestJobDialog
            @testcase-deselect-rows="deselectRows"
            v-if="selected.length > 0"
            :testCasesIds="selected"/>
          <v-btn
            class="mr-2"
            outlined
            color="primary"
            style="height:40px"
            v-show="getChangeState"
            :loading="saveChangesLoading"
            @click="saveChangesTestJob">
            Save Changes
          </v-btn>
          <v-spacer />
        </v-toolbar>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <TestCaseDialog :testCaseId="item.id"/>
        <v-tooltip
          bottom
          open-delay="1000">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              small
              v-on="on">
              <v-icon
                v-if="!busy.includes(item)"
                :disabled="item.testSteps.length === 0 || !item.testSteps[0].phrase"
                small
                @click.stop="playTestCaseInDefaultConfig(item)">
                mdi-run
              </v-icon>
              <v-progress-circular
                v-else
                :size="15"
                :width="2"
                indeterminate/>
            </v-btn>
          </template>
          <span>Run Test Job</span>
        </v-tooltip>
        <DeleteDialog store="testCase" :item="item" />
      </template>
      <template v-slot:[`item.latestTestResult`]="{ item }">
        <span v-if="!item.latestTestResult">
          N/A
        </span>
        <span v-else>
          {{ item.latestTestResult.createdAt | convertDateTime }}
        </span>
      </template>
      <template v-slot:[`item.result`]="{ item }">
        <ResultChip
          v-if="item.latestTestResult"
          :result="item.latestTestResult.passed"/>
        <ResultChip
          v-else
          :result="null" />
      </template>
    </v-data-table>
    <Pagination type="testCase" />
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TestJobDialog from '@/components/dialogs/TestJob'
import DefaultConfigDialog from '@/components/dialogs/DefaultConfig'
import TestCaseDialog from '@/components/dialogs/TestCase'
import TestCaseDownload from '@/components/fragments/TestCaseDownload'
import TestCaseUpload from '@/components/dialogs/TestCaseUpload'
import ResultChip from '@/components/fragments/ResultChip'
import Pagination from '@/components/fragments/Pagination'
import DeleteDialog from '@/components/dialogs/Delete'

export default {
  name: 'TestCaseDataTable',
  components: {
    TestJobDialog,
    DefaultConfigDialog,
    TestCaseDialog,
    TestCaseDownload,
    TestCaseUpload,
    ResultChip,
    Pagination,
    DeleteDialog
  },
  data () {
    return {
      selectedTestJobs: null,
      selectedTestJobTestCases: [],
      filename: 'TestCaseList.vue',
      deleteLoader: -1,
      isBulkDeleting: false,
      selected: [],
      saveChangesLoading: false
    }
  },
  mounted () {
    this.fetchUserSetting()
    this.fetchConfigurations()
    this.fetchDomains()
    this.fetchPrompts()
    this.fetchIntents()
    this.fetchLanguages()
    this.fetchTestJobs()
    this.fetch()
  },
  computed: {
    ...mapState('testJob', {
      testJobLoading: 'loading',
      testJobs: 'all'
    }),
    ...mapState('testCase', ['busy', 'loading', 'headers']),
    ...mapState('userSetting', ['userSetting']),
    ...mapState('oidcStore', ['user']),
    ...mapState('intent', { intents: 'all' }),
    ...mapState('domain', { domains: 'all' }),
    search: {
      get () {
        return this.$store.state.testCase.search
      },
      set (value) {
        this.$store.commit('testCase/SET_TABLE_OPTIONS', { page: 1 })
        this.$store.commit('testCase/SET_SEARCH', value)
        this.fetch()
      }
    },
    selectedDomain: {
      get () {
        return this.$store.state.testCase.filter.domain
      },
      set (value) {
        this.$store.commit('testCase/SET_TABLE_OPTIONS', { page: 1 })
        this.$store.commit('testCase/SET_FILTER', { domain: value })
        this.fetch()
      }
    },
    selectedCreator: {
      get () {
        return this.$store.state.testCase.filter.creator
      },
      set (value) {
        this.$store.commit('testCase/SET_TABLE_OPTIONS', { page: 1 })
        this.$store.commit('testCase/SET_FILTER', { creator: value })
        this.fetch()
      }
    },
    selectedIntent: {
      get () {
        return this.$store.state.testCase.filter.intent
      },
      set (value) {
        this.$store.commit('testCase/SET_TABLE_OPTIONS', { page: 1 })
        this.$store.commit('testCase/SET_FILTER', { intent: value })
        this.fetch()
      }
    },
    testCases: {
      get () {
        return this.$store.state.testCase.all
      },
      set () {
        console.log('set TestCase')
      }
    },
    dataTableOptions: {
      get () {
        return this.$store.state.testCase.dataTableOptions
      },
      set (value) {
        this.$store.commit('testCase/SET_TABLE_OPTIONS', value)
        this.fetch()
      }
    },
    getChangeState () {
      if (this.selectedTestJobs) {
        var idsA = this.selected
          .map((x) => {
            return x.id
          })
          .sort()
        var idsB = this.selectedTestJobTestCases
          .map((x) => {
            return x.id
          })
          .sort()
        return idsA.join(',') !== idsB.join(',')
      } else {
        return false
      }
    },
    defaultConfigInfo () {
      const defaultConfig = this.userSetting.defaultConfiguration
      if (!defaultConfig) {
        return ''
      }
      const sut = defaultConfig.systemUnderTest
      const car = defaultConfig.car
      const sutName = sut ? sut.name : 'SUT NOT SPECIFIED'
      const carName = car ? car.name : 'CAR NOT SPECIFIED'
      return `${sutName} | ${carName} | ${defaultConfig.recognition}`
    }
  },
  methods: {
    ...mapActions('testCase', [
      'fetch',
      'delete',
      'deleteBulk',
      'play'
    ]),
    ...mapActions('testJob', {
      fetchTestJob: 'fetch',
      fetchTestJobs: 'fetch',
      addTestCaseToTestJob: 'addTo',
      removeTestCaseFromTestJob: 'removeFrom'
    }),
    ...mapActions('configuration', { fetchConfigurations: 'fetch' }),
    ...mapActions('notification', ['add']),
    ...mapActions('domain', { fetchDomains: 'fetch' }),
    ...mapActions('language', { fetchLanguages: 'fetch' }),
    ...mapActions('prompts', { fetchPrompts: 'fetch' }),
    ...mapActions('intent', { fetchIntents: 'fetch' }),
    ...mapActions('userSetting', ['fetchUserSetting']),
    logTestJob (event) {
      if (event) {
        this.selected = []
        this.selectedTestJobTestCases = []
        let selectedTestCases = this.selectedTestJobs.testCases
        if (selectedTestCases) {
          selectedTestCases.forEach((item) => {
            let selectedTestCase = this.testCases.filter(
              (testCase) => testCase.id === item.id
            )[0]
            if (selectedTestCase) {
              this.selectedTestJobTestCases.push(selectedTestCase)
              this.selected.push(selectedTestCase)
            }
          })
        }
      } else {
        this.selected = []
        this.selectedTestJobTestCases = []
      }
    },
    deleteTestCaseAt (item) {
      const index = this.Testcases.indexOf(item)
      confirm('Are you sure you want to delete this item?') &&
        this.deleteTestCase(index, item)
    },
    async saveChangesTestJob () {
      var idsA = this.selected
        .map((x) => {
          return x.id
        })
        .sort()
      var idsB = this.selectedTestJobTestCases
        .map((x) => {
          return x.id
        })
        .sort()
      var toBeSubstracted = idsB.filter((el) => !idsA.includes(el))
      var toBeAdded = idsA.filter((el) => !idsB.includes(el))
      this.saveChangesLoading = true
      await Promise.all(toBeAdded.map(item => this.addTestCaseToTestJob({
        testCaseId: item,
        testJobId: this.selectedTestJobs.id
      }).then((response) => {
        item = response
      })))
      await Promise.all(
        toBeSubstracted.map(item => this.removeTestCaseFromTestJob({
          testCaseId: item,
          testJobId: this.selectedTestJobs.id
        }).then((response) => {
          item = response
        }))
      )
      this.fetch().then((response) => {
        this.selectedTestJobTestCases = this.selected
        this.saveChangesLoading = false
      }).catch((error) => {
        this.saveChangesLoading = false
        console.log(error)
      })
      this.fetchTestJob({ id: this.selectedTestJobs.id })
    },
    deselectRows () {
      this.selected = []
    },
    async bulkDelete () {
      if (confirm('Are you sure you want to delete these items?')) {
        let ids = this.selected.map((testCase) => testCase.id)
        const response = await this.deleteBulkTC({ ids: ids })
        if (response.message) {
          this.add({ message: response.message, type: 'error' })
        } else {
          for (let i = 0; i <= response.length - 1; i++) {
            this.testCases = this.testCases.filter(
              testCase => testCase.id !== response[i].id
            )
          }
          this.selected = []
          this.add({ message: 'Test Cases Deleted', type: 'success' })
        }
      }
    },
    async playTestCaseInDefaultConfig (item) {
      let response = await this.playTestCase({
        testCase: item,
        configurationId: this.userSetting.defaultConfiguration.id
      })
      if (response.message) {
        this.add({ message: response.message, type: 'error' })
      } else {
        if (response.data.playTestCase.passed) {
          this.add({ message: 'Test executed successfully', type: 'success' })
        } else {
          this.add({ message: 'Test Failed', type: 'error' })
        }
      }
    }
  }
}
</script>

<style scoped>
textarea {
    width: 100%;
    overflow: auto;
    font-size: 20px;
    display: inline-flex;
    font-family: 'Open sans', sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}
</style>
