<template>
  <v-text-field
    v-model="item"
    :label="label"
    required
    outlined
    dense
    hide-details
    :rules="rules"
    color="primary">
  </v-text-field>
</template>

<script>
export default {
  props: {
    type: String,
    store: String,
    index: Number,
    required: Boolean
  },
  data () {
    return {
      rules: [v => !!v || 'Field is required']
    }
  },
  computed: {
    item: {
      get () {
        return this.$store.state[this.store].editedItem[this.index][this.type]
      },
      set (value) {
        let obj = { data: {} }
        obj['data'][this.type] = value
        if (this.index !== null || this.index !== undefined) {
          obj['position'] = this.index
        }
        this.$store.commit(this.store + '/SET_EDITED_FIELD', obj)
      }
    },
    label () {
      let type = this.type.charAt(0).toUpperCase() + this.type.slice(1)
      let required = this.required ? '*' : ''
      return type + required
    }
  }
}
</script>
