import { apolloClient } from '@/vue-apollo'
import {
  ROLE,
  ROLES,
  CREATE_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE
} from '../graphql/graphql'

export default {
  createRole (newRole) {
    return apolloClient.mutate({ mutation: CREATE_ROLE, variables: newRole })
  },
  updateRole (updatedRole) {
    return apolloClient.mutate({
      mutation: UPDATE_ROLE, variables: updatedRole
    })
  },
  deleteRole (id) {
    return apolloClient.mutate({ mutation: DELETE_ROLE, variables: id })
  },
  getRole (id) {
    return apolloClient.query({
      query: ROLE, fetchPolicy: 'no-cache', variables: id
    })
  },
  getRoles (args) {
    return apolloClient.query({
      query: ROLES, fetchPolicy: 'no-cache', variables: args
    })
  }
}
