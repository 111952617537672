<template>
  <div>
    <v-btn
      icon
      :color="buttonColor"
      :class="{ 'animated infinite pulse': true }"
      @click="readText">
      <v-icon :color="iconColor">{{ "record_voice_over" }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import Speech from 'speak-tts'

export default {
  props: ['textToRead'],
  data () {
    return {
      iconColor: 'white',
      buttonColor: this.$vuetify.theme.primary
    }
  },
  watch: {
    textToRead: function () {
      this.readText()
    }
  },
  methods: {
    readText () {
      // eslint-disable-next-line no-undef
      speech
        .speak({
          text: this.textToRead
        })
        .then(() => {})
        .catch(e => {
          console.tag('TextToSpeech').error('TTS error:', e)
        })
    }
  },
  mounted () {
    try {
      const speech = new Speech()
      if (speech.hasBrowserSupport()) {
        console.tag('TextToSpeech').info('Speech synthesis supported.')
      }
      speech
        .init({
          volume: 1,
          lang: 'en-US',
          rate: 1,
          pitch: 1,
          voice: 'Samantha',
          splitSentences: true,
          listeners: {
            onvoiceschanged: voices => {}
          }
        })
        .then(data => {
          console
            .tag('TextToSpeech')
            .info('Speech is ready, voices are available.', data)
        })
        .catch(e => {
          console
            .tag('TextToSpeech')
            .error('An error occured while initializing:', e)
        })
    } catch (err) {
      console.tag('TextToSpeech').error(err)
    }
  }
}
</script>

<style></style>
