import {
  GET_ALL_CARS,
  GET_A_CAR,
  CREATE_CAR,
  UPDATE_CAR,
  DELETE_CAR
} from '../graphql/graphql'
import { apolloClient } from '@/vue-apollo'

export default {
  async  getCars (variables) {
    return apolloClient.query({ query: GET_ALL_CARS, variables: variables })
  },
  async createCar (newCar) {
    return apolloClient.mutate({ mutation: CREATE_CAR, variables: newCar })
  },
  async updateCar (updateCar) {
    return apolloClient.mutate({ mutation: UPDATE_CAR, variables: updateCar })
  },
  async deleteCar (id) {
    return apolloClient.mutate({ mutation: DELETE_CAR, variables: id })
  },
  async getCar (id) {
    return apolloClient.query({ query: GET_A_CAR, variables: id })
  }
}
