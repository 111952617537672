import recService from '@/services/recService.js'

export const namespaced = true

export const state = {
  recording: {},
  recordings: [],
  recLoading: true
}

export const getters = {
  recordings () {
    return state.recordings
  },
  recLoading () {
    return state.recLoading
  }
}

export const mutations = {
  ADD_REC (state, recording) {
    state.recordings.push(recording)
  },
  FETCH_STARTS (state) {
    state.recLoading = true
  },
  FETCH_ENDS (state, recordings) {
    state.recordings = recordings
    state.recLoading = false
  },
  DELETE_REC (state, recToDelete) {
    state.recordings = state.recordings.filter(
      recording => recording.name !== recToDelete.name
    )
  },
  UPDATE_REC (state, updatedREC) {
    let index = state.recordings.findIndex(
      recording => recording.name === updatedREC.name
    )
    state.recordings.splice(index, 1, updatedREC)
  }

}

export const actions = {
  async fetchRecordings ({ commit }) {
    commit('FETCH_STARTS')
    await recService.getRECs().then(
      response => {
        let { data } = response.data.recordings
        commit('FETCH_ENDS', data)
      })
      .catch(error => {
        const errorMessage = 'Error while fetching Recordings: ' + error.message
        console.log(errorMessage)
        console.log(error.response.data)
      })
  },
  async createRecording ({ commit }, newREC) {
    await recService.createRecording(newREC).then(
      response => {
        commit('ADD_REC', response.data.createRecording)
      }
    )
  }
}
