<template>
  <v-flex>
    <ChatBubbleContainer
      :botOnRightProp="botOnRightProp"
      :icon="icon"
      :isYours="isYours"
      @chat-bubble-clicked="$emit('chat-bubble-clicked', msg)"
    >
      <template #bubble-content>
        <v-layout column>
          <v-img v-if="msg.data.image" :src="msg.data.image" />
          <v-btn
            v-if="getAudioData(msg)"
            @click.stop.prevent="togglePlay()"
            icon
          >
            <v-icon :color="transportButtonColor" large>{{
              transportButtonIcon
            }}</v-icon>
          </v-btn>
          <span v-if="msg.data.text">{{ msg.data.text }}</span>
          <span v-if="preTtsText">[Pre Tts]&nbsp;{{ preTtsText }}</span>
          <span v-if="postTtsText">[Post Tts]&nbsp;{{ postTtsText }}</span>
        </v-layout>
      </template>
    </ChatBubbleContainer>
  </v-flex>
</template>

<script>
import ChatBubbleContainer from '@/components/chat/ChatBubbleContainer.vue'
export default {
  components: {
    ChatBubbleContainer
  },
  props: {
    msg: {
      type: Object
    },
    botOnRightProp: {}
  },
  computed: {
    author () {
      return this.isYours ? 'You' : '#heymercedes'
    },
    icon () {
      return this.isYours ? 'person' : 'directions_car'
    },
    isYours () {
      return this.msg.author === 'me'
    },
    chatBubbleStyle () {
      return this.isYours ? 'is-yours' : 'is-not-yours'
    },
    transportButtonColor () {
      return this.isAudioPlaying ? 'error' : 'success'
    },
    transportButtonIcon () {
      return this.isAudioPlaying ? 'stop' : 'play_circle_filled'
    },
    audioData () {
      return this.getAudioData(this.msg)
    },
    preTtsText () {
      return this.getPreTtsText(this.msg)
    },
    postTtsText () {
      return this.getPostTtsText(this.msg)
    }
  },
  data () {
    return {
      isAudioPlaying: false,
      audio: null,
      audioQueue: []
    }
  },
  methods: {
    togglePlay () {
      if (this.isAudioPlaying) {
        this.audio.pause()
        this.audio.load()
      } else {
        this.audio.play()
      }

      this.isAudioPlaying = !this.isAudioPlaying
    },
    loadAudio () {
      // stop previous audio playback
      if (this.audio) {
        this.audio.pause()
      }

      let audioSource

      if (this.audioQueue.length > 0) {
        audioSource = this.audioQueue[0]
        this.audioQueue.splice(0, 1)
      }

      if (audioSource) {
        audioSource = audioSource.toString()
        const prefix = audioSource.includes('.mp3')
          ? ''
          : 'data:audio/mp3;base64,'

        this.audio = new Audio(prefix + audioSource)
        this.audio.onplay = () => {
          this.isAudioPlaying = true
        }
        this.audio.onpause = () => {
          this.isAudioPlaying = false
        }
        this.audio.onended = () => {
          this.isAudioPlaying = false
          if (this.isMostRecent) {
            this.loadAudio()
          }
        }
        this.togglePlay()
      }
    },
    getAudioData (msg) {
      if (!msg || !msg.data || !msg.data.response) {
        return ''
      }

      const response = msg.data.response['service-provider-recognition-data']
      if (!response) {
        return ''
      }

      const results = response['NLU-with-Content']['appserver_results']
      const audioData = results.payload.actions[0].audio_data

      if (
        audioData &&
        !audioData.toString().includes('($responseData.audio)')
      ) {
        return audioData
      }

      return ''
    },
    getPreTtsText (msg) {
      if (!msg || !msg.data || !msg.data.response) {
        return ''
      }

      const response = msg.data.response['service-provider-recognition-data']
      if (!response) {
        return ''
      }

      const results = response['NLU-with-Content']['appserver_results']
      return results.payload.actions[0].pre_tts_text
    },
    getPostTtsText (msg) {
      if (!msg || !msg.data || !msg.data.response) {
        return ''
      }

      const response = msg.data.response['service-provider-recognition-data']
      if (!response) {
        return ''
      }

      const results = response['NLU-with-Content']['appserver_results']
      return results.payload.actions[0].post_tts_text
    }
  },
  mounted () {
    if (this.audioData) {
      if (Array.isArray(this.audioData)) {
        this.audioData.forEach(d => {
          if (d.toString().includes('.mp3')) {
            // workaround until https://issue.swf.daimler.com/browse/MBIENT-4480 is finished
            this.audioQueue.push(...d.toString().split(','))
          } else {
            this.audioQueue.push(d)
          }
        })
      } else {
        this.audioQueue.push(this.audioData)
      }
    }

    this.loadAudio()
  }
}
</script>

<style lang="scss" scoped></style>
