import { apolloClient } from '@/vue-apollo'
import { ALL_RECORDINGS, CREATE_RECORDING } from '../graphql/graphql'

export default {
  async createRecording (newREC) {
    let result = await apolloClient.mutate({
      mutation: CREATE_RECORDING, variables: newREC
    })
    return result
  },
  getRECs () {
    return apolloClient.query({ query: ALL_RECORDINGS })
  }
}
