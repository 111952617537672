import { createApolloProvider } from '@vue/apollo-option'
import { restartWebsockets, createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import { setContext } from 'apollo-link-context'
import { createUploadLink } from 'apollo-upload-client'
import { ApolloLink, InMemoryCache, from } from '@apollo/client/core'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'

const AUTH_TOKEN = 'daimler-token'
const httpEndpoint = 'https://test-automation-platform-backend.azurewebsites.net/graphql'
const wsEndpoint = 'wss://test-automation-platform-backend.azurewebsites.net/graphql'

const httpOptions = {
  uri: process.env.VUE_APP_GRAPHQL_HTTP || httpEndpoint
}
const httpLink = ApolloLink.split(
  operation => operation.getContext().hasUpload,
  createUploadLink(httpOptions),
  new BatchHttpLink(httpOptions)
)

// Create a new Middleware Link using setContext
const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN)
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    console.log(graphQLErrors)
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
      // Apollo Server sets code to UNAUTHENTICATED
      // when an AuthenticationError is thrown in a resolver
      case 'UNAUTHENTICATED':
        // TBD once the refresh token issue resoleved, use the
        // new token in the header authorization
        // Modify the operation context with a new token
        // const oldHeaders = operation.getContext().headers
        // operation.setContext({
        //   headers: {
        //     ...oldHeaders,
        //     authorization: console.log('getNewToken')
        //   }
        // })
        // Since the refresh token is not available from Token master,
        // Enforcing the user to logout page.
        window.location.href = '/'
        // Retry the request, returning the new observable
        return forward(operation)
      }
    }
  }
  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint: process.env.VUE_APP_GRAPHQL_HTTP || httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || wsEndpoint,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,
  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink
  link: from([ errorLink, authLink, httpLink ]),
  // Override default cache
  cache: new InMemoryCache(),
  // Override the way the Authorization header is set
  // getAuth: (tokenName) => { }
  // Additional ApolloClient options
  // apollo: { ... }
  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
  defaultHttpLink: false
}

// Create apollo client
export const { apolloClient, wsClient } = createApolloClient({
  ...defaultOptions
  // ...options
})
apolloClient.wsClient = wsClient

// Call this in the Vue app file
export function createProvider (options = {}) {
  // Create vue apollo provider
  const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network'
      }
    },
    errorHandler (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    }
  })
  return apolloProvider
}

// Manually call this when user log in
export async function onLogin (apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
export async function getClient () {
  return this.apolloProvider.defaultClient
}
