<template>
  <v-card class="ma-3">
    <v-data-table
      :loading="loading || loadingTestJobHistory"
      loading-text="Test Job Run History Loading..."
      :headers="headers"
      :items="all"
      hide-default-footer
      :options.sync="dataTableOptions"
      :server-items-length="dataTableOptions.totalItems">
      <template v-slot:[`item.responseTime`]="{ item }">
        <span>{{ responseTime(item.latency) }}</span>
      </template>
      <template v-slot:[`item.passed`]="{ item }">
        <ResultChip :result="item.passed" />
      </template>
      <template v-slot:top>
        <v-toolbar>
          <v-toolbar-title>
              <h1 class="text-h6" v-if="single.testJob">
              {{ single.testJob.name }} ({{ single.status }})
            </h1>
          </v-toolbar-title>
          <v-spacer />
          <v-tooltip
            bottom
            open-delay="1000"> <!-- Dowload Button -->
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                class="mt-3 mr-2"
                :loading="downloadLoading"
                @click="downloadTestResults"
                v-bind="attrs"
                v-on="on">
                <v-icon>
                  mdi-download
                </v-icon>
              </v-btn>
            </template>
            <span>Download excel file of the Test Job</span>
          </v-tooltip>
          <v-tooltip
            bottom
            open-delay="1000"> <!-- Close Button -->
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                class="mt-3 mr-2"
                @click="close"
                v-bind="attrs"
                v-on="on">
                <v-icon>mdi-close-box</v-icon>
              </v-btn>
            </template>
            <span>Close Test Run</span>
          </v-tooltip>
        </v-toolbar>
        <v-toolbar>
          <v-row>
            <v-col cols="12">
              <v-simple-table
                style="background-color:transparent">
                <template v-slot:default>
                  <tr>
                    <th class="text-caption text-left" width="110px">
                        testCaseCount
                    </th>
                    <th class="text-caption text-left" width="70px">
                        Passed
                    </th>
                    <th class="text-caption text-left" width="140px">
                        executionStart
                    </th>
                    <th class="text-caption text-left" width="140px">
                        executionEnd
                    </th>
                    <th class="text-caption text-left" width="140px">
                        minResponseTime
                    </th>
                    <th class="text-caption text-left" width="140px">
                        avgResponseTime
                    </th>
                    <th class="text-caption text-left" width="140px">
                        maxResponseTime
                    </th>
                  </tr>
                  <tr>
                    <td class="text-left">
                        {{ single.testCaseCount }}
                    </td>
                    <td class="text-left">
                        {{ single.passedCount }}
                    </td>
                    <td class="text-left">
                        {{ single.executionStart | convertDateTime }}
                    </td>
                    <td class="text-left">
                        {{ single.executionEnd | convertDateTime }}
                    </td>
                    <td class="text-left">
                        {{ responseTime(single.minResponseTime) }}
                    </td>
                    <td class="text-left">
                        {{ responseTime(single.avgResponseTime) }}
                    </td>
                    <td class="text-left">
                        {{ responseTime(single.maxResponseTime) }}
                    </td>
                  </tr>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-toolbar>
          <v-row>
            <v-col :align-self="'start'" cols="2"> <!-- domains filter -->
              <v-select
                :items="domains"
                v-model="selectedDomain"
                item-text="name"
                clearable
                label="Expected Domain"
                outlined
                hide-details
                dense />
            </v-col>
            <v-col :align-self="'start'" cols="2"> <!-- intent filter -->
              <v-select
                :items="intents"
                v-model="selectedIntent"
                item-text="name"
                clearable
                label="Expected Intent"
                outlined
                hide-details
                dense />
            </v-col>
            <v-col :align-self="'start'"> <!-- search -->
              <v-text-field
                v-model="search"
                placeholder="Search Expected Phrase"
                clearable
                outlined
                hide-details
                dense />
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:footer>
        <Pagination type="testJobRunHistory" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import reportDowloader from '@/services/downloadReport.js'
import ResultChip from '@/components/fragments/ResultChip'
import Pagination from '@/components/fragments/Pagination'

export default {
  name: 'TestJobRunHistoryDataTable',
  components: {
    ResultChip,
    Pagination
  },
  data () {
    return {
      downloadLoading: false,
      id: 0
    }
  },
  computed: {
    ...mapState('testJobRunHistory', ['loading', 'headers', 'all']),
    ...mapState('testJobHistory', {
      loadingTestJobHistory: 'loading',
      single: 'single'
    }),
    ...mapState('domain', { domains: 'all' }),
    ...mapState('intent', { intents: 'all' }),
    search: {
      get () {
        return this.$store.state.testJobRunHistory.search
      },
      set (value) {
        this.$store.commit('testJobRunHistory/SET_SEARCH', value)
        this.fetch()
      }
    },
    dataTableOptions: {
      get () {
        return this.$store.state.testJobRunHistory.dataTableOptions
      },
      set (value) {
        this.$store.commit('testJobRunHistory/SET_TABLE_OPTIONS', value)
        this.fetch()
      }
    },
    selectedDomain: {
      get () {
        return this.$store.state.testCase.filter.domain
      },
      set (value) {
        this.$store.commit('testJobRunHistory/SET_TABLE_OPTIONS', { page: 1 })
        this.$store.commit('testJobRunHistory/SET_FILTER', { domain: value })
        this.fetch()
      }
    },
    selectedIntent: {
      get () {
        return this.$store.state.testJobRunHistory.filter.intent
      },
      set (value) {
        this.$store.commit('testJobRunHistory/SET_TABLE_OPTIONS', { page: 1 })
        this.$store.commit('testJobRunHistory/SET_FILTER', { intent: value })
        this.fetch()
      }
    }
  },
  methods: {
    ...mapActions('testJobHistory', { fetchTestJobHistory: 'fetchSingle' }),
    ...mapActions('testJobRunHistory', ['fetch']),
    ...mapActions('testResult', { fetchTestResults: 'fetch' }),
    responseTime (time) {
      if (time) {
        return (parseFloat(time) / 1000).toFixed(2)
      } else {
        return ''
      }
    },
    async downloadTestResults () {
      this.downloadLoading = true
      this.$store.commit('testResult/SET_TESTJOBHISTORYID', this.id)
      await this.fetchTestResults()
      await reportDowloader.createXSLXTestResults(
        this.id, this.$store.state.testResult.all
      )
      this.downloadLoading = false
    },
    close () {
      this.$router.push({ name: 'dashboard' })
    }
  },
  async mounted () {
    this.id = parseInt(this.$route.params.id)
    await this.fetchTestJobHistory({
      id: this.id
    })
    await this.fetch()
    await this.$store.dispatch('domain/fetch')
    await this.$store.dispatch('intent/fetch')
  }
}
</script>
