<template>
  <div
    id="scroll-target"
    class="overflow-y-auto white pa-4"
    style="width: 100%">
    <div id="scrolled-content">
      <v-layout
        v-resize="scrollToBottom"
        v-for="(msg, msgIndex) in messages"
        :key="msgIndex"
        column
        my-6
        mx-0>
        <BusyChatBubble
          v-if="msg.type === 'busy'"
          :msg="msg"
          :botOnRightProp="botOnRight" />
        <SuperChatBubble
          v-else
          :msg="msg"
          :botOnRightProp="botOnRight"
          @chat-bubble-clicked="onChatBubbleClicked($event)" />
      </v-layout>
      <v-dialog v-model="showFullResponse" max-width="600px">
        <div>
          <v-card text>
            <v-container grid-list-xl container--fluid>
              <v-layout wrap>
                <v-flex xs12>
                  <h2>Raw JSON response</h2>
                  <VueJsonPretty
                    :path="'res'"
                    :data="fullJsonResponse"
                    showLength
                    :showLine="false">
                  </VueJsonPretty>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </div>
      </v-dialog>
      <v-dialog v-model="showFullRequest" max-width="600px">
        <div>
          <v-card text>
            <v-container grid-list-xl container--fluid>
              <v-layout wrap>
                <v-flex xs12>
                  <h2>Raw JSON request</h2>
                  <VueJsonPretty
                    :path="'res'"
                    :data="fullJsonRequest"
                    showLength
                    :showLine="false"
                  ></VueJsonPretty>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import BusyChatBubble from '@/components/chat/BusyChatBubble.vue'
import SuperChatBubble from '@/components/chat/SuperChatBubble.vue'
import VueJsonPretty from 'vue-json-pretty'

export default {
  components: {
    BusyChatBubble,
    SuperChatBubble,
    VueJsonPretty
  },
  props: {
    autoScrollEnabled: {
      type: Boolean
    },
    botOnRight: {
      type: Boolean
    },
    messages: {
      type: Array
    }
  },
  computed: {
    isChatOpen () {
      return this.$store.state.isChatOpen
    }
  },
  data () {
    return {
      showFullResponse: false,
      showFullRequest: false,
      fullJsonResponse: '',
      fullJsonRequest: ''
    }
  },
  watch: {
    autoScrollEnabled (newValue) {
      if (newValue) {
        this.scrollToBottom()
      }
    },
    isChatOpen (newValue) {
      if (newValue) {
        this.scrollToBottom()
      }
    },
    messages () {
      this.messagesPointer = this.messages.length - 1
      this.scrollToBottom()
    }
  },
  methods: {
    scrollToBottom () {
      if (!this.autoScrollEnabled) {
        return
      }
      setTimeout(() => {
        let elem = document.getElementById('scrolled-content')
        let container = document.getElementById('scroll-target')
        container.scrollTop = Math.floor(elem.offsetHeight * 10)
      }, 15)
    },
    onChatBubbleClicked (msg) {
      if (msg.author === 'me') {
        this.fullJsonRequest = msg.data.request
        this.showFullRequest = true
      } else {
        this.fullJsonResponse = msg.data.response
        this.showFullResponse = true
        console.tag('ChatBot').verbose(msg.data.response)
      }
      // bubble this event to the parent
      this.$emit('chat-bubble-clicked', msg)
    }
  }
}
</script>

<style scoped>
.is-yours {
  border-radius: 25px 0px 25px 25px;
  display: table;
}
.is-not-yours {
  border-radius: 0px 25px 25px 25px;
  display: table;
}
</style>
