<template>
  <v-dialog
    v-model="dialog"
    width="100%"
    fullscreen
    scrollable
    style="z-index: 20000;">
    <template v-slot:activator="{}">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn v-if="mode === 'icon'" v-on="on" @click="dialog = true" icon>
            <v-icon>fullscreen</v-icon>
          </v-btn>
          <v-btn
            v-else-if="mode === 'text'"
            v-on="on"
            @click="dialog = true"
            text>
            Fullscreen {{ label }}
          </v-btn>
        </template>
        <span>Fullscreen {{ title }}</span>
      </v-tooltip>
    </template>
    <v-card v-if="dialog" class="scroll">
      <v-layout pa-4 column>
        <v-layout style="max-height: 60px;" row>
          <span class="headline">{{ title }}</span>
          <v-spacer />
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn v-on="on" @click="dialog = false" icon>
                <v-icon>fullscreen_exit</v-icon>
              </v-btn>
            </template>
            <span>Exit Fullscreen</span>
          </v-tooltip>
        </v-layout>
        <slot />
        <v-spacer fill-height />
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    label: {
      type: String
    },
    mode: {
      type: String,
      default: 'icon'
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  watch: {
    dialog () {
      this.$emit('fullscreen', this.dialog)
    }
  }
}
</script>

<style scoped>
.scroll {
  overflow-y: auto;
}
</style>
