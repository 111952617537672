import teststepService from '@/services/teststepService.js'

export const namespaced = true

export const state = {
  all: [],
  single: {},
  editedItem: [],
  default: {
    phrase: '',
    intent: { id: 0, name: '' },
    prompts: [],
    audioFile: null,
    deleted: false
  },
  toDelete: [],
  undeleteable: []
}

export const mutations = {
  FETCH_ENDS (state, testSteps) {
    state.all = testSteps
  },
  SET_EDITED_TESTSTEP (state, testCase) {
    state.editedItem = testCase.testSteps
    state.editedItem.forEach((item, index) => {
      state.editedItem[index]['deleted'] = false
      let prompts = []
      for (let prompt of item.prompts) {
        prompts.push({ id: prompt.id, name: prompt.text })
      }
      state.editedItem[index].prompts = prompts
    })
  },
  SET_EDITED_FIELD (state, data) {
    for (let [field, value] of Object.entries(data.data)) {
      state.editedItem[data.position][field] = value
    }
  },
  SET_DEFAULT_TESTSTEP (state, openDialog) {
    let arrayLength = openDialog ? 1 : state.editedItem.length
    let copiedTestStep = JSON.parse(JSON.stringify(state.default))
    let insertArray = Array(arrayLength).fill(copiedTestStep)
    state.editedItem = insertArray
    state.toDelete = []
    state.undeleteable = []
  },
  ADD_NEW_DEFAULT_TESTSTEP (state) {
    let copiedTestStep = JSON.parse(JSON.stringify(state.default))
    state.editedItem.push(copiedTestStep)
  },
  DELETE_TESTSTEP (state, position) {
    if ('id' in state.editedItem[position]) {
      state.toDelete.push(state.editedItem[position])
    }
    state.editedItem[position].deleted = true
  },
  REMOVE_TESTSTEP (state, position) {
    state.editedItem.splice(position, 1)
  },
  RESTORE_TESTSTEP (state, position) {
    if ('id' in state.editedItem[position]) {
      let indexToDeleteTestStep = state.toDelete.indexOf(position)
      state.toDelete.splice(indexToDeleteTestStep, 1)
      let indexUndeletableTestSteps = state.undeleteable.findIndex(
        entry => entry.id === position
      )
      state.undeleteable.splice(indexUndeletableTestSteps, 1)
    }
    state.editedItem[position].deleted = false
  },
  REORDER_TESTSTEPS () {
  }
}

export const actions = {
  async saveTestStep ({ state, dispatch }, newTestCase) {
    try {
      for (let [index, editedTestStep] of state.editedItem.entries()) {
        let testCaseId = newTestCase !== null
          ? newTestCase.data.createTestCase.id
          : this.state.testCase.editedItem[0].id
        let languageId = newTestCase !== null
          ? newTestCase.data.createTestCase.lanuage.id
          : this.state.testCase.editedItem[0].language.id
        let intent = state.editedItem[index].intent
        if (typeof intent === 'string' || !intent.hasOwnProperty('id')) {
          intent = await dispatch(
            'intent/create',
            { index: index, item: intent },
            { root: true }
          )
        }
        let prompts = state.editedItem[index].prompts
        let promptsId = []
        for (let prompt of prompts) {
          if (typeof prompts === 'string' || !prompt.hasOwnProperty('id')) {
            let newPrompt = await dispatch(
              'prompts/create',
              { index: index, item: { text: prompt, languageId: languageId } },
              { root: true }
            )
            promptsId.push(newPrompt.id)
          } else {
            promptsId.push(prompt.id)
          }
        }
        let data = {
          testCaseId: testCaseId,
          phrase: editedTestStep.phrase,
          intentId: intent.id,
          promptIds: promptsId,
          position: index
        }
        if (editedTestStep.audioFile) {
          data['audio'] = editedTestStep.audioFile
        }
        if (editedTestStep.id) {
          await teststepService.updateTS({
            id: editedTestStep.id,
            data: data
          })
        } else {
          await teststepService.createTeststep({
            data: data
          })
        }
      }
      for (let testStep of state.toDelete) {
        try {
          await teststepService.deleteTestStep(testStep.id)
        } catch (error) {
          console.error(error.message)
          state.undeleteable.push(testStep)
        }
      }
      return 'finished'
    } catch (error) {
      return error
    }
  }
}
