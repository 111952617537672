<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    height="600px"
    persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip
        bottom
        open-delay="1000">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            icon
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }">
            <v-icon
              small>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
        <span>Delete {{ headline }}</span>
      </v-tooltip>
    </template>
    <v-card v-if="dialog">
      <v-card-title>
        <h2
          class="text-h5"
          style="word-break: normal">
          Are you sure you want to delete {{ headline }}: '{{ item.name }}'?
        </h2>
      </v-card-title>
      <v-card-actions> <!-- Close & Delete Buttons -->
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
          text
          @click="deleteItem"
          color="primary">
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteDialog',
  data () {
    return {
      dialog: false
    }
  },
  props: {
    item: Object,
    store: String
  },
  computed: {
    headline () {
      return this.store.charAt(0).toUpperCase() + this.store.slice(1)
    }
  },
  methods: {
    async deleteItem () {
      await this.$store.dispatch(this.store + '/delete', this.item.id)
      this.closeDialog()
    },
    async closeDialog () {
      this.dialog = false
      this.$store.dispatch(this.store + '/fetch')
    }
  },
  watch: {
    dialog (value) {
      if (value === false) {
        this.closeDialog()
      }
    }
  }
}
</script>
