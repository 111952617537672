<template>
  <v-list-item
    class="menu"
    :to="item.path"
    @click="onClickItem()">
    <v-list-item-icon>
      <v-icon>
        {{ item.icon }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ item.title }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'NavigationMenu',
  props: {
    item: Object
  },
  methods: {
    onClickItem () {
      this.$store.commit('settings/SET_SUBMENU', false)
    }
  }
}
</script>
