<template>
  <v-card class="ma-3">
    <v-data-table
      :loading="loading"
      loading-text="Test Job Reports Loading..."
      :headers="headers"
      :items="items"
      hide-default-footer
      :options.sync="dataTableOptions"
      :server-items-length="dataTableOptions.totalItems"
      :footer-props="dataTableOptions.footerProps"
      style="background-color: transparent">
      <template v-slot:top>
        <v-toolbar>
          <v-toolbar-title>
            <h1 class="text-h5">
              Test Job History
            </h1>
          </v-toolbar-title>
        </v-toolbar>
        <v-toolbar>
          <v-row class="mb-0">
            <v-col cols="8">
              <v-text-field
                v-model="search"
                label="Search Test Jobs"
                prepend-inner-icon="search"
                outlined
                clearable
                dense
                hide-details />
            </v-col>
            <v-col>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                class="mt-n6">
                <template v-slot:activator="{}">
                  <v-select
                    v-model="time"
                    :items="timeframe"
                    item-text="name"
                    return-object
                    dense
                    outlined
                    clearable
                    hide-details />
                </template>
                <v-date-picker
                  v-model="customTime"
                  :max="maxTime"
                  color="primary"
                  no-title
                  scrollable
                  range>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    @click="closeMenu()">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="saveCustomTime()">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-toolbar height="420px">
          <Charts
            v-if="!chartLoading"
            :chartData="lineChart.data"
            :chartLabel="lineChart.labels" />
        </v-toolbar>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
        outlined>
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:[`item.executionStart`]="{ item }">
        <span>{{ item.executionStart | convertDateTime }}</span>
      </template>
      <template v-slot:[`item.executionEnd`]="{ item }">
        <span>{{ item.executionEnd | convertDateTime }}</span>
      </template>
      <template v-slot:[`item.maxResponseTime`]="{ item }">
        <span>{{ responseTime(item.maxResponseTime) }}</span>
      </template>
      <template v-slot:[`item.minResponseTime`]="{ item }">
        <span>{{ responseTime(item.minResponseTime) }}</span>
      </template>
      <template v-slot:[`item.avgResponseTime`]="{ item }">
        <span>{{ responseTime(item.avgResponseTime) }}</span>
      </template>
      <template v-slot:[`item.successRate`]="{ item }">
        <span
          v-if="item.testCaseCount === null || item.passedCount === null">
          {{ "" }}
        </span>
        <span v-else-if="item.testCaseCount === 0">
          {{ 0 }}
        </span>
        <span v-else>
          {{ (
            (
              parseFloat(item.passedCount) / parseFloat(item.testCaseCount)
            ) * 100
            ).toFixed(2) }}
        </span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-layout>
          <TestJobHistoriesDownload />
          <v-tooltip
            bottom
            open-delay="1000">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                v-on="on">
                <v-icon
                  small
                  @click="openDetails(item.id)">
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Open details of the Test Job</span>
          </v-tooltip>
        </v-layout>
      </template>
    </v-data-table>
    <Pagination type="testJobHistory" />
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Pagination from '@/components/fragments/Pagination'
import Charts from '@/components/charts/Charts.vue'
import TestJobHistoriesDownload from '@/components/fragments/TestJobHistoriesDownload'

export default {
  name: 'TestJobHistoryDataTable',
  components: {
    Charts,
    Pagination,
    TestJobHistoriesDownload
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    ...mapState(
      'testJobHistory', [
        'loading',
        'pagination',
        'chartLoading',
        'timeframe',
        'maxTime',
        'lineChart'
      ]
    ),
    headers () {
      return this.$store.state.testJobHistory.headers
    },
    dataTableOptions: {
      get () {
        return this.$store.state.testJobHistory.dataTableOptions
      },
      set (value) {
        this.$store.commit('testJobHistory/SET_TABLE_OPTIONS', value)
        this.fetchTestJobHistories()
      }
    },
    items: {
      get () {
        return this.$store.state.testJobHistory.all
      },
      set () {
        console.log('set TestJobHistory')
      }
    },
    search: {
      get () {
        return this.$store.state.testJobHistory.search
      },
      set (value) {
        this.$store.commit('testJobHistory/SET_TABLE_OPTIONS', { page: 1 })
        this.$store.commit('testJobHistory/SET_SEARCH', value)
        this.fetchTestJobHistories()
        this.fetchChartData()
      }
    },
    time: {
      get () {
        return this.$store.state.testJobHistory.time
      },
      set (value) {
        if (value !== null && value.custom === true) {
          this.menu = true
          this.$store.commit('testJobHistory/SET_CUSTOM_TIME_NAME', null)
          this.$store.commit('testJobHistory/SET_TIME', value)
        } else {
          this.$store.commit('testJobHistory/SET_TABLE_OPTIONS', { page: 1 })
          this.$store.commit('testJobHistory/SET_TIME', value)
          this.fetchTestJobHistories()
          this.fetchChartData()
        }
      }
    },
    customTime: {
      get () {
        let result = []
        for (let item of this.$store.state.testJobHistory.customTime) {
          result.push(item.toISOString().slice(0, 10))
        }
        return result
      },
      set (value) {
        this.$store.commit('testJobHistory/SET_CUSTOM_TIME', value)
        this.$store.commit('testJobHistory/SET_CUSTOM_TIME_NAME', null)
      }
    }
  },
  methods: {
    ...mapActions('testJobHistory', ['fetchChartData']),
    ...mapActions('testJobHistory', { fetchTestJobHistories: 'fetch' }),
    ...mapActions('testResult', { fetchTestResults: 'fetch' }),
    responseTime (time) {
      if (time) {
        return (parseFloat(time) / 1000).toFixed(2)
      } else {
        return ''
      }
    },
    openDetails (id) {
      this.$router.push({
        name: 'TestJobRunHistory', params: { id: id }
      })
    },
    saveCustomTime () {
      this.menu = false
      this.$store.commit('testJobHistory/SET_CUSTOM_TIME_NAME', 'Custom')
      this.fetchTestJobHistories()
      this.fetchChartData()
    },
    closeMenu () {
      this.menu = false
      this.$store.commit('testJobHistory/SET_TABLE_OPTIONS', { page: 1 })
      this.$store.commit('testJobHistory/SET_TIME', null)
      this.$store.commit('testJobHistory/SET_CUSTOM_TIME_NAME', 'Custom')
      this.fetchTestJobHistories()
      this.fetchChartData()
    }
  },
  mounted () {
    this.fetchTestJobHistories()
    this.fetchChartData()
  }
}
</script>
