<template>
  <v-dialog
    v-model="dialog"
    max-width="1200px"
    height="600px"
    persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <!-- Buttons to open dialog -->
      <v-tooltip
        bottom
        open-delay="1000">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            icon
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            class="mr-1"
            disabled>
            <v-icon>
              upload
            </v-icon>
          </v-btn>
        </template>
        <span>Upload Test Cases as Excel or JSON file</span><br/>
      </v-tooltip>
    </template>
    <v-card v-if="dialog">
      <v-card-title>
        <h2>Upload Test Cases</h2>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container grid-list-md>
            <v-row> <!-- Test Case Form -->
              <v-col cols="12" sm="8" md="8"> <!-- Name -->
                <v-file-input
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .json"
                label="Upload Test Cases in JSON or Excel format"
                required
                show-size
                :rules="rules"
                v-model="file">
              </v-file-input>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-combobox
                  v-model="languageModel"
                  label="Language*"
                  :items="Languages"
                  :rules="rules"
                  auto-select-first
                  return-object
                  required>
                </v-combobox>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions> <!-- Close & Save Buttons -->
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="importFile"
          :disabled="!valid">
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import fileService from '../../services/fileService'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'TestCaseUploadDialog',
  data () {
    return {
      dialog: false,
      valid: false,
      rules: [v => !!v || 'Field is required']
    }
  },
  computed: {
    Languages () {
      let Languages = []
      if (this.loading === false) {
        this.all.forEach(item => {
          Languages.push({
            value: item.id,
            text: item.name
          })
        })
      }
      return Languages
    },
    ...mapState('language', ['all', 'loading']),
    ...mapState('testCase', ['pagination'])
  },
  methods: {
    getFileData (file) {
      return new Promise((resolve, reject) => {
        const fReader = new FileReader()
        fReader.onload = () => {
          try {
            resolve(JSON.parse(fReader.result, null, 2))
          } catch (err) {
            reject(err)
          }
        }
        fReader.readAsText(file)
      })
    },
    async importFile () {
      const fileType = this.file.type === 'application/json' ? 'json' : 'excel'
      const data = await this.getFileData(this.file)
      const variables = {
        data: {
          languageId: this.languageModel.value,
          file: data
        }
      }

      if (fileType === 'json') {
        try {
          let uploadResult = await fileService.uploadJson(variables)
          if (uploadResult.data.importJson.status) {
            this.addNotification({
              message: uploadResult.data.importJson.message,
              type: 'success'
            })
            this.fetchMeta()
            setTimeout(() => this.$router.push({ path: 'TestCases' }), 2000)
          } else {
            this.addNotification({
              message: uploadResult.data.importJson.message,
              type: 'error'
            })
          }
        } catch (error) {
          console.error(error)
          this.status = -1
        }
      } else {
        try {
          await fileService.uploadExcel(variables)
        } catch (error) {
          console.error(error)
          this.status = -1
        }
      }
    },
    async closeDialog () {
      this.$refs.form.reset()
      this.dialog = false
    },
    ...mapActions('testCase', ['fetchMeta']),
    ...mapActions('notification', { addNotification: 'add' }),
    ...mapActions('language', ['fetch'])
  },
  async closeDialog () {
    this.$refs.form.reset()
    this.dialog = false
  },
  watch: {
    dialog (value) {
      if (value === true) {
        this.fetch()
      } else {
        this.closeDialog()
      }
    }
  }
}
</script>
