import { apolloClient } from '@/vue-apollo'
import { TEST_JOB_RUN_HISTORY } from '@/graphql/graphql'

export default {
  async getTestJobRunHistory (args) {
    let testJobRunHistory = await apolloClient.query({
      query: TEST_JOB_RUN_HISTORY, variables: args
    })
    return testJobRunHistory
  }
}
