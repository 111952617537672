import promptService from '@/services/promptService.js'

export const namespaced = true

export const state = {
  single: {},
  all: [],
  new: [],
  loading: true
}

export const getters = {
}

export const mutations = {
  ADD (state, prompt) {
    state.all.push({ name: prompt })
  },
  FETCH_STARTS (state) {
    state.loading = true
  },
  FETCH_ENDS (state, prompts) {
    state.all = prompts
  },
  CREATE_NEW (state, data) {
    if (Array.isArray(state.new[data.position])) {
      state.new[data.position].push({ name: data.item })
    } else {
      state.new.splice(data.position, 1, [{ name: data.item }])
    }
  },
  CLEAR_NEW (state) {
    state.new = []
  }
}
export const actions = {
  async fetch ({ commit }) {
    commit('FETCH_STARTS')
    promptService.getPrompts().then(
      response => {
        let data = []
        response.data.prompts.data.forEach((value, index) => {
          data.splice(index, 0, {
            id: value.id,
            name: value.text
          })
        })
        commit('FETCH_ENDS', data)
      })
      .catch(error => {
        const errorMessage = 'Error while fetching promptS: ' + error.message
        console.log(errorMessage)
        console.log(error.response.data)
      })
  },
  async create ({ state }, data) {
    try {
      if (state.new) {
        let response = await promptService.createPrompt(
          { data: data.item }
        )
        return response.data.createPrompt
      }
      return null
    } catch (error) {
      const errorMessage = 'Error while creating Domains: ' + error.message
      console.log(errorMessage)
      console.log(error.response.data)
    }
  }
}
