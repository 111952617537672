<template>
  <v-card class="ma-3">
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="all"
      :search="search"
      hide-default-footer
      :options.sync="dataTableOptions"
      :server-items-length="dataTableOptions.totalItems">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="mr-6">
            <v-icon class="mb-1 mr-2">mdi-pound</v-icon>
            System Under Test
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Search Name"
            hide-details
            prepend-inner-icon="search"
            outlined
            dense
            class="mr-2">
          </v-text-field>
          <SystemUnderTestDialog />
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <SystemUnderTestDialog :systemUnderTestId="item.id" />
        <DeleteDialog store="systemUnderTest" :item="item" />
      </template>
    </v-data-table>
    <Pagination type="systemUnderTest" />
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Pagination from '@/components/fragments/Pagination'
import SystemUnderTestDialog from '@/components/dialogs/SystemUnderTest'
import DeleteDialog from '@/components/dialogs/Delete'

export default {
  name: 'SystemUnderTestDataTable',
  components: {
    Pagination,
    SystemUnderTestDialog,
    DeleteDialog
  },
  computed: {
    ...mapState('systemUnderTest', ['all', 'loading', 'headers']),
    dataTableOptions: {
      get () {
        return this.$store.state.systemUnderTest.dataTableOptions
      },
      set (value) {
        this.$store.commit('systemUnderTest/SET_TABLE_OPTIONS', value)
        this.fetch()
      }
    },
    search: {
      get () {
        return this.$store.state.systemUnderTest.search
      },
      set (value) {
        this.$store.commit('systemUnderTest/SET_SEARCH', value)
        this.fetch()
      }
    }
  },
  methods: {
    ...mapActions('systemUnderTest', ['fetch'])
  },
  mounted () {
    this.fetch()
    this.$store.commit('settings/SET_SUBMENU', true)
  }
}
</script>
