import writeXlsxFile from 'write-excel-file'

export default {
  async createXSLXTestResults (testJobHistoryID, testResults) {
    console.log(
      'Excel generation started for TestJob History: ' + testJobHistoryID
    )
    const schema = [
      {
        column: 'Id',
        type: Number,
        value: result => result.testCase.id || 0,
        width: 8
      },
      {
        column: 'status',
        type: Boolean,
        value: result => result.passed || false,
        width: 20
      },
      {
        column: 'Cerence profile',
        type: String,
        value: result => (result.testJobHistory && result.testJobHistory.testJob)
          ? result.testJobHistory.testJob.name : '',
        width: 16
      },
      {
        column: 'Language',
        type: String,
        value: result => (result.testCase && result.testCase.language) ? result.testCase.language.cerenceCode : '',
        width: 15
      },
      {
        column: 'transcription',
        type: String,
        value: result => result.transcription,
        width: 50
      },
      {
        column: 'Expected transcription',
        type: String,
        value: result => (result.testCase && result.testCase.testSteps && result.testCase.testSteps.length > 0)
          ? result.testCase.testSteps[0].phrase : '',
        width: 50
      },
      {
        column: 'Word error rate',
        type: Number,
        value: result => result.wordErrorRate || 0.0,
        format: '0.00',
        width: 15
      },
      {
        column: 'domain',
        type: String,
        value: result => result.domain,
        width: 15
      },
      {
        column: 'Expected Domain',
        type: String,
        value: result => (result.testCase && result.testCase.domain) ? result.testCase.domain.name : '',
        width: 15
      },
      {
        column: 'Intent',
        type: String,
        value: result => result.intent,
        width: 25
      },
      {
        column: 'Expected Intent',
        type: String,
        value: result => (result.testCase && result.testCase.testSteps &&
          result.testCase.testSteps.length > 0 && result.testCase.testSteps[0].intent)
          ? result.testCase.testSteps[0].intent.name : '',
        width: 25
      },
      {
        column: 'voice prompt',
        type: String,
        value: result => (result.prompt) ? result.prompt.replaceAll('\u001b', '\\u001b') : '',
        width: 50
      },
      {
        column: 'response time',
        type: Number,
        value: result => (result.latency || 0.0) / 1000.0,
        width: 15
      },
      {
        column: 'nmaid',
        type: String,
        value: result => 'nmaid',
        width: 20
      },
      {
        column: 'NCS session id',
        type: String,
        value: result => result.sessionId,
        width: 40
      },
      {
        column: 'Trace id',
        type: String,
        value: result => result.traceId,
        width: 40
      },
      {
        column: 'Daimler Git Tag',
        type: String,
        value: result => 'Daimler Git Tag',
        width: 20
      },
      {
        column: 'pre_tts_text',
        type: String,
        value: result => (result.preTtsText) ? result.preTtsText.replaceAll('\u001b', '\\u001b') : '',
        width: 50
      },
      {
        column: 'expected pre_tts_text',
        type: String,
        value: result => 'expectedPreTtsText',
        width: 50
      },
      {
        column: 'audio_data',
        type: String,
        value: result => result.audioData,
        width: 50
      },
      {
        column: 'expected audio_data',
        type: String,
        value: result => 'expectedAudioData',
        width: 50
      }
    ]
    await writeXlsxFile(testResults, {
      schema,
      fileName: 'test-results-' + testJobHistoryID + '.xlsx',
      stickyRowsCount: 1,
      fontFamily: 'Calibri',
      fontSize: 11
    })
    console.log('Excel file generated and downloaded!')
  }
}
