import {
  ALL_SYSTEM_UNDER_TEST,
  CREATE_SYSTEM_UNDER_TEST,
  UPDATE_SYSTEM_UNDER_TEST,
  DELETE_SYSTEM_UNDER_TEST
} from '../graphql/graphql'
import { apolloClient } from '@/vue-apollo'

export default {
  async createSystemUnderTest (newSUT) {
    return apolloClient.mutate({
      mutation: CREATE_SYSTEM_UNDER_TEST, variables: newSUT
    })
  },
  async updateSystemUnderTest (updatedSUT) {
    return apolloClient.mutate({
      mutation: UPDATE_SYSTEM_UNDER_TEST, variables: updatedSUT
    })
  },
  async deleteSystemUnderTest (id) {
    return apolloClient.mutate({
      mutation: DELETE_SYSTEM_UNDER_TEST, variables: id
    })
  },
  getSUTs (variables) {
    return apolloClient.query({
      query: ALL_SYSTEM_UNDER_TEST, variables: variables
    })
  }
}
