import { apolloClient } from '@/vue-apollo'
import {
  USER,
  USERS,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER
} from '../graphql/graphql'

export default {
  createUser (newUser) {
    return apolloClient.mutate({ mutation: CREATE_USER, variables: newUser })
  },
  updateUser (updatedUser) {
    return apolloClient.mutate({
      mutation: UPDATE_USER, variables: updatedUser
    })
  },
  deleteUser (userId) {
    return apolloClient.mutate({ mutation: DELETE_USER, variables: userId })
  },
  getUser (id) {
    return apolloClient.query({
      query: USER, fetchPolicy: 'no-cache', variables: id
    })
  },
  getUsers (args) {
    return apolloClient.query({
      query: USERS, fetchPolicy: 'no-cache', variables: args
    })
  }
}
