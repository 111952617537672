<template>
  <v-card class="ma-3">
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="all"
      :search="search"
      hide-default-footer
      :options.sync="dataTableOptions"
      :server-items-length="dataTableOptions.totalItems">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="mr-6">
            <v-icon class="mb-1 mr-2">mdi-settings</v-icon>
            Configurations
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Search Name"
            hide-details
            prepend-inner-icon="search"
            outlined
            dense
            class="mr-2">
          </v-text-field>
          <ConfigurationDialog />
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <ConfigurationDialog :configurationId="item.id"/>
        <DeleteDialog store="configuration" :item="item" />
      </template>
      <template v-slot:[`item.recognition`]="{ item }">
        <span>
          {{
            item.recognition.slice(0,1) +
            item.recognition.slice(1).toLowerCase()
          }}
        </span>
      </template>
    </v-data-table>
    <Pagination type="configuration" />
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Pagination from '@/components/fragments/Pagination'
import ConfigurationDialog from '@/components/dialogs/Configuration'
import DeleteDialog from '@/components/dialogs/Delete'

export default {
  name: 'ConfigurationDataTable',
  components: {
    Pagination,
    ConfigurationDialog,
    DeleteDialog
  },
  computed: {
    ...mapState('configuration', [
      'all',
      'headers',
      'loading'
    ]),
    dataTableOptions: {
      get () {
        return this.$store.state.configuration.dataTableOptions
      },
      set (value) {
        this.$store.commit('configuration/SET_TABLE_OPTIONS', value)
        this.fetch()
      }
    },
    search: {
      get () {
        return this.$store.state.configuration.search
      },
      set (value) {
        this.$store.commit('configuration/SET_SEARCH', value)
        this.fetch()
      }
    }
  },
  methods: {
    ...mapActions('configuration', ['fetch'])
  },
  mounted () {
    this.fetch()
    this.$store.commit('settings/SET_SUBMENU', false)
  }
}
</script>
