<template>
  <div>
    <!-- Field for data without option to add new item -->
    <v-combobox
      v-if="addNew"
      v-model="item"
      :label="label"
      return-object
      clearable
      :required="required"
      outlined
      :multiple="multiple"
      :small-chips="multiple"
      :deletable-chips="multiple"
      dense
      hide-details
      item-text="name"
      :items="allItems"
      :rules="rules">
    </v-combobox>
    <!-- Field for data with option to add new item -->
    <v-autocomplete
      v-else
      v-model="item"
      :label="label"
      return-object
      clearable
      :required="required"
      outlined
      dense
      hide-details
      item-text="name"
      :items="allItems"
      :rules="rules">
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    store: String,
    index: Number,
    required: Boolean,
    addNew: Boolean,
    multiple: Boolean
  },
  data () {
    return {
      rules: [v => !!v || 'Field is required']
    }
  },
  computed: {
    item: {
      get () {
        return this.$store.state[this.store].editedItem[this.index][this.type]
      },
      set (value) {
        let obj = {
          data: {},
          position: this.index
        }
        obj['data'][this.type] = value
        this.$store.commit(this.store + '/SET_EDITED_FIELD', obj)
        if (this.addNew) {
          if (typeof value === 'string') {
            this.$store.commit(this.type + '/CREATE_NEW', {
              item: value,
              position: this.index
            })
          } else if (Array.isArray(value)) {
            for (let item of value) {
              if (typeof item === 'string') {
                this.$store.commit(this.type + '/CREATE_NEW', {
                  item: item,
                  position: this.index
                })
              }
            }
          }
        }
      }
    },
    allItems () {
      if (this.addNew) {
        let newEntries = this.$store.state[this.type].new
        if (this.multiple) {
          newEntries = [].concat.apply([], this.$store.state[this.type].new)
        }
        return newEntries.concat(
          this.$store.state[this.type].all
        )
      } else {
        return this.$store.state[this.type].all
      }
    },
    label () {
      let type = this.type.charAt(0).toUpperCase() + this.type.slice(1)
      let required = this.required ? '*' : ''
      return type + required
    }
  },
  mounted () {
    this.$store.dispatch(this.type + '/fetch')
  }
}
</script>
