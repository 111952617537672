
<template>
  <v-list>
    <template v-for="(item, i) in items">
      <NavigationMenu v-if="!item.submenu" :item="item" :key="i" />
      <NavigationSubMenu v-else :item="item" :key="i" />
    </template>
  </v-list>
</template>

<script>
import { mapState } from 'vuex'
import NavigationMenu from '@/components/navigation/NavigationMenu'
import NavigationSubMenu from '@/components/navigation/NavigationSubMenu'

export default {
  name: 'SideBar',
  components: {
    NavigationMenu,
    NavigationSubMenu
  },
  computed: {
    ...mapState(['isLeftDrawerOpen']),
    ...mapState('navigation', ['items'])
  }
}
</script>

<style>
  .menu {
    font-weight: 400;
  }
  .menu.v-list-item--active,
  .submenu .v-list-item--active {
    border-left: 4px solid var(--v-text-base);
    background-color: var(--v-menuBackground-base);
    color: var(--v-text-base) !important
  }
  .submenu {
    background-color: var(--v-darkerBackground-base);
  }
  .submenu .v-list-item--active {
    background-color: var(--v-lighterBackground-base);
  }
  .theme--dark.v-list-item--active:before,
  .theme--dark.v-list-item--active:hover:before,
  .theme--dark.v-list-item:focus:before {
    opacity: .0 !important;
  }
</style>
