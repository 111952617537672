<template>
  <div class="text-center pt-2">
    <v-container
      class="pb-0">
      <v-row justify="center">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
              v-model="currentPage"
              :length="lastPage"
              total-visible="7"
              color="lighterBackground">
            </v-pagination>
            <p class="text-overline mt-2 lighterBackground--text mb-0">
              Entries {{ itemRangeFrom }}-{{ itemRangeTo }} of
              {{ dataTableOptions.totalItems }}
            </p>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ['type'],
  computed: {
    dataTableOptions () {
      return this.$store.state[this.type].dataTableOptions
    },
    itemsPerPage () {
      return this.dataTableOptions.itemsPerPage
    },
    totalItems () {
      return this.dataTableOptions.totalItems
    },

    itemRangeFrom: {
      get () {
        return this.currentPage === 1
          ? 1
          : ((this.currentPage - 1) * this.itemsPerPage) + 1
      }
    },
    itemRangeTo: {
      get () {
        return this.currentPage === 1
          ? this.totalItems
          : (
            (this.currentPage * this.itemsPerPage) > this.totalItems
              ? this.totalItems
              : (this.currentPage * this.itemsPerPage)
          )
      }
    },
    currentPage: {
      get () {
        return this.dataTableOptions.currentPage
      },
      set (value) {
        this.$store.commit(this.type + '/SET_TABLE_OPTIONS', {
          currentPage: value
        })
      }
    },
    lastPage: {
      get () {
        return this.dataTableOptions.totalPages
      }
    }
  },
  watch: {
    currentPage () {
      this.$store.dispatch(this.type + '/fetch')
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
}
</script>
