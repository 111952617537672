<template>
  <v-app id="app">
    <v-navigation-drawer
      :value="isLeftDrawerOpen"
      :mini-variant="!isLeftDrawerOpen"
      :width="250"
      clipped
      permanent
      app
      v-if="!home">
      <SideBar />
    </v-navigation-drawer>
    <Header
      @switch-show-chat="switchShowChat"
      :home="home"/>
    <v-main>
      <div>
        <slot />
      </div>
      <template v-if="!home && showChat">
        <template v-if="isChatOpen">
          <template v-if="showTts">
              <div id="fixed-tts-button">
                <TextToSpeech :textToRead="textToRead"></TextToSpeech>
              </div>
            </template>
          </template>
          <ChatBot
            v-model="messages"
            :textQueryFromVoice="lastVoiceQuery"
            :newVoiceRequest="newVoiceRequest"
            @read-response="readResponse"
            @clear-history="messages = []"
            title="#HeyMercedes" />
        </template>
        <NotificationContainer v-if="!home && showNotifications" />
    </v-main>
    <v-footer app fixed class="pa-3">
      <div>&copy; 2020 MBRDNA</div>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ChatBot from '@/components/chat/ChatBot.vue'
import Cookies from 'js-cookie'
import SideBar from '@/components/navigation/SideBarLeft'
import Header from '@/components/header/Header'
import NotificationContainer from '@/components/notification/NotificationContainer.vue'
import TextToSpeech from '@/components/TextToSpeech.vue'

export default {
  components: {
    ChatBot,
    SideBar,
    NotificationContainer,
    TextToSpeech,
    Header
  },
  data: () => {
    return {
      speechToTextResults: '',
      messages: [
        {
          isYours: false,
          type: 'text',
          author: 'bot',
          data: {
            text: 'Hi! This is #HeyMercedes in the USA',
            response: {}
          }
        }
      ],
      newVoiceRequest: '',
      showChat: false,
      showNotifications: true,
      showTts: false,
      textToRead: 'I have nothing to say yet'
    }
  },
  computed: {
    home () {
      return this.$route.path === '/'
    },
    envModeFromCookie () {
      let envMode = Cookies.get('envmode')
      console.tag('Default').info('envmode:', envMode)
      Cookies.remove('envmode')
      return envMode
    },
    lastVoiceQuery () {
      /* eslint-disable standard/computed-property-even-spacing */
      return this.speechToTextResults.sentences &&
        this.speechToTextResults.sentences.length > 0
        ? this.speechToTextResults.sentences[
          this.speechToTextResults.sentences.length - 1
        ]
        : ''
    },
    userFromCookie () {
      const userToken = Cookies.get('user')
      Cookies.remove('user')
      if (userToken) {
        return Buffer.from(userToken, 'base64').toString('utf8')
      }
      return undefined
    },
    ...mapState([
      'envMode',
      'isChatOpen',
      'isLeftDrawerOpen',
      'isRightDrawerOpen',
      'user'
    ]),
    ...mapGetters(['isProduction'])
  },
  methods: {
    readResponse (response) {
      this.textToRead = response
    },
    switchShowChat (value) {
      this.showChat = value
    },
    switchShowNotifications (value) {
      this.showNotifications = value
    },
    switchShowTts (value) {
      this.showTts = value
    },
    ...mapActions(['switchDrawerState']),
    ...mapActions('users', ['setCurrentUser'])
  },
  created () {
    if (this.userFromCookie) {
      this.setCurrentUser(JSON.parse(this.userFromCookie))
    }
    if (this.envModeFromCookie) {
      this.$store.state.envMode = this.envModeFromCookie
    }
  }
}
</script>
<style scoped>
.brand-icon {
  margin-left: 25px;
  width: 32px;
  height: 32px;
  display: block;
}
#fixed-tts-button {
  bottom: 32px;
  right: 201px;
  position: fixed;
  z-index: 3000;
}

</style>
