import {
  ALL_LANGUAGES,
  CREATE_LANGUAGE,
  DELETE_LANGUAGE,
  UPDATE_LANGUAGE
} from '../graphql/graphql'
import { apolloClient } from '@/vue-apollo'

export default {

  async  getLanguages (variables) {
    return apolloClient.query({ query: ALL_LANGUAGES, variables: variables })
  },
  async  getLang (id) {
    return apolloClient.query({ query: ALL_LANGUAGES, variables: id })
  },
  async createLanguage (newLanguage) {
    return apolloClient.mutate({ mutation: CREATE_LANGUAGE, variables: newLanguage })
  },
  async updateLanguage (updatedLanguage) {
    return apolloClient.mutate({ mutation: UPDATE_LANGUAGE, variables: updatedLanguage })
  },
  async deleteLanguage (id) {
    return apolloClient.mutate({ mutation: DELETE_LANGUAGE, variables: id })
  }
}
