<template>
  <div>
    <div v-if="result === null">
      N/A
    </div>
    <v-chip
      v-else-if="result === true"
      color="success"
      label
      small>
      <v-icon left>
        mdi-check
      </v-icon>
      PASS
    </v-chip>
    <v-chip
      v-else
      color="error"
      label
      small>
      <v-icon left>
        mdi-close
      </v-icon>
      FAIL
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    result: Boolean
  }
}
</script>
