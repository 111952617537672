export const namespaced = true

export const state = {
  notifications: []
}

let nextId = 1
export const mutations = {
  PUSH (state, notification) {
    state.notifications.push({
      ...notification,
      id: nextId++
    })
  },
  DELETE (state, notificationToRemove) {
    const index = state.notifications.findIndex(object => {
      return object.id === notificationToRemove.id
    })
    state.notifications.splice(index, 1)
  }
}

export const actions = {
  add ({ commit }, notification) {
    commit('PUSH', notification)
  },
  remove ({ commit }, notificationToRemove) {
    commit('DELETE', notificationToRemove)
  },
  show ({ commit }, { message, error }) {
    const type = error ? 'error' : 'success'
    const notification = { type, message }
    if (error) {
      notification.secondaryMessage = error.response.data.msg || ''
    }
    commit('PUSH', notification)
  }
}
