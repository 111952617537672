import testcaseService from '@/services/testcaseService.js'
import {
  createLimit,
  createOffset,
  createOrder,
  createTotalPages,
  createCurrentPage
} from './_utilsStore'

export const namespaced = true

export const state = {
  all: [],
  testCase: {},
  loading: false,
  busy: [],
  testCaseResults: {},
  headers: [
    { text: 'ID', value: 'id', width: '65px' },
    { text: 'Testcase', value: 'name' },
    { text: 'Domain', value: 'domain.name', width: '100px' },
    { text: 'Language', value: 'language.name', width: '110px' },
    { text: 'Platform', value: 'platform', width: '105px' },
    {
      text: 'Last Run',
      value: 'latestTestResult',
      width: '130px',
      sortable: false
    },
    { text: 'Last Result', value: 'result', width: '115px', sortable: false },
    { text: 'Actions', value: 'action', sortable: false, width: '145px' }
  ],
  dataTableOptions: {
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: ['id'],
    sortDesc: ['false'],
    multiSort: false,
    mustSort: true,
    totalItems: 0,
    totalPages: 0
  },
  search: null,
  filter: {
    domain: null,
    creator: 'ALL',
    intent: null
  },
  editedItem: [],
  defaultTestCase: {
    name: '',
    domain: { id: 0, name: '' },
    language: { id: 0, name: '' },
    platform: { name: '' }
  }
}

export const mutations = {
  FETCH_STARTS (state) {
    state.all = []
    state.loading = true
  },
  FETCH_ENDS (state, testCases) {
    state.all = testCases.data
    let paging = testCases.meta.paging
    state.dataTableOptions.totalItems = paging.total
    state.dataTableOptions.totalPages = createTotalPages(
      paging.total, paging.limit
    )
    state.dataTableOptions.currentPage = createCurrentPage(
      paging.offset, paging.limit
    )
    state.loading = false
  },
  DELETE (state, id) {
    state.testCases = state.testCases.filter(testCase => testCase.id !== id)
  },
  SET_EDITED_TESTCASE (state, id) {
    let testCaseToEdit = state.all.findIndex(obj => obj.id === id)
    let copiedTestCase = JSON.parse(
      JSON.stringify(state.all[testCaseToEdit])
    )
    state.editedItem = [Object.fromEntries(
      Object
        .entries(copiedTestCase)
        .filter(
          ([key]) => [
            'id', 'name', 'language', 'platform', 'domain'
          ].includes(key)
        )
    )]
    this.commit('testStep/SET_EDITED_TESTSTEP', copiedTestCase)
  },
  SET_EDITED_FIELD (state, data) {
    for (let [field, value] of Object.entries(data.data)) {
      state.editedItem[0][field] = value
    }
  },
  SET_DEFAULT_TESTCASE (state) {
    state.editedItem = [state.defaultTestCase]
  },
  SET_SEARCH (state, data) {
    state.search = data
  },
  SET_FILTER (state, data) {
    for (let setting in data) {
      state.filter[setting] = data[setting]
    }
  },
  SET_TABLE_OPTIONS (state, data) {
    for (let setting in data) {
      state.dataTableOptions[setting] = data[setting]
    }
  }
}

export const actions = {
  async fetch ({ commit, state }) {
    try {
      commit('FETCH_STARTS')
      const creator = (state.selectedCreator === 'ME') ? this.user.email : null
      const filter = {
        name: {
          contains: state.search
        },
        creator: {
          email: creator
        },
        domain: {
          name: state.filter.domain
        },
        testStep: {
          intent: {
            name: state.filter.intent
          }
        }
      }
      const { data, loading } = await testcaseService.getTCs({
        limit: await createLimit(state.dataTableOptions),
        offset: await createOffset(state.dataTableOptions),
        filter: filter,
        order: await createOrder(state.dataTableOptions)
      })
      if (loading) {
        commit('FETCH_STARTS')
      }
      if (data) {
        commit('FETCH_ENDS', data.testCases)
        return data.testCases
      }
    } catch (error) {
      const errorMessage = 'Error while fetching testcases: ' + error.message
      console.log(errorMessage)
    }
  },
  async delete ({ state }, id) {
    try {
      const response = await testcaseService.deleteTC({
        id: id
      })
      console.log('TestCase deleted: ' + id)
      return response.data.deleteTestCases
    } catch (error) {
      console.error(error)
    }
  },
  async deleteBulk ({ commit }, args) {
    try {
      const response = await testcaseService.deleteBulkTC(args)
      commit('BULK_DELETE', response.data.deleteTestCases)
      return response.data.deleteTestCases
    } catch (error) {
      const errorMessage = 'Error while fetching cars: ' + error.message
      console.error(errorMessage)
      console.error(error)
      return error.message
    }
  },
  async play ({ commit }, { testCase, configurationId }) {
    commit('ADD_BUSY_TEST_CASE', testCase)
    try {
      let response = await testcaseService.playTestCase({
        data: {
          testCaseId: testCase.id,
          configurationId
        }
      })
      commit('UPDATE_TEST_CASE_RESULTS', {
        testCase,
        result: response.data.playTestCase
      })
      commit(
        'testresult/ADD_TEST_RESULT',
        response.data.playTestCase,
        { root: true }
      )
      commit('REMOVE_BUSY_TEST_CASE', testCase)
      return response
    } catch (error) {
      console.error(error)
      commit('REMOVE_BUSY_TEST_CASE', testCase)
      return error
    }
  },
  async fetchMeta ({ commit }) {
    try {
      commit('FETCH_STARTS')
      const { data: { testCases: { meta } } } = await testcaseService.getTCs(
        { limit: 0, offset: 0 }
      )
      commit('SET_META', meta)
    } catch (error) {
      const errorMessage = 'Error while fetching cars: ' + error.message
      console.error(errorMessage)
      console.error(error)
    }
  },
  async save ({ state, dispatch }, id) {
    try {
      let domain = state.editedItem[0].domain
      if (typeof domain === 'string' || !domain.hasOwnProperty('id')) {
        domain = await dispatch('domain/create', '', { root: true })
      }
      let newTestCase = null
      let data = {
        name: state.editedItem[0].name,
        domainId: domain.id,
        languageId: state.editedItem[0].language.id,
        platform: state.editedItem[0].platform.name
      }
      if (id) {
        await testcaseService.updateTC({
          id: id,
          data: data
        })
      } else {
        newTestCase = await testcaseService.createTestCase({
          data: data
        })
      }
      return newTestCase
    } catch (error) {
      console.error(error)
    }
  }
}
