import { ALL_SLOTS, CREATE_SLOT } from '../graphql/graphql'
import { apolloClient } from '@/vue-apollo'

export default {
  async  getSlots () {
    return apolloClient.query({ query: ALL_SLOTS })
  },
  async createSlot (newSlot) {
    return apolloClient.mutate({ mutation: CREATE_SLOT, variables: newSlot })
  }
}
