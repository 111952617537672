import { makeClient } from './client'

const apiClient = makeClient('/stages')

export default {
  getStages ({ market, environment }) {
    return apiClient.get('/?market=' + market + '&environment=' + environment)
  },
  getStage ({ domainId, market, environment, language }) {
    let path = '/' + domainId + '?environment=' + environment
    if (market) path += '&market=' + market
    if (language) path += '&language=' + language
    return apiClient.get(path)
  },
  createStage ({ newStageData }) {
    return apiClient.post('/', newStageData)
  },
  deleteStage ({ domainId, market, environment, language }) {
    return apiClient.delete(
      '/' +
        domainId +
        '?market=' +
        market +
        '&environment=' +
        environment +
        '&language=' +
        language
    )
  }
}
