import localeService from '@/services/localeService.js'

export const namespaced = true

export const state = {
  all: []
}

export const mutations = {
  FETCH_STARTS (state) {
    state.all = []
  },
  FETCH_ENDS (state, data) {
    state.all = data.data
  }
}

export const actions = {
  async fetch ({ commit }) {
    try {
      commit('FETCH_STARTS')
      const response = await localeService.getLocales()
      commit('FETCH_ENDS', response.data.locales)
      return response.data.locales
    } catch (error) {
      console.error(error)
    }
  }
}
