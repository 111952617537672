<template>
  <FullscreenDialog label="Chat Bot" mode="text">
    <v-layout style="weight: 100%; height: 100%" column>
      <v-layout column>
        <h3 class="">{{ title }}</h3>
        <h3 class="">CLOUD</h3>
      </v-layout>
      <ChatBotMessages
        :messages="messageList"
        :autoScrollEnabled="autoScrollEnabled"
        :botOnRight="false"
        style="width: 100%; height: 100%"
      />
      <ChatBotInput
        :textInputProp="textInput"
        :messagesProp="messageList"
        :messagesPointerProp="messagesPointer"
        @on-send="sendMessage"
        @request-recorded="onRequestRecorded"
        class="mr-1"
      >
        <template #chat-input-left>
          <v-icon v-if="dialogSessionEnabled" class="mr-4" color="green"
            >cached</v-icon
          >
        </template>
      </ChatBotInput>
    </v-layout>
  </FullscreenDialog>
</template>

<script>
import ChatBotInput from '@/components/chat/ChatBotInput.vue'
import ChatBotMessages from '@/components/chat/ChatBotMessages.vue'
import FullscreenDialog from '@/components/FullscreenDialog.vue'

export default {
  components: {
    ChatBotInput,
    ChatBotMessages,
    FullscreenDialog
  },
  props: {
    autoScrollEnabled: {},
    dialogSessionEnabled: {
      type: Boolean
    },
    title: {
      type: String
    },
    requestHeader: {},
    requestInfo: {},
    textInput: {},
    messageList: {
      type: Array
    },
    messagesPointer: {},
    sendMessage: {},
    onRequestRecorded: {}
  }
}
</script>

<style lang="scss" scoped></style>
