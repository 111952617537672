import { ALL_INTENTS, CREATE_INTENT } from '../graphql/graphql'
import { apolloClient } from '@/vue-apollo'

export default {
  async  getIntents (variables) {
    return apolloClient.query({ query: ALL_INTENTS, variables: variables })
  },
  async createIntent (newIntent) {
    return apolloClient.mutate({
      mutation: CREATE_INTENT, variables: newIntent
    })
  }
}
