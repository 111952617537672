
export const namespaced = true

export const state = {
  submenu: false
}

export const mutations = {
  SET_SUBMENU (state, submenu) {
    state.submenu = submenu
  }
}
