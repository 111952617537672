import userSettingService from '@/services/userSettingService'

export const namespaced = true

export const state = {
  userSetting: {},
  userSettingLoading: false
}

export const getters = {
  userSetting () {
    return state.userSetting
  },
  userSettingLoading () {
    return state.userSettingLoading
  }
}

export const mutations = {
  START_MODIFY_USER_SETTING (state) {
    state.userSettingLoading = true
  },
  MODIFY_USER_SETTING (state, newSetting) {
    state.userSetting = newSetting
    state.userSettingLoading = false
  },
  FETCH_STARTS (state) {
    state.userSettingLoading = true
  },
  FETCH_ENDS (state, userSetting) {
    state.userSetting = userSetting
    state.userSettingLoading = false
  },
  FETCH_ERROR (state) {
    state.userSettingLoading = false
  },
  MODIFY_SETTING_ERROR (state) {
    state.userSettingLoading = false
  }
}

export const actions = {
  async fetchUserSetting ({ commit }) {
    try {
      commit('FETCH_STARTS')
      let response = await userSettingService.getUserSetting()
      if (response) {
        let userSetting = response.data.userSetting
        commit('FETCH_ENDS', userSetting)
        return userSetting
      }
    } catch (error) {
      const errorMessage = `Fail to fetch UserSetting ${error.message}`
      console.log(errorMessage)
      commit('FETCH_ERROR')
    }
  },
  async updateUserSetting ({ commit }, newUserSetting) {
    try {
      commit('START_MODIFY_USER_SETTING')
      let response = await userSettingService.updateUserSetting(newUserSetting)
      if (response) {
        commit('MODIFY_USER_SETTING', response.data.updateUserSetting)
        return response.data.updateUserSetting
      }
    } catch (error) {
      const errorMessage = `Fail to update the default config ${error.message}`
      console.log(errorMessage)
      commit('MODIFY_SETTING_ERROR')
    }
  }
}
