<template>
  <div>
    <v-menu :close-on-click="true" :close-on-content-click="false" offset-y>
      <template #activator="{ on }">
        <v-btn v-on="on" small icon>
          <v-icon>settings</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-switch
            v-model="autoScrollEnabled"
            @change="$emit('autoscroll-enabled', autoScrollEnabled)"
            label="Auto Scroll"
          />
        </v-list-item>
        <v-list-item>
          <v-switch
            v-model="botOnRight"
            @change="$emit('bot-on-right', botOnRight)"
            label="Orientation"
          />
        </v-list-item>
        <v-layout column>
          <slot name="settings" />
        </v-layout>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      autoScrollEnabled: true,
      botOnRight: false
    }
  }
}
</script>

<style scoped></style>
