<template>
  <div class="text-center">
    <v-tooltip left>
      <template #activator="{ on: tooltip }">
        <v-btn
          v-on="{ ...tooltip }"
          @click="toggleChat()"
          fab
          medium
          color="transparent"
          elevation="24"
          class="chatbot-button"
        >
          <v-avatar v-if="!isChatOpen" size="48" class="btn-shadow">
            <img :src="assets.mercedesLogo2" alt="avatar" />
          </v-avatar>
          <v-icon v-else>clear</v-icon>
        </v-btn>
      </template>
      <span> Try out domains in the SDP chatbot </span>
    </v-tooltip>
    <v-snackbar
      v-model="$store.state.isChatOpen"
      :timeout="-1"
      class="chatbot-window"
      rounded="lg"
      right
    >
      <v-layout v-resize="onResize" column>
        <v-layout header align-center>
          <v-flex class="xs12 sm4 ml-3 align-center text-xs-center">
            <h3 class="">{{ title }}</h3>
            <h3 class="">CLOUD</h3>
          </v-flex>
          <v-flex style="margin-top: -12px;" xs12 sm4 ml-2>
            <LanguageSelector
              v-model="requestHeader"
              @input="updateHeader($event)"
              directionProp="top"
            />
            <StageSelector
              :stage="requestHeader.stage"
              @stageSelected="
                $emit('stageSelected', $event);
                setStage($event);
              "
              directionProp="bottom"
              style="margin-top: 1.5em; margin-bottom: 12px;"
            />
          </v-flex>
          <v-spacer />

          <ChatBotSettings
            @autoscroll-enabled="autoScrollEnabled = $event"
            @bot-on-right="botOnRight = $event"
            class="mr-3 mb-4"
          >
            <template #settings>
              <v-list-item @click="$emit('clear-history')">
                Clear Chat History
              </v-list-item>
              <v-list-item>
                <RequestHeaderDetail
                  v-model="requestHeader"
                  label="Edit Request Header"
                  @input="updateHeader($event)"
                />
              </v-list-item>
              <v-list-item>
                <v-switch
                  v-model="dialogSessionEnabled"
                  @change="resetDialogSession()"
                  label="Chat Session"
                />
              </v-list-item>
              <v-list-item>
                <v-btn
                  block
                  :disabled="!dialogSessionSaved"
                  @click="resetDialogSession()"
                >
                  Reset Dialog Session
                </v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn @click="dumpRequestsToConsole()" block>
                  Dump Requests to Console
                </v-btn>
              </v-list-item>
              <v-list-item>
                <ChatBotFullscreen
                  :autoScrollEnabled="autoScrollEnabled"
                  :dialogSessionEnabled="dialogSessionEnabled"
                  :requestHeader="requestHeader"
                  :requestInfo="requestInfo"
                  :textInput="textInput"
                  :messageList="messageList"
                  :messagesPointer="messagesPointer"
                  :sendMessage="sendMessage"
                  :onRequestRecorded="onRequestRecorded"
                  title="#HeyMercedes"
                  style="z-index: 30000;"
                />
              </v-list-item>
            </template>
          </ChatBotSettings>
          <v-btn @click="closeChat()" class="mb-4" medium icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-layout>
        <ChatBotMessages
          :messages="messageList"
          :autoScrollEnabled="autoScrollEnabled"
          :botOnRight="botOnRight"
          :style="
            'width: 400px; height: ' +
              (windowSize.height - 327) +
              'px;margin-left: -16px; margin-right: -24px;'
          "
        />
        <ChatBotInput
          :textInputProp="textInput"
          :messagesProp="messageList"
          :messagesPointerProp="messagesPointer"
          @on-send="sendMessage"
          @request-recorded="onRequestRecorded"
        >
          <template #chat-input-left>
            <v-icon v-if="dialogSessionEnabled" class="mr-4" color="green"
              >cached</v-icon
            >
          </template>
        </ChatBotInput>
      </v-layout>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { clone } from '@/utils'
import ChatBotFullscreen from '@/components/chat/ChatBotFullscreen.vue'
import ChatBotInput from '@/components/chat/ChatBotInput.vue'
import ChatBotMessages from '@/components/chat/ChatBotMessages.vue'
import ChatBotSettings from '@/components/chat/ChatBotSettings.vue'
import LanguageSelector from '@/components/chat/LanguageSelector.vue'
import MvapService from '@/services/MvapService.js'
import RequestHeaderDetail from '@/components/chat/RequestHeaderDetail.vue'
import StageSelector from '@/components/staging/StageSelector.vue'

export default {
  components: {
    ChatBotFullscreen,
    ChatBotInput,
    ChatBotMessages,
    ChatBotSettings,
    LanguageSelector,
    RequestHeaderDetail,
    StageSelector
  },
  props: {
    newVoiceRequest: {},
    title: {
      type: String
    },
    textQueryFromVoice: {},
    value: {
      type: Array
    }
  },
  computed: {
    isChatOpen () {
      return this.$store.state.isChatOpen
    },
    selectedStage () {
      return this.$store.state.stage
    },
    dialogSessionSaved () {
      return this.requestInfo.appserver_data.daimler_third_party
    },
    ...mapState(['assets'])
  },
  data: () => ({
    autoScrollEnabled: true,
    textInput: '',
    messageList: [],
    messagesPointer: 0,
    botOnRight: false,
    dialogSessionEnabled: false,
    requestHeader: {
      language: 'eng-USA',
      stage: 'ece-dev',
      vin: '',
      appId: 'NCS_DEMO_NTG6_JUN-REL_20170601',
      appKey:
        'afab8c46ce22e37b24d37f613931c389ddaa17d36fb6dacc7a9cfba1eb5494935f98255aa1d453679971d5e4f8e7b54bdddf4d2c28d7cc5ac721fcc0ab22b1c4',
      appName: 'OneSpeech_19',
      ntgVersion: 'NTG7-Mid-SOP',
      profileAuth: '',
      position: {
        latitude: '37.386430',
        longitude: '-122.036034'
      }
    },
    requestInfo: {
      appserver_data: {
        content_provider_units: 'METRIC',
        session_state: ''
      }
    },
    windowSize: {
      width: 0,
      height: 0
    }
  }),
  watch: {
    isChatOpen (isChatOpen) {
      if (isChatOpen) {
        this.messageList = this.value
        this.newMessagesCount = 0
      } else {
        this.showTypingIndicator = ''
      }
    },
    newVoiceRequest () {
      this.openChat()
    },
    lastVoiceQuery () {
      this.openChat()
    },
    value (newValue) {
      this.messageList = newValue || []
    },
    dialogSessionEnabled () {
      this.saveChatSettings()
    }
  },
  methods: {
    dumpRequestsToConsole () {
      console.log('request header', this.requestHeader)
      console.log('request info:', this.requestInfo)
    },
    formatRequestHeader (header) {
      header = clone(header)
      header.position = `${header.position.latitude}:${header.position.longitude}`
      header.position = header.position.replace(/undefined/g, '0')

      // @@@johnguy: Getting this to work with getAuthIdFromProfileAuthHeader@Util.java
      header.profileAuth = `acc440ba-e44e-4708-886f-670eef459eb1;dskjfglkadshj;${header.profileAuth}`

      return header
    },
    getFirstAction (fullResponse) {
      if (!fullResponse) {
        return null
      }

      const data = fullResponse['service-provider-recognition-data']
      if (!data) {
        return null
      }

      const nluContent = data['NLU-with-Content']
      const actions = nluContent.appserver_results.payload.actions
      return actions && actions.length ? actions[0] : null
    },
    closeChat () {
      this.$store.state.isChatOpen = false
    },
    updateHeader (data) {
      this.requestHeader = data
      localStorage.requestHeader = JSON.stringify(data)
    },
    setStage (stage) {
      this.requestHeader.stage = stage
      this.updateHeader(this.requestHeader)
    },
    saveChatSettings () {
      let chatSettings = localStorage.chatSettings || {}

      try {
        chatSettings = JSON.parse(chatSettings)
      } catch (err) {
        console.error(err)
        chatSettings = {}
      }

      chatSettings.dialogSessionEnabled = this.dialogSessionEnabled
      localStorage.chatSettings = JSON.stringify(chatSettings)
    },
    loadChatSettings () {
      let chatSettings = localStorage.chatSettings
      if (chatSettings) {
        chatSettings = JSON.parse(chatSettings)
        this.dialogSessionEnabled = !!chatSettings.dialogSessionEnabled
      }
    },
    openChat () {
      this.sendMessage(this.lastVoiceQuery)
      this.$store.state.isChatOpen = true
    },
    toggleChat () {
      const isChatOpen = !this.$store.state.isChatOpen
      this.$store.state.isChatOpen = isChatOpen
    },
    resetDialogSession () {
      if (this.requestInfo.appserver_data.daimler_third_party) {
        delete this.requestInfo.appserver_data.daimler_third_party
        this.requestInfo = clone(this.requestInfo)
      }
    },
    saveDialogSession (fullResponse) {
      if (!this.dialogSessionEnabled) {
        return
      }

      const action = this.getFirstAction(fullResponse)
      if (action && action.dialogstate) {
        if (!this.requestInfo.appserver_data.daimler_third_party) {
          this.requestInfo.appserver_data.daimler_third_party = {}
        }

        const thirdParty = this.requestInfo.appserver_data.daimler_third_party
        if (action.dialogstate.continue) {
          thirdParty.dialogstate = action.dialogstate
        } else {
          delete this.requestInfo.appserver_data.daimler_third_party
        }
      }

      this.requestInfo = clone(this.requestInfo)
    },
    sendMessage (text) {
      if (text) {
        this.newMessagesCount = this.isChatOpen
          ? this.newMessagesCount
          : this.newMessagesCount + 1
        this.onMessageWasSent({
          isYours: true,
          author: 'me',
          type: 'text',
          data: { text }
        })
      }
    },
    sendText (textQuery, timeoutMS) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-unused-vars
        var timeoutPromise = new Promise(function (resolve, reject) {
          let timeout = timeoutMS || 20000
          setTimeout(resolve, timeout, {
            textResponse: 'Sorry! The service is unavailable.',
            fullResponse: {}
          })
        })

        const header = this.formatRequestHeader(this.requestHeader)
        const requestInfo = clone(this.requestInfo)
        console.log('request header', header)
        console.log('request info:', requestInfo)
        // eslint-disable-next-line no-unused-vars
        var actionPromise = new Promise((resolve, reject) => {
          MvapService.performTextRecognition(textQuery, header, requestInfo)
            .then(response => {
              console.log(response)
              resolve({
                textResponse: response.data.text_response,
                fullResponse: response.data.full_response,
                fullRequest: response.data.full_request
              })
            })
            .catch(error => {
              console.error(error)
              reject(error)
            })
        })

        Promise.race([timeoutPromise, actionPromise]).then(function (result) {
          resolve(result)
        })
      })
    },
    readResponse (responseMessage) {
      this.$emit('read-response', responseMessage.data.text)
    },
    switchBotOnRight (newValue) {
      this.botOnRight = newValue
    },
    async onMessageWasSent (message) {
      // called when the user sends a message
      this.messageList = [
        ...this.messageList,
        message,
        { author: 'bot', type: 'busy', data: { text: '...' } }
      ]
      this.$emit('input', this.messageList)
      this.showTypingIndicator = 'bot'
      let requestTimeoutMS = this.requestTimeout
      let { textResponse, fullResponse, fullRequest } = await this.sendText(
        message.data.text,
        requestTimeoutMS
      )
      message.data.request = fullRequest
      this.saveDialogSession(fullResponse)

      this.showTypingIndicator = ''
      this.messageList.pop()
      this.messageList.pop()
      this.messageList = [...this.messageList, message]
      console.log('textResponse:', textResponse)
      let responseMessage = {
        type: 'text',
        author: 'bot',
        data: { text: textResponse, response: fullResponse }
      }

      this.messageList = [...this.messageList, responseMessage]
      this.$emit('input', this.messageList)
      this.readResponse(responseMessage)
    },
    onRequestRecorded (results) {
      console.log('results:', results)
      this.lastVoiceQuery =
        results.sentences && results.sentences.length > 0
          ? results.sentences[results.sentences.length - 1]
          : ''
    },
    onResize () {
      this.windowSize = {
        width: window.innerWidth,
        height: window.innerHeight
      }
    },
    onSend (text) {
      if (text) {
        this.$emit('message-sent', {
          author: 'me',
          type: 'text',
          data: { text }
        })
        this.textInput = ''
      }
    }
  },
  mounted () {
    this.onResize()
    if (localStorage.requestHeader) {
      try {
        this.requestHeader = JSON.parse(localStorage.requestHeader)
      } catch (err) {
        console.error(err)
      }
    }

    this.loadChatSettings()
  }
}
</script>

<style scoped>
.chatbot-button {
  position: fixed;
  right: 2em;
  bottom: 2em;
  z-index: 5;
}

.chatbot-window {
  position: fixed;
  bottom: 48px;
  right: 8px;
}

.btn-shadow {
  box-shadow: 0 3px 3px 0 #8ededd, 0 3px 1px -2px #8ededd, 0 1px 7px 3px #8ededd !important;
}
h3 {
    display: flex;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16.38px;
  }
</style>
