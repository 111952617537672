<template>
  <div>
    <v-list-item
      @click="onClickItem(item)"
      :class="activeSubmenu()">
      <v-list-item-icon>
        <v-icon>
          {{ item.icon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  <v-list-item-group
    class="submenu"
    v-show="submenu">
      <v-list-item
        v-for="(subitem, i) in item.submenu"
        :key="i"
        link
        :to="subitem.path">
        <v-list-item-icon>
          <v-icon>
            {{ subitem.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ subitem.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </div>
</template>

<script>
export default {
  name: 'NavigationSubMenu',
  props: {
    item: Object
  },
  computed: {
    submenu: {
      get () {
        return this.$store.state.settings.submenu
      },
      set (value) {
        this.$store.commit('settings/SET_SUBMENU', value)
      }
    }
  },
  methods: {
    onClickItem (item) {
      this.$store.commit('settings/SET_SUBMENU', true)
      if (item.path && item.path !== this.$route.path) {
        this.$router.push(item.path)
      }
    },
    activeSubmenu () {
      if (this.submenu === true) {
        return 'menu v-list-item--active'
      } else {
        return 'menu'
      }
    }
  }
}
</script>
