import testJobRunHistoryService from '@/services/testJobRunHistoryService.js'
import {
  createLimit,
  createOffset,
  createOrder,
  createTotalPages,
  createCurrentPage
} from './_utilsStore'

export const namespaced = true

export const state = {
  all: [],
  loading: false,
  headers: [
    { text: 'Test Case', value: 'testCase.name', width: '120px' },
    { text: 'Status', value: 'passed', width: '100px' },
    {
      text: 'Language',
      value: 'testCase.language.cerenceCode',
      width: '110px',
      sortable: false
    },
    { text: 'Response Time', value: 'responseTime', width: '90px' },
    {
      text: 'Expected Prompt',
      value: 'testCase.testSteps[0].phrase',
      width: '200px',
      sortable: false
    },
    {
      text: 'Obtained Prompt',
      value: 'transcription',
      width: '200px',
      sortable: false
    },
    { text: 'WER', value: 'wordErrorRate', width: '90px' },
    {
      text: 'Expected Domain',
      value: 'testCase.domain.name',
      width: '100px',
      sortable: false
    },
    {
      text: 'Obtained Domain',
      value: 'domain',
      width: '100px',
      sortable: false
    },
    {
      text: 'Expected Intent',
      value: 'testCase.testSteps[0].intent.name',
      width: '106px',
      sortable: false
    },
    {
      text: 'Obtained Intent',
      value: 'intent',
      width: '106px',
      sortable: false
    },
    { text: 'Voice prompt', value: 'prompt', width: '200px', sortable: false },
    { text: 'Trace ID', value: 'traceId', width: '100px', sortable: false },
    {
      text: 'Pre TTS Text',
      value: 'preTtsText',
      width: '150px',
      sortable: false
    },
    {
      text: 'Expected Audio Data',
      value: 'expectedAudioData',
      width: '170px',
      sortable: false
    },
    { text: 'Audio Data', value: 'audioData', width: '150px', sortable: false }
  ],
  dataTableOptions: {
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: ['testCase.name'],
    sortDesc: ['false'],
    multiSort: false,
    mustSort: true,
    totalItems: 0,
    totalPages: 0
  },
  search: null,
  filter: {
    domain: null,
    intent: null
  }
}

export const mutations = {
  FETCH_STARTS (state) {
    state.all = []
    state.loading = true
  },
  FETCH_ENDS (state, testJobRunHistories) {
    state.all = testJobRunHistories.data
    let paging = testJobRunHistories.meta.paging
    state.dataTableOptions.totalItems = paging.total
    state.dataTableOptions.totalPages = createTotalPages(
      paging.total, paging.limit
    )
    state.dataTableOptions.currentPage = createCurrentPage(
      paging.offset, paging.limit
    )
    state.loading = false
  },
  SET_SEARCH (state, data) {
    state.search = data
  },
  SET_FILTER (state, data) {
    for (let setting in data) {
      state.filter[setting] = data[setting]
    }
  },
  SET_TABLE_OPTIONS (state, data) {
    for (let setting in data) {
      state.dataTableOptions[setting] = data[setting]
    }
  }
}

export const actions = {
  async fetch ({ commit, rootState }) {
    try {
      commit('FETCH_STARTS')
      const filter = {
        testJobHistory: {
          id: rootState.testJobHistory.single.id
        },
        testCase: {
          testStep: {
            intent: { name: state.filter.intent },
            phrase: { contains: state.search }
          },
          domain: {
            name: state.filter.domain
          }
        }
      }
      const {
        data, loading
      } = await testJobRunHistoryService.getTestJobRunHistory(
        {
          limit: await createLimit(state.dataTableOptions),
          offset: await createOffset(state.dataTableOptions),
          filter: filter,
          order: await createOrder(state.dataTableOptions)
        }
      )
      if (loading) {
        commit('FETCH_STARTS')
      }
      if (data) {
        commit('FETCH_ENDS', data.testResults)
        return data.testResults
      }
    } catch (error) {
      console.log(error.response)
    }
  }
}
