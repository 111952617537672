export const namespaced = true

export const state = {
  single: {},
  all: [
    { name: 'CLOUD' },
    { name: 'EMBEDDED' },
    { name: 'HYBRID' }
  ]
}
