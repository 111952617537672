<template>
  <v-layout text-input row pl-4 align-center mt-1>
    <slot name="chat-input-left" />
    <v-text-field
      v-model="textInput"
      @keyup.enter="onSend(textInput)"
      placeholder="Ask #HeyMercedes"
    />
    <v-btn @click="onSend(textInput)" icon>
      <v-icon color="primary">send</v-icon>
    </v-btn>
    <SpeechToText @speechend="speechend"></SpeechToText>
  </v-layout>
</template>

<script>
import SpeechToText from '@/components/SpeechToText.vue'

export default {
  components: {
    SpeechToText
  },
  props: ['textInputProp', 'messagesProp', 'messagesPointerProp'],
  data () {
    return {
      textInput: this.textInputProp,
      messages: this.messagesProp,
      messagesPointer: this.messagesPointerProp
    }
  },
  watch: {
    textInputProp () {
      this.textInput = this.textInputProp
    },
    messagesProp () {
      this.messages = this.messagesProp
    },
    messagesPointerProp () {
      this.messagesPointer = this.messagesPointerProp
    }
  },
  methods: {
    onSend () {
      this.$emit('on-send', this.textInput)
      this.textInput = ''
    },
    speechend (results) {
      this.$emit('request-recorded', results)
      //   this.speechToTextResults = results;
      //   this.newVoiceRequest = results.sentences;
    }
  },
  created () {
    window.addEventListener('keydown', e => {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        let count = 20
        while (
          this.messages.length > 0 &&
          this.messages[this.messagesPointer] &&
          this.messages[this.messagesPointer].author !== 'me' &&
          count !== 0
        ) {
          if (e.key === 'ArrowUp' && this.messagesPointer > 0) {
            this.messagesPointer -= 1
          }
          if (e.key === 'ArrowDown') {
            this.messagesPointer += 1
          }
          count -= 1
        }
        this.textInput =
          this.messagesPointer > 0 &&
          this.messagesPointer < this.messages.length
            ? this.messages[this.messagesPointer].data.text
            : ''
        if (e.key === 'ArrowUp' && this.messagesPointer > 0) {
          this.messagesPointer -= 1
        }
        if (
          e.key === 'ArrowDown' &&
          this.messagesPointer < this.messages.length - 1
        ) {
          this.messagesPointer += 1
        }
      }
    })
  }
}
</script>

<style scoped></style>
