import { apolloClient } from '@/vue-apollo'
import { ALL_TEST_JOB_HISTORY, TEST_JOB_HISTORY } from '../graphql/graphql'

export default {
  async getTestJobHistories (args) {
    let testJobHistories = await apolloClient.query({
      query: ALL_TEST_JOB_HISTORY, variables: args
    })
    return testJobHistories
  },
  async getTestJobHistory (id) {
    let testJobHistory = await apolloClient.query({
      query: TEST_JOB_HISTORY, variables: id
    })
    return testJobHistory
  }
}
