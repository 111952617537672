export const dateTimeFormat = {
  shortNumericDate: {
    day: '2-digit',
    month: '2-digit'
  },
  longNumericDate: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  },
  shortTime: {
    hour: '2-digit',
    minute: '2-digit'
  },
  longTime: {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  }
}

export const location = 'de-DE'
