import { ALL_DOMAINS, CREATE_DOMAIN } from '../graphql/graphql'
import { apolloClient } from '@/vue-apollo'

export default {
  async  getDomains () {
    return apolloClient.query({ query: ALL_DOMAINS })
  },
  async createDomain (newDomain) {
    return apolloClient.mutate({
      mutation: CREATE_DOMAIN, variables: newDomain
    })
  }
}
