export const namespaced = true

export const state = {
  all: [
    'TEXT',
    'SPEECH',
    'STREAM',
    'DICTATION'
  ]
}
