<template>
  <v-dialog v-model="dialog" persistent max-width="450px">
    <template v-slot:activator="{ on: dialog , attrs}">
      <v-tooltip
        bottom
        v-if=!!defaultConfig
        open-delay="1000">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            icon
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            class="mr-1">
            <v-icon>
              mdi-settings
            </v-icon>
          </v-btn>
        </template>
        <span>Select a configuration for a test run.</span><br/>
        <span>Currently selected: {{ configuration }}</span>
      </v-tooltip>
      <v-tooltip
        bottom
        v-else
        open-delay="1000">
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            color="error"
            v-on="{ ...tooltip, ...dialog }"
            icon>
            <v-icon>
              mdi-settings
            </v-icon>
          </v-btn>
        </template>
        <span>Select a configuration for a test run.</span><br/>
        <span>Currently none is selected.</span>
      </v-tooltip>
    </template>
    <v-card v-if="dialog">
      <v-card-title>
        <span v-if="!!defaultConfig" class="headline">
          Edit Default Config
        </span>
        <span v-else class="headline">Set Default Config</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout wrap>
            <v-flex>
              <v-combobox
                class="mr-2"
                v-model="config"
                :items="configs"
                label="Configuration"
                item-text="text"
                auto-select-first
                required
                return-object>
              </v-combobox>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
          text
          :loading="userSettingLoading"
          @click="saveDefaultConfig"
          color="primary">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'DefaultConfigDialog',
  props: { configuration: String },
  data () {
    return {
      dialog: false,
      valid: false,
      config: {
        text: '',
        value: ''
      },
      defaultValue: {
        text: '',
        value: ''
      },
      nameRules: [(v) => !!v || 'Config is required']
    }
  },
  computed: {
    defaultConfig () {
      return !!this.userSetting.defaultConfiguration
    },
    configs () {
      if (this.configurationLoading) return []
      return this.configurations.map((x) => {
        return { value: x.id, text: x.name }
      })
    },
    ...mapState('configuration', ['configurations', 'configurationLoading']),
    ...mapState('userSetting', ['userSetting', 'userSettingLoading'])
  },
  watch: {
    dialog (newValue) {
      if (!newValue) return {}
      if (newValue) {
        if (this.userSetting.defaultConfiguration) {
          this.config.text = this.userSetting.defaultConfiguration.name
          this.config.value = this.userSetting.defaultConfiguration.id
        }
      }
    }
  },
  methods: {
    async saveDefaultConfig () {
      let newDefaultConfig = {
        data: {
          defaultConfigurationId: this.config.value
        }
      }
      await this.updateUserSetting(newDefaultConfig)
      this.closeDialog()
    },
    closeDialog () {
      this.$refs.form.reset()
      this.dialog = false
      this.config = Object.assign({}, this.defaultValue)
    },
    ...mapActions('userSetting', ['updateUserSetting', 'fetchUserSetting'])
  }
}
</script>
