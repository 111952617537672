<template>
  <v-app-bar
    app
    clipped-left>
    <v-app-bar-nav-icon
      @click.stop="switchDrawerState('left')"
      v-if="!home"/>
    <router-link :to="{ name: 'dashboard' }" class="brand">
      <img :src="assets.mercedesLogo2" class="brand-icon mr-8" alt="Mercedes" />
    </router-link>
    <v-toolbar-title>TAP - #HeyMercedes dev</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items
      v-if="!home">
        <UserProfile
          @switch-show-chat="switchShowChat"
          @switch-show-tts="switchShowTts" />
      </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UserProfile from '@/components/header/UserProfile'
export default {
  name: 'Header',
  components: {
    UserProfile
  },
  props: {
    home: Boolean
  },
  data () {
    return {
      showChat: true
    }
  },
  computed: {
    ...mapState(['assets'])
  },
  methods: {
    switchShowChat (value) {
      this.showChat = value
      this.$emit('switch-show-chat', this.showChat)
    },
    switchShowTts (value) {
      this.showTts = value
    },
    ...mapActions(['switchDrawerState'])
  }
}
</script>

<style scoped>
.brand-icon {
  margin-left: 25px;
  width: 32px;
  height: 32px;
  display: block;
}
</style>
