import testjobService from '@/services/testjobService.js'
import {
  createLimit,
  createOffset,
  createOrder,
  createTotalPages,
  createCurrentPage
} from './_utilsStore'

export const namespaced = true

export const state = {
  all: [],
  single: {},
  loading: false,
  busyTestJobs: [],
  testJobResults: {},
  testJobStatus: {},
  totalTestJob: 0,
  pagination: {
    total: 0
  },
  search: null,
  headers: [
    { text: 'ID', value: 'id', width: '65px' },
    { text: 'Test Job', value: 'name' },
    { text: 'Configuration', value: 'configuration.name', width: '130px' },
    { text: 'Repeat', value: 'repeat', width: '100px' },
    { text: 'Days', value: 'weekDays', width: '80px' },
    { text: 'Time(GMT)', value: 'executionTime', width: '120px' },
    { text: 'Last Run', value: 'lastRun', width: '129px', sortable: false },
    { text: 'Status', value: 'result', width: '137px', sortable: false },
    { text: 'Actions', value: 'action', sortable: false, width: '150px' },
    { value: 'data-table-expand', width: '56px' }
  ],
  dataTableOptions: {
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: ['id'],
    sortDesc: ['false'],
    multiSort: false,
    mustSort: true,
    totalItems: 0,
    totalPages: 0
  }
}

export const getters = {
  getTJById: state => id => {
    return state.all.filter(
      testjob => testjob.id === id
    )[0]
  }
}

export const mutations = {
  ADD_TJ (state, testjob) {
    state.all = [ testjob, ...state.all ]
    state.pagination.total += 1
  },
  SET_TJ (state, testjob) {
    state.single = testjob
  },
  FETCH_STARTS (state) {
    state.loading = true
  },
  FETCH_ENDS (state, testJobs) {
    state.all = testJobs.data
    let paging = testJobs.meta.paging
    state.dataTableOptions.totalItems = paging.total
    state.dataTableOptions.totalPages = createTotalPages(
      paging.total, paging.limit
    )
    state.dataTableOptions.currentPage = createCurrentPage(
      paging.offset, paging.limit
    )
    state.loading = false
  },
  DELETE_TJ (state, tjToDelete) {
    state.all = state.all.filter(
      testjob => testjob.id !== tjToDelete.id
    )
  },
  DELETE_TJ_TC (state, args) {
    let index = -1
    let testCaseIds = args.testCases.map(testCase => testCase.id)
    index = state.all.findIndex(testjob => testjob.id === args.id)
    for (let i = 0; i <= testCaseIds.length; i++) {
      state.all[index].testCases = state.all[index].testCases.filter(
        testCase => testCase.id !== testCaseIds[i])
    }
  },
  UPDATE_TJ (state, updatedTJ) {
    let index = state.all.findIndex(
      testjob => testjob.id === updatedTJ.id
    )
    state.all = [
      ...state.all.slice(0, index),
      updatedTJ,
      ...state.all.slice(index + 1)]
  },
  ADD_TJ_TC (state, args) {
    let index = -1
    index = state.all.findIndex(testjob => testjob.id === args.id)
    state.all[index].testCases.concat(args.testCases)
  },
  ADD_BUSY_TEST_JOB (state, testJob) {
    state.busyTestJobs.push(testJob)
  },
  REMOVE_BUSY_TEST_JOB (state, testJob) {
    state.busyTestJobs = state.busyTestJobs.filter(tj => tj.id !== testJob.id)
  },
  UPDATE_TEST_JOB_RESULTS (state, { testJob, result }) {
    state.testJobResults[testJob.id] = result
    state.testJobStatus[testJob.id] = result.every(x => x.passed === true)
    state.testJobResults = JSON.parse(JSON.stringify(state.testJobResults))
    state.testJobStatus = JSON.parse(JSON.stringify(state.testJobStatus))
  },
  SET_META (state, { paging: { total } }) {
    state.totalTestJob = total
    state.loading = false
  },
  SET_TABLE_OPTIONS (state, data) {
    for (let setting in data) {
      state.dataTableOptions[setting] = data[setting]
    }
  }
}

export const actions = {
  async fetch ({ commit }) {
    try {
      commit('FETCH_STARTS')
      const filter = {
        name: {
          contains: state.search
        },
        delete: false
      }
      const { data, loading } = await testjobService.getTJs({
        limit: await createLimit(state.dataTableOptions),
        offset: await createOffset(state.dataTableOptions),
        filter: filter,
        order: await createOrder(state.dataTableOptions)
      })
      if (loading) {
        commit('FETCH_STARTS')
      }
      if (data) {
        commit('FETCH_ENDS', data.testJobs)
        return data.testJobs
      }
    } catch (error) {
      const errorMessage = 'Error while fetching test job: ' + error.message
      console.log(errorMessage)
      console.log(error.response)
    }
  },
  async fetchSingle ({ commit }, id) {
    try {
      const response = await testjobService.getTJ(id)
      if (response) {
        commit('UPDATE_TJ', response.data.testJob)
        return response.data.testJob
      }
      return false
    } catch (error) {
      const errorMessage = 'Error while fetching test job: ' + error.message
      console.error(error)
      console.error(errorMessage)
    }
  },
  async create ({ commit }, newTJ) {
    const response = await testjobService.createTestJob(newTJ)
    commit('ADD_TJ', response.data.createTestJob)
  },
  async update ({ commit }, args) {
    try {
      const { data } = await testjobService.updateTestJob(args)
      commit('UPDATE_TJ', data.updateTestJob)
      return data.updateTestJob
    } catch (error) {
      const errorMessage = 'Error while updating test job: ' + error.message
      console.error(error)
      console.error(errorMessage)
    }
  },
  async pause ({ commit }, args) {
    try {
      const response = await testjobService.pauseTestJob(args)
      commit('UPDATE_TJ', response.data.pauseTestJob)
      return response.data.playTestJob
    } catch (error) {
      const errorMessage = 'Error while updating test job: ' + error.message
      console.error(error)
      console.error(errorMessage)
    }
  },
  async resume ({ commit }, args) {
    try {
      const response = await testjobService.resumeTestJob(args)
      commit('UPDATE_TJ', response.data.resumeTestJob)
      return response.data.resumeTestJob
    } catch (error) {
      const errorMessage = 'Error while updating test job: ' + error.message
      console.error(error)
      console.error(errorMessage)
    }
  },
  async delete ({ state }, id) {
    try {
      const response = await testjobService.deleteTestJob({
        id: id
      })
      console.log('TestJob deleted: ' + id)
      return response.data.deleteTestJob
    } catch (error) {
      console.error(error)
    }
  },
  async addTo ({ commit }, args) {
    return testjobService.addTJ_TC({ data: args }).then(response => {
      commit('ADD_TJ_TC', response.data.addTestCaseToTestJob)
      return response.data.addTestCaseToTestJob
    })
  },
  async removeFrom ({ commit }, args) {
    return testjobService.deleteTJ_TC({ data: args }).then(response => {
      commit('DELETE_TJ_TC', response.data.deleteTestCaseFromTestJob)
      return response.data.deleteTestCaseFromTestJob
    })
  },
  play ({ commit }, testJob) {
    commit('ADD_BUSY_TEST_JOB', testJob)
    return new Promise(function (resolve, reject) {
      testjobService.playTestJob({
        data: {
          testJobId: testJob.id
        }
      }).then(
        response => {
          commit('UPDATE_TEST_JOB_RESULTS', {
            testJob,
            result: response.data.playTestJob
          })
          resolve(response)
        }
      ).catch(error => {
        console.error(error)
        reject(error)
      }).finally(() => {
        commit('REMOVE_BUSY_TEST_JOB', testJob)
      })
    })
  },
  async fetchMeta ({ commit }) {
    try {
      commit('FETCH_STARTS')
      const { data: { testJobs: { meta } } } = await testjobService.getTJs(
        { limit: 0, offset: 0, filter: { delete: false } }
      )
      commit('SET_META', meta)
    } catch (error) {
      const errorMessage = 'Error while fetching cars: ' + error.message
      console.error(errorMessage)
      console.error(error)
    }
  }
}
