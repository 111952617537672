import testResultService from '@/services/testresultService.js'
import {
  createLimit,
  createOffset,
  createOrder,
  createTotalPages,
  createCurrentPage
} from './_utilsStore'

export const namespaced = true

export const state = {
  all: [],
  loading: false,
  headers: [
    { text: 'ID ', value: 'id', width: '65px' },
    { text: 'TestCase', value: 'testCase.name', width: '150px' },
    { text: 'Status', value: 'passed', width: '92px' },
    { text: 'Domain', value: 'domain', width: '95px' },
    { text: 'Intent', value: 'intent', width: '100px' },
    { text: 'WER', value: 'wordErrorRate', width: '80px' },
    { text: 'Prompt', value: 'prompt', sortable: false },
    { text: 'Latency', value: 'latency', width: '93px' },
    { text: 'Executed on (GMT)', value: 'createdAt', width: '154px' },
    { text: 'Actions', value: 'action', sortable: false, width: '74px' }
  ],
  dataTableOptions: {
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: ['id'],
    sortDesc: ['false'],
    multiSort: false,
    mustSort: true,
    totalItems: 0,
    totalPages: 0,
    footerProps: { 'items-per-page-options': [5, 10, 25, 50, -1] }
  },
  search: null,
  testJobHistoryID: null
}

export const mutations = {
  FETCH_STARTS (state) {
    state.all = []
    state.loading = true
  },
  FETCH_ENDS (state, testResults) {
    state.all = testResults.data
    let paging = testResults.meta.paging
    state.dataTableOptions.totalItems = paging.total
    state.dataTableOptions.totalPages = createTotalPages(
      paging.total, paging.limit
    )
    state.dataTableOptions.currentPage = createCurrentPage(
      paging.offset, paging.limit
    )
    state.loading = false
  },

  SET_SEARCH (state, data) {
    state.search = data
  },
  SET_FILTER (state, data) {
    for (let setting in data) {
      state.filter[setting] = data[setting]
    }
  },
  SET_TABLE_OPTIONS (state, data) {
    for (let setting in data) {
      state.dataTableOptions[setting] = data[setting]
    }
  },
  SET_TESTJOBHISTORYID (state, data) {
    state.testJobHistoryID = data
  },
  CLEAR_ALL (state) {
    state.all = []
    state.testJobHistoryID = null
  }
}

export const actions = {
  async fetch ({ commit }) {
    try {
      commit('FETCH_STARTS')
      const filter = {
        testCase: {
          name: {
            contains: state.search
          }
        },
        testJobHistory: {
          id: state.testJobHistoryID
        }
      }
      const { data, loading } = await testResultService.getTestResults({
        limit: await createLimit(state.dataTableOptions),
        offset: await createOffset(state.dataTableOptions),
        filter: filter,
        order: await createOrder(state.dataTableOptions)
      })
      if (loading) {
        commit('FETCH_STARTS')
      }
      if (data) {
        commit('FETCH_ENDS', data.testResults)
        return data.testResults
      }
    } catch (error) {
      const errorMessage = 'Error while fetching testresult: ' + error.message
      console.log(errorMessage)
      console.log(error.response.data)
    }
  },
  async delete ({ state }, id) {
    try {
      const response = await testResultService.deleteTestResult({
        id: id
      })
      console.log('TestResult deleted: ' + id)
      return response.data.deleteTestResult
    } catch (error) {
      console.error(error)
    }
  },
  bulkDeleteTestResults ({ commit }, ids) {
    return new Promise((resolve, reject) => {
      testResultService.bulkDeleteTestResults(ids)
        .then(response => {
          commit('DELETE_TEST_RESULTS', response.data.deleteTestResults)
          resolve(response)
        })
        .catch(error => {
          console.error(error)
          reject(error)
        })
    })
  }
}
