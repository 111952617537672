<template>
  <v-container>
    <h1>Getting Started!</h1>
    <v-layout justify-center row wrap>
      <template v-for="(item, index) in items">
        <v-flex :key="index" md4 pl-2 pr-2>
          <v-card hover :href="item.link" target="_blank" class="card -shadow">
            <v-card-title class="headline mb-1">{{ item.title }}</v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="item.text">{{ item.text }}</v-card-text>
          </v-card>
        </v-flex>
      </template>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    items: [
      {
        title: 'Getting started',
        text:
          'Get started with the Test Automation Platform! Learn the basic' +
          'concepts and create and execute your first Tests.',
        link:
          'https://gsep.daimler.com/confluence/display/MVAP/Getting+Started'
      }
    ]
  })
}
</script>
