import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store/store'
import { convertDateTime } from './utils'

import { createProvider } from './vue-apollo'

Vue.config.productionTip = false
Vue.filter('convertDateTime', value => {
  if (value) {
    return convertDateTime(value, 'longNumericDate', 'longTime')
  }
})

new Vue({
  render: h => h(App),
  apolloProvider: createProvider(),
  router,
  vuetify,
  store
}).$mount('#app')
