import { makeClient } from './client'

const apiClient = makeClient('/assistants/mvap')

export default {
  performTextRecognition (textQuery, header, requestInfo) {
    return apiClient.post('/text-request', {
      text_query: textQuery,
      requestInfo,
      header
    })
  }
}
