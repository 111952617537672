<template>
  <v-card class="ma-3">
    <v-data-table
      :loading="loading"
      loading-text="Test Jobs Loading..."
      v-model="selected"
      :headers="headers"
      :items="items"
      show-expand
      single-expand
      :expanded="expanded"
      @click:row="expandColumn"
      hide-default-footer
      :options.sync="dataTableOptions"
      :server-items-length="dataTableOptions.totalItems">
      <template v-slot:[`item.repeat`]="{ item }">
        {{ item.repeat.slice(0, 1) + item.repeat.slice(1).toLowerCase() }}
      </template>
      <template v-slot:[`item.weekDays`]="{ item }">
        {{ days(item.weekDays) }}
      </template>
      <template v-slot:[`item.executionTime`]="{ item }">
        {{ item.executionTime.slice(0, 5) }}
      </template>
      <template v-slot:top>
        <v-toolbar>
          <v-toolbar-title>
            <h1 class="text-h6">
              <v-icon class="mr-2">
                mdi-clock-outline
              </v-icon>
              Schedule
            </h1>
          </v-toolbar-title>
          <v-spacer />
          <v-text-field
            v-model="search"
            label="Search Name"
            prepend-inner-icon="search"
            outlined
            clearable
            hide-details
            dense />
        </v-toolbar>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <TestJobDialog :testJob="item" />
        <v-tooltip
          bottom
          open-delay="1000">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              small
              v-on="on">
              <v-icon
                small
                v-if="!busyTestJobs.includes(item)"
                :disabled="item.length === 0 || !item.id"
                @click.stop="playTestJob(item)">
                mdi-run
              </v-icon>
              <v-progress-circular
                v-else
                :size="15"
                :width="2"
                indeterminate />
            </v-btn>
          </template>
          <span>Run Test Job</span>
        </v-tooltip>
        <v-tooltip
          bottom
          open-delay="1000"
          v-if="!item.pause">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on">
              <v-icon
                small
                @click.stop="pauseTestJob(item)">
                mdi-pause
              </v-icon>
            </v-btn>
          </template>
          <span>Pause Test Job</span>
        </v-tooltip>
        <v-tooltip
          bottom
          open-delay="1000"
          v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on">
                <v-icon
                small
                @click.stop="resumeTestJob(item)">
                mdi-play
              </v-icon>
            </v-btn>
          </template>
          <span>Resume Test Job</span>
        </v-tooltip>
        <DeleteDialog store="testJob" :item="item" />
      </template>
      <template v-slot:[`item.result`]="{ item }">
        <v-chip
          outlined
          v-if="busy(item)">
          RUNNING
        </v-chip>
        <v-chip
          outlined
          v-else-if="pause(item)">
          PAUSED
        </v-chip>
        <v-chip
          outlined
          v-else-if="getTestJobHistory(item, true) === 'FINISHED'"
          color="success">
          {{ getTestJobHistory(item, true) }}
        </v-chip>
        <v-chip
          outlined
          v-else-if="getTestJobHistory(item, true) === 'FAILED'"
          color="error">
          {{ getTestJobHistory(item, true) }}
        </v-chip>
        <v-chip
          outlined
          v-else>
          SCHEDULED
        </v-chip>
      </template>
      <template v-slot:[`item.lastRun`]="{ item }">
        <v-chip
          outlined
          v-if="getLastRun(item) === 'N/A'">
          {{ getLastRun(item) }}
        </v-chip>
        <v-chip
          outlined
          v-else
          @click="openDetails(getTestJobHistory(item))">
          {{ getLastRun(item) }}
        </v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-card flat class="mx-auto">
            <v-subheader v-if="item.testCases.length > 0">
              Test Cases
            </v-subheader>
            <v-subheader v-else class="warning">
              No Test Cases Available
              </v-subheader>
            <v-list dense>
              <template v-for="testCase in item.testCases">
                <v-list-item :key="'testCase' + testCase.id">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ testCase.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  :key="testCase.id"
                  v-if="item.testCases.indexOf(testCase) < item.testCases.length - 1">
                </v-divider>
              </template>
            </v-list>
          </v-card>
        </td>
      </template>
    </v-data-table>
    <Pagination type="testJob" />
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TestJobDialog from '@/components/dialogs/TestJob'
import Pagination from '@/components/fragments/Pagination'
import DeleteDialog from '@/components/dialogs/Delete'
import { convertDateTime } from '../../utils'

export default {
  name: 'TestJobDataTable',
  components: {
    TestJobDialog,
    Pagination,
    DeleteDialog
  },
  data () {
    return {
      keywords: [],
      deleteLoader: -1,
      selected: [],
      expanded: [],
      weekDayList: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    }
  },
  computed: {
    headers () {
      return this.$store.state.testJob.headers
    },
    dataTableOptions: {
      get () {
        return this.$store.state.testJob.dataTableOptions
      },
      set (value) {
        this.$store.commit('testJob/SET_TABLE_OPTIONS', value)
        this.fetchTestJobs()
      }
    },
    items: {
      get () {
        return this.$store.state.testJob.all
      },
      set () {
        console.log('set TestJob')
      }
    },
    search: {
      get () {
        return this.$store.state.testJobHistory.search
      },
      set (value) {
        this.$store.commit('testJobHistory/SET_SEARCH', value)
        this.fetchTestJobs()
      }
    },
    busy () {
      return testJob => !!this.busyTestJobs.find((t) => t.id === testJob.id)
    },
    pause () {
      return testJob => !!testJob.pause
    },
    passedColor () {
      return 'success'
    },
    failedColor () {
      return 'error'
    },
    warningColor () {
      return 'warning'
    },
    ...mapState('testJob', [
      'busyTestJobs',
      'loading',
      'pagination'
    ]),
    ...mapState(
      'testJobHistory', [
        'all',
        'single'
      ]
    )
  },
  methods: {
    expandColumn (value) {
      if (this.expanded.length > 0) {
        if (this.expanded[0].id === value.id) {
          this.expanded = []
        } else {
          this.expanded = []
          this.expanded.push(value)
        }
      } else {
        this.expanded.push(value)
      }
    },
    getTestJobHistory (item, status) {
      for (let i = this.all.length - 1; i >= 0; i--) {
        if (item.id === this.all[i].testJob.id) {
          if (status === true) {
            return this.all[i].status
          } else {
            return this.all[i]
          }
        }
      }
    },
    getLastRun (item) {
      const testJobHistory = this.getTestJobHistory(item, false)
      if (testJobHistory) {
        if (testJobHistory.executionEnd) {
          return convertDateTime(
            testJobHistory.executionEnd, 'longNumericDate'
          )
        } else {
          return 'N/A'
        }
      } else {
        return 'N/A'
      }
    },
    async pauseTestJob (item) {
      if (item) {
        await this.pauseTJ({ id: item.id })
      }
    },
    async resumeTestJob (item) {
      if (item) {
        await this.resumTestJob({ id: item.id })
      }
    },
    async deleteTestJob (item) {
      if (item) {
        if (confirm('Do you really want to delete?')) {
          await this.deleteTestJob({ id: item.id })
          await this.fetchTestJobs()
        }
      }
    },
    days (items) {
      if (items.length === 7) {
        return 'Daily'
      } else {
        return this.weekDayList.filter(
          (i, e) => { return items.includes(e) }
        ).join()
      }
    },
    openDetails (testJob) {
      this.$router.push({
        name: 'TestJobRunHistory',
        params: { id: testJob.id }
      })
    },
    ...mapActions('testJob', {
      fetchTestJobs: 'fetch',
      deleteTestJob: 'delete',
      playTestJob: 'play',
      pauseTJ: 'pause',
      resumeTestJob: 'resume'
    }),
    ...mapActions('testJobHistory', { fetchTestJobHistories: 'fetch' })
  },
  async mounted () {
    await this.fetchTestJobs()
    await this.fetchTestJobHistories(false)
  }
}
</script>
