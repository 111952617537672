<template>
  <v-dialog v-model="dialog" max-width="65%" max-height="60%" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
       <v-tooltip
          v-if="!!testJob"
          bottom
          open-delay="1000">
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="{ ...dialog, ...tooltip }">
              <v-icon small>
                edit
              </v-icon>
            </v-btn>
          </template>
          <span>Create Test Schedule</span>
        </v-tooltip>
      <v-btn
        v-else
        outlined
        :disabled="testCasesIds.length == 0"
        color="primary"
        v-on="{ ...dialog}"
        v-bind="attrs"
        class="mr-2"
        style="height:40px">
        Create Schedule
      </v-btn>
    </template>
    <v-card v-if="dialog">
      <v-card-title>
        <h2 v-if="!!testJob">Edit Test Schedule</h2>
        <h2 v-else>Create Schedule</h2>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-layout wrap>
            <v-flex md6>
              <v-text-field
                class="mr-2"
                v-model="editedItem.name"
                label="Name (*)"
                :rules="nameRules"
                required>
              </v-text-field>
            </v-flex>
            <v-flex md6>
              <v-combobox
                class="mr-2"
                v-model="editedItem.config"
                :items="configs"
                label="Configuration (*)"
                item-text="text"
                :rules="configRules"
                auto-select-first
                required
                return-object
              ></v-combobox>
            </v-flex>
          </v-layout>
          <h4 class="text-left">Select days</h4>
          <v-card-actions>
            <v-chip
              active-class="primary--text"
              v-model="isEveryDay"
              @click="toggleSelection"
              class="mr-3"
            >Daily </v-chip>
            <v-chip-group
              mandatory
              v-model="selectedDays"
              active-class="primary--text"
              column
              multiple>
              <v-chip v-for="day in days" :key="day">
                {{ day }}
              </v-chip>
            </v-chip-group>
            <v-divider vertical class="ma-4" />
            <v-chip-group
              mandatory
              v-model="selectedRepeat"
              active-class="primary--text"
              column>
              <v-chip v-for="repeat in repeats" :key="repeat">
                {{ repeat }}
              </v-chip>
            </v-chip-group>
          </v-card-actions>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="time"
                label="Select Time in GMT"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on">
              </v-text-field>
            </template>
            <v-time-picker
              v-if="menu"
              v-model="time"
              full-width
              @click:minute="$refs.menu.save(time)">
            </v-time-picker>
          </v-menu>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
          text
          :disabled="!valid"
          :loading="testJobLoading"
          @click="saveTestJob"
          color="primary">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'CreateEditTestJobDialog',
  props: {
    testCasesIds: {
      type: Array,
      default: () => []
    },
    testJob: Object
  },
  data () {
    return {
      time: null,
      menu: false,
      days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      selectedDays: [0],
      repeats: ['Weekly', 'Bi-Weekly'],
      selectedRepeat: 0,
      dialog: false,
      valid: false,
      repeat: null,
      editedItem: {
        name: '',
        schedule: '',
        testCases: [],
        config: {
          text: '',
          value: ''
        }
      },
      editedIndex: -1,
      nameRules: [(v) => !!v || 'Name is required'],
      configRules: [(v) => !!v || 'Configuration is required']
    }
  },
  computed: {
    configs () {
      if (this.configurationLoading) return []
      return this.configurations.map((x) => {
        return { value: x.id, text: x.name }
      })
    },
    isEveryDay () {
      return this.selectedDays.length === 7
    },
    ...mapState('configuration', {
      configurationLoading: 'loading',
      configurations: 'all'
    }),
    ...mapState('testJob', { testJobLoading: 'loading' })
  },
  watch: {
    dialog (newValue) {
      if (newValue) {
        this.loadTestCaseIds()
        this.loadTestJob()
      }
    }
  },
  methods: {
    toggleSelection () {
      if (this.selectedDays.length === 7) {
        this.selectedDays = [0]
      } else {
        this.selectedDays = [0, 1, 2, 3, 4, 5, 6]
      }
    },
    loadTestCaseIds () {
      this.editedItem.testCases = this.testCasesIds.map((x) => {
        return { value: x.id, text: x.name }
      })
    },
    loadTestJob () {
      if (this.testJob) {
        this.editedItem.id = this.testJob.id
        this.editedItem.name = this.testJob.name
        if (!this.editedItem.config) {
          this.editedItem.config = {}
        }
        this.editedItem.config.value = this.testJob.configuration.id
        this.editedItem.config.text = this.testJob.configuration.name
        this.selectedDays = this.testJob.weekDays
        this.selectedRepeat = this.testJob.repeat === 'WEEKLY' ? 0 : 1
        this.time = this.testJob.executionTime.slice(0, 5)
      }
    },
    async saveTestJob () {
      try {
        if (this.testJob) {
          let testCaseIds = this.testJob.testCases.map(item => item.id)
          let updatedTJ = {
            id: this.testJob.id,
            data: {
              name: this.editedItem.name,
              weekDays: this.selectedDays,
              repeat: this.selectedRepeat === 0 ? 'WEEKLY' : 'BIWEEKLY',
              testCases: testCaseIds,
              executionTime: this.time,
              configurationId: this.editedItem.config.value
            }
          }
          await this.updateTestJob(updatedTJ)
          const updatedTestJob = await this.fetchTestJob({
            id: this.testJob.id
          })
          this.editedItem.name = updatedTestJob.name
          this.selectedDays = updatedTestJob.weekDays
          this.selectedRepeat = updatedTestJob.repeat
          this.time = updatedTestJob.executionTime
          this.closeDialog()
        } else {
          let testCaseIds = this.editedItem.testCases.map(item => item.value)
          let newTestJob = {
            data: {
              name: this.editedItem.name,
              weekDays: this.selectedDays,
              repeat: this.selectedRepeat === 0 ? 'WEEKLY' : 'BIWEEKLY',
              testCases: testCaseIds,
              executionTime: this.time,
              configurationId: this.editedItem.config.value
            }
          }
          await this.createTestJob(newTestJob)
          this.closeDialog()
          this.$router.push({ path: 'TestJob' })
        }
      } catch (err) {
        console.log(err)
      }
    },
    closeDialog () {
      this.$refs.form.reset()
      this.dialog = false
      this.$emit('testcase-deselect-rows')
    },
    ...mapActions('testJob', {
      fetchTestJobs: 'fetch',
      fetchTestJob: 'fetchSingle',
      createTestJob: 'create',
      updateTestJob: 'update'
    })
  }
}
</script>
