import slotService from '@/services/slotService.js'

export const namespaced = true

export const state = {
  slot: {},
  slots: [],
  slotLoading: false
}

export const getters = {
  slots () {
    return state.slots
  },
  slotLoading () {
    return state.slotLoading
  }
}

export const mutations = {
  ADD_SLOT (state, slot) {
    state.slots.push(slot)
  },
  FETCH_STARTS (state) {
    state.slotLoading = true
  },
  FETCH_ENDS (state, slots) {
    state.slots = slots
    state.slotLoading = false
  }
}

export const actions = {
  fetchSlots ({ commit }) {
    commit('FETCH_STARTS')
    slotService.getSlots().then(
      response => {
        let { data } = response.data.slots
        commit('FETCH_ENDS', data)
      })
      .catch(error => {
        const errorMessage = 'Error while fetching slotS: ' + error.message
        console.log(errorMessage)
        console.log(error.response.data)
      })
  },
  createSlot ({ commit }, newSlot) {
    return new Promise(function (resolve, reject) {
      slotService.createSlot(newSlot).then(
        response => {
          commit('ADD_SLOT', response.data.createSlot)
        }
      )
    })
  }
}
