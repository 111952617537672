import systemundertestService from '@/services/systemundertestService.js'
import {
  createLimit,
  createOffset,
  createOrder,
  createTotalPages,
  createCurrentPage
} from './_utilsStore'

export const namespaced = true

export const state = {
  all: [],
  single: {},
  loading: false,
  dataTableOptions: {
    currentPage: 1,
    itemsPerPage: 10,
    sortBy: ['name'],
    sortDesc: ['false'],
    multiSort: false,
    mustSort: true,
    totalItems: 0,
    totalPages: 0
  },
  headers: [
    { text: 'ID', value: 'id', width: '65px' },
    { text: 'Name', value: 'name' },
    { text: 'Actions', value: 'actions', sortable: false, width: '90px' }
  ],
  editedItem: [],
  default: {
    name: ''
  }
}

export const mutations = {
  FETCH_STARTS (state) {
    state.all = []
  },
  FETCH_ENDS (state, data) {
    state.all = data.data
    let paging = data.meta.paging
    state.dataTableOptions.totalItems = paging.total
    state.dataTableOptions.totalPages = createTotalPages(
      paging.total, paging.limit
    )
    state.dataTableOptions.currentPage = createCurrentPage(
      paging.offset, paging.limit
    )
    state.loading = false
  },
  SET_EDITED (state, id) {
    let toEdit = state.all.findIndex(obj => obj.id === id)
    let copied = JSON.parse(
      JSON.stringify(state.all[toEdit])
    )
    state.editedItem = [
      copied
    ]
  },
  SET_EDITED_FIELD (state, data) {
    for (let [field, value] of Object.entries(data.data)) {
      state.editedItem[0][field] = value
    }
  },
  SET_DEFAULT (state) {
    state.editedItem = [state.default]
  },
  SET_TABLE_OPTIONS (state, data) {
    for (let setting in data) {
      state.dataTableOptions[setting] = data[setting]
    }
  },
  SET_SEARCH (state, data) {
    state.search = data
  }
}

export const actions = {
  async fetch ({ commit }) {
    try {
      commit('FETCH_STARTS')
      const filter = {
        name: {
          contains: state.search
        }
      }
      const { data, loading } = await systemundertestService.getSUTs({
        limit: await createLimit(state.dataTableOptions),
        offset: await createOffset(state.dataTableOptions),
        filter: filter,
        order: await createOrder(state.dataTableOptions)
      })
      if (loading) {
        commit('FETCH_STARTS')
      }
      if (data) {
        commit('FETCH_ENDS', data.systemUnderTests)
        return data.systemUnderTests
      }
    } catch (error) {
      const errorMessage = 'Error while fetching cars: ' + error.message
      console.error(errorMessage)
      console.error(error)
    }
  },
  async delete ({ state }, id) {
    try {
      const response = await systemundertestService.deleteSystemUnderTest({
        id: id
      })
      console.log('System Under Test deleted: ' + id)
      return response.data.deleteSystemUnderTest
    } catch (error) {
      console.error(error)
    }
  },
  async save ({ state }, id) {
    try {
      let newItem = null
      let item = state.editedItem[0]
      let data = {
        name: item.name
      }
      if (id) {
        await systemundertestService.updateSystemUnderTest({
          id: id,
          data: data
        })
      } else {
        newItem = await systemundertestService.createSystemUnderTest({
          data: data
        })
      }
      return newItem
    } catch (error) {
      console.error(error)
    }
  }
}
